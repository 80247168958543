<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import * as regexUtils from "@/utils/regexUtils";
import { email, integer, required } from "@vuelidate/validators";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import contactTools from "@/tools/contactTools";
import contactApi from "@/apis/contactApi";

defineProps({
  contactInfo: {},
});
//region form表单
let contactFormModel = reactive({});
// Validation rules
const nameRule = (value) => regexUtils.nameRegx.test(value);
const rules = computed(() => {
  return {
    givenName: { required, nameRule },
    surname: { required, nameRule },
    emailAddress: { required, email },
    phoneNumber: { required, integer },
    message: { required },
  };
});
const v$ = useVuelidate(rules, contactFormModel);

//电话号码
function phoneNumberInput(value, event) {
  if (value && typeof value !== "object") {
    contactFormModel.phoneNumber = value;
    validatePhoneNumber();
  }
}

function phoneBlur() {
  validatePhoneNumber();
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}

function countryChange(value) {
  contactFormModel.countryAccessCode = value.dialCode;
}

onMounted(() => {
  let phoneEle = document.getElementById("phoneEle");
  if (phoneEle) {
    phoneEle.style.border = "none";
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("form-control");
        childListElement.classList.add("bg_input");
      }
    }
  }
});

const validatePhoneNumberExpose = () => {
  if (!v$.value.phoneNumber) return;
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
};

let messageShow = ref(false);
let message = ref("");

async function sendMessage() {
  const result = await v$.value.$validate();
  validatePhoneNumberExpose();
  const contactFormModelCopy = JSON.parse(JSON.stringify(contactFormModel));
  const name =
    contactFormModelCopy.surname + "/" + contactFormModelCopy.givenName;
  const telephoneNumber =
    "+" +
    contactFormModelCopy.countryAccessCode +
    contactFormModelCopy.phoneNumber;

  const contactRQ = contactTools.getContactRQ(
    name,
    contactFormModelCopy.emailAddress,
    telephoneNumber,
    contactFormModelCopy.emailAddress
  );
  if (!result) {
    return;
  }
  contactApi.contact(contactRQ).then((res) => {
    const { data } = res;

    if (data.success) {
      message.value = data.comment.stringValue;
    } else {
      message.value = "Sorry! Failed to send message";
    }
    messageShow.value = true;
  });
}

//endregion
let active = ref(false);

function modalToggle() {
  const body = document.querySelector("body");
  active.value = !active.value;
  active.value
    ? body.classList.add("modal-open")
    : body.classList.remove("modal-open");
}
</script>
<template>
  <section id="contact_main_arae" class="section_padding">
    <div class="container">
      <div class="row" v-if="contactInfo && contactInfo.phoneNumber">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("contact-with-us") }}</h2>
          </div>
        </div>
      </div>
      <div class="row" v-if="contactInfo && contactInfo.phoneNumber">
        <div class="col-lg-12">
          <div class="phone_tuch_area">
            <h3>{{ $t("stay-in-touch") }}</h3>
            <h3>
              <a href="tel:+00-123-456-789">{{ contactInfo.phoneNumber }}</a>
            </h3>
          </div>
        </div>
        <div
          class="row"
          v-if="
            contactInfo && contactInfo.offices && contactInfo.offices.length > 0
          "
        >
          <div
            class="col-lg-3 col-md-6 col-sm-12 col-12"
            v-for="(offer, index) in contactInfo.offices"
            :key="index"
          >
            <!--此处设置宽高是为了隐藏View on map后统一大小-->
            <div class="contact_boxed" style="width: 330px; height: 214px">
              <h6>{{ offer.officeName }}</h6>
              <h3>{{ offer.locationName }}</h3>
              <p>{{ offer.address }}</p>
              <a href="#" @click="modalToggle" v-show="false">{{
                $t("view-on-map")
              }}</a>
            </div>
          </div>
        </div>
      </div>

      <div
        ref="modal"
        class="modal fade"
        :class="{ show: active, 'd-block': active }"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-body map_modal_content">
              <div class="btn_modal_closed">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="modalToggle"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="map_area">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact_main_form_area">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="section_heading_center">
              <h2>{{ $t("leave-us-a-message") }}</h2>
            </div>
            <div class="contact_form">
              <form action="!#" id="contact_form_content">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        :class="{
                          'is-invalid': v$.surname.$errors.length > 0,
                        }"
                        class="form-control bg_input"
                        placeholder="Last name*"
                        v-model="v$.surname.$model"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        :class="{
                          'is-invalid': v$.givenName.$errors.length > 0,
                        }"
                        v-model="v$.givenName.$model"
                        type="text"
                        class="form-control bg_input"
                        placeholder="First name*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        :class="{
                          'is-invalid': v$.emailAddress.$errors.length > 0,
                        }"
                        class="form-control bg_input"
                        placeholder="Email address*"
                        v-model="v$.emailAddress.$model"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <VueTelInput
                        style="height: 54px"
                        mode="international"
                        :class="{
                          'is-invalid': v$.phoneNumber.$errors.length > 0,
                        }"
                        id="phoneEle"
                        autocomplete="off"
                        :autoFormat="false"
                        :inputOptions="{
                          placeholder: 'Phone number*',
                        }"
                        @input="phoneNumberInput"
                        @blur="phoneBlur"
                        @country-changed="countryChange"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <textarea
                        class="form-control bg_input"
                        rows="5"
                        placeholder="Message*"
                        :class="{
                          'is-invalid': v$.message.$errors.length > 0,
                        }"
                        v-model="contactFormModel.message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="messageShow">
                    <div class="form-group text-center">
                      <div
                        class="alert alert-success"
                        role="alert"
                        v-if="message"
                        style="display: flex; justify-content: space-between"
                      >
                        <div>{{ message }}</div>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        class="alert alert-danger"
                        role="alert"
                        v-else
                        style="display: flex; justify-content: space-between"
                      >
                        <div>{{ message }}</div>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group text-center">
                      <button
                        type="button"
                        class="btn btn_theme btn_md"
                        @click="$router.back"
                      >
                        {{ $t("back") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn_theme btn_md mg-l-8"
                        @click="sendMessage"
                      >
                        {{ $t("send-message") }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
