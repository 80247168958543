<script setup>
import Banner from "@/components/custom/Banner.vue";
import { reactive, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import paymentApi from "@/apis/paymentApi";
import { useTemplateStore } from "@/stores/template";
import { useBookingStore } from "@/stores/booking";
import { useCmsContentStore } from "@/stores/cms";
import invoiceApi from "@/apis/invoiceApi";

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
let conditionChecked = ref(null);

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "Payment",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Payment",
    },
  ],
});

const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const bookingStore = useBookingStore();
bookingStore.$reset();

const bookingId = route.query.bookingId;
const invoiceId = route.query.invoiceId;
const productType = route.query.productType;
JSON.parse(sessionStorage.getItem("bookRQ"));
if (bookingId) {
  syncSessionAndSearch(bookingId);
} else if (invoiceId) {
  invoiceApi.getInvoiceById(invoiceId).then((res) => {
    let data = res.data;
    syncSessionAndSearch(data.bookingId);
  });
}

async function syncSessionAndSearch(id) {
  templateStore.pageLoader({ mode: "on" });
  let condition = {
    type: "BOOKING_ID",
    id: id,
    limit: 1,
    offset: 0,
  };

  await bookingStore.searchBookingDetails(condition);
  templateStore.pageLoader({ mode: "off" });
}

let passengerFares = reactive({
  flightPassengerFareItems: null,
});

watchEffect(() => {
  let flightPassengerFareItems = null;
  let hotelPassengerFareItems = null;
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.flightItems
  ) {
    flightPassengerFareItems = [];
    bookingStore.bookingDetailsViewItem.flightItems.forEach((flightItem) => {
      let passengerFareItems = flightItem.passengerFareViewItems;
      flightPassengerFareItems.push(...passengerFareItems);
    });
  }
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.hotelItems &&
    bookingStore.bookingDetailsViewItem.hotelItems.length
  ) {
    hotelPassengerFareItems = [];
    bookingStore.bookingDetailsViewItem.hotelItems.forEach((hotelItem) => {
      let roomTypeNames = hotelItem.roomTypeNames;
      let roomTypeName = roomTypeNames.split(",")[0];
      hotelItem.roomViewItems.forEach((roomViewItem) => {
        let totalPrice = roomViewItem.totalPrice;
        let currency = roomViewItem.currency;
        hotelPassengerFareItems.push({
          totalPrice,
          currency,
          roomTypeName,
        });
      });
    });
  }
  passengerFares.flightPassengerFareItems = flightPassengerFareItems;
  passengerFares.hotelPassengerFareItems = hotelPassengerFareItems;

  let totalAmount = 0;
  let currency = null;
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (flightPassengerFareItems) {
    flightPassengerFareItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  if (hotelPassengerFareItems) {
    hotelPassengerFareItems.forEach((hotelPassengerFareItem) => {
      totalAmount += hotelPassengerFareItem.totalPrice;
      //(hotelPassengerFareItem.currency);
      if (!currency && hotelPassengerFareItem.currency) {
        currency = hotelPassengerFareItem.currency;
      }
    });
  }

  let totalAmountContent = {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
  passengerFares.totalAmountContent = totalAmountContent;
});

function viewBookingDetails() {
  router.push({
    name: "sales-booking-success",
    query: {
      bookingId: bookingId,
    },
  });
}

function payByPaymentMethod(paymentMethod, cardType) {
  if (conditionChecked.value && conditionChecked.value != null) {
    const bookRQSession =
      sessionStorage.getItem("bookRQ") != null
        ? JSON.parse(sessionStorage.getItem("bookRQ"))
        : null;
    templateStore.pageLoader({ mode: "on" });
    paymentApi
      .payByPaymentMethod(
        invoiceId,
        productType,
        paymentMethod,
        bookRQSession,
        cardType
      )
      .then((res) => {
        templateStore.pageLoader({ mode: "off" });
        if (res.data.redirect && res.data.redirect.redirectUrl) {
          let redirectUrl = res.data.redirect.redirectUrl;
          window.location.href = redirectUrl;
        }
      });
  } else {
    conditionChecked.value = false;
  }
}

function stripePay(currency) {
  if (conditionChecked.value && conditionChecked.value !== null) {
    const bookRQ =
      sessionStorage.getItem("bookRQ") != null
        ? JSON.parse(sessionStorage.getItem("bookRQ"))
        : null;
    templateStore.pageLoader({ mode: "on" });
    paymentApi
      .commonInitiatePayment(invoiceId, productType, "STRIPE", bookRQ, currency)
      .then((res) => {
        console.info(res.data);
        let chargeRS = res.data;
        let redirectUrl = chargeRS.redirect
          ? chargeRS.redirect.redirectUrl
          : null;
        if (redirectUrl) {
          window.open(redirectUrl);
        } else {
        }
        templateStore.pageLoader({ mode: "off" });
      });
  } else {
    conditionChecked.value = false;
  }
}
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div>
              <h3 class="heading_theme">{{ $t("payment-method") }}</h3>
              <div class="tour_booking_form_box hotel_details_content_area">
                <h3>
                  {{ $t("total-amount") }}
                  {{ passengerFares.totalAmountContent.currency }}
                  {{ passengerFares.totalAmountContent.totalAmount.toFixed(2) }}
                </h3>
                <div class="row">
                  <div class="col-4">
                    <!--                    <a-->
                    <!--                      @click="payByPaymentMethod('WORLDPAY')"-->
                    <!--                      class="btn btn_theme btn_md"-->
                    <!--                      >By Union Pay-->
                    <!--                    </a>-->
                    <!--                    <img-->
                    <!--                      style="height: 50px"-->
                    <!--                      src="@/assets/img/payment/unionPayLogo.png"-->
                    <!--                      @click="payByPaymentMethod('WORLDPAY', 'UNION_PAY')"-->
                    <!--                    />-->
                    <i
                      class="fa-brands fa-cc-stripe cursor-point"
                      style="font-size: 90px; margin-left: 35px"
                      @click="
                        stripePay(passengerFares.totalAmountContent.currency)
                      "
                    ></i>
                  </div>
                  <!--                  <div class="col-4">-->
                  <!--                    &lt;!&ndash;                    <a&ndash;&gt;-->
                  <!--                    &lt;!&ndash;                      @click="payByPaymentMethod('WORLDPAY')"&ndash;&gt;-->
                  <!--                    &lt;!&ndash;                      class="btn btn_theme btn_md"&ndash;&gt;-->
                  <!--                    &lt;!&ndash;                      >By Visa/Master&ndash;&gt;-->
                  <!--                    &lt;!&ndash;                    </a>&ndash;&gt;-->
                  <!--                    <img-->
                  <!--                      style="height: 50px"-->
                  <!--                      src="@/assets/img/payment/visaMastercardLogo.png"-->
                  <!--                      @click="payByPaymentMethod('WORLDPAY', 'VISA_MASTER')"-->
                  <!--                    />-->
                  <!--                  </div>-->
                </div>
                <div class="form-check write_spical_check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="conditionChecked"
                    id="flexCheckDefaultf1"
                  />
                  <label class="form-check-label" for="flexCheckDefaultf1">
                    <router-link to="/templates/terms-service"
                      >{{ $t("read-and-accept-conditions") }}
                    </router-link>
                  </label>
                  <div
                    class="text-danger"
                    v-if="!conditionChecked && conditionChecked != null"
                  >
                    Please check the above option！
                  </div>
                </div>
              </div>
            </div>
            <div class="booking_tour_form_submit">
              <button @click="router.back" class="btn btn_theme btn_md">
                {{ $t("go-back") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
