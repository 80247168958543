<script setup>
import { computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSecurityStore } from "@/stores/security";
import { useTemplateStore } from "@/stores/template";
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import useVuelidate from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import securityApi from "@/apis/securityApi";

const templateStore = useTemplateStore();

// Main store and Router
const router = useRouter();
const route = useRoute();
const securityStore = useSecurityStore();

// Input state variables
const state = reactive({
  username: null,
  password: null,
});

// Validation rules
//自定义正则表达式校验器
const rules = computed(() => {
  return {
    username: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      minLength: minLength(5),
    },
  };
});

// Use vuelidate
const v$ = useVuelidate(rules, state);

/**
 * Sign In
 */
let errorMessage = ref("");

async function signIn() {
  const result = await v$.value.$validate();
  if (!result) {
    // notify user form is invalid
    return;
  }
  templateStore.pageLoader({ mode: "on" });

  securityApi
    .signIn(state.username, state.password)
    .then((res) => {
      // (res.data);

      if (res.data) {
        securityStore.userInfo = res.data;
        sessionStorage.setItem("user", JSON.stringify(res.data));
        // router.push({name: "home"});
        templateStore.pageLoader({ mode: "off" });
        router.back();
      } else {
        sessionStorage.removeItem("user");
        templateStore.pageLoader({ mode: "off" });
      }
    })
    .catch((error) => {
      if (error.status == 401) {
        errorMessage.value = "User name and password mismatch";
      }
      const errorAlert = document.getElementById("errorPasswordAlert");
      if (errorAlert) {
        errorAlert.classList.add("show");
        setTimeout(() => {
          errorAlert.classList.remove("show");
        }, 15000);
      }
      sessionStorage.removeItem("user");
      templateStore.pageLoader({ mode: "off" });
    });
}
</script>

<template>
  <!-- ALERT -->
  <div
    id="errorPasswordAlert"
    class="alert alert-danger alert-dismissible fade"
    role="alert"
  >
    <p class="mb-0">
      {{ errorMessage }}
    </p>
    <button
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="alert"
      type="button"
    ></button>
  </div>
  <form id="main_author_form" action="#" @submit.prevent="signIn">
    <div class="form-group">
      <input
        id="login-username"
        v-model="state.username"
        :class="{
          'is-invalid': v$.username.$errors.length,
        }"
        class="form-control"
        name="login-username"
        :placeholder="$t('enter-your-username')"
        type="text"
        @blur="v$.username.$touch"
      />
      <div
        v-if="v$.username.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-your-username") }}
      </div>
    </div>
    <div class="form-group">
      <input
        id="login-password"
        v-model="state.password"
        :class="{
          'is-invalid': v$.password.$errors.length,
        }"
        class="form-control"
        name="login-password"
        :placeholder="$t('enter-your-password')"
        type="password"
        @blur="v$.password.$touch"
      />
      <div
        v-if="v$.password.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-your-password") }}
      </div>
      <div class="d-inline-block">
        <router-link
          to="/security/forget-password"
          class="d-inline-block p-lg-1"
          >{{ $t("forgot-password") }}?
        </router-link>
        <router-link to="/security/reactive-account" class="d-inline-block">
          {{ $t("reactive-account") }}?
        </router-link>
      </div>
    </div>
    <div class="common_form_submit">
      <button class="btn btn_theme btn_md">{{ $t("sign-in") }}</button>
    </div>
    <div class="have_acount_area">
      <p>
        {{ $t("dont-have-a-account") }}?
        <router-link to="/security/sign-up"
          >{{ $t("register-now") }}
        </router-link>
      </p>
    </div>
  </form>
</template>

<style scoped></style>
