import { defineStore } from "pinia";
import hotelReserveTools from "@/tools/v2/hotel/reserve/hotelReserveTools";
import tripHotelItemTools from "@/tools/v2/trip/tripHotelItemTools";
import hotelReserveApi from "@/apis/v2/hotel/hotelReserveApi";

export const useHotelReserveStore = defineStore("hotelReserveStore", {
  state: () => ({
    tripHotelItem: null,
    hotelBookResRQ: null,
  }),
  actions: {
    holdRoomInventory(hotelReserveFormModel) {
      const holdResRQ = hotelReserveTools.buildHoldResRQ(hotelReserveFormModel);
      const thisRef = this;
      return hotelReserveApi
        .hold(holdResRQ)
        .then((res) => {
          let holdRS = res.data;
          if (
            holdRS == null ||
            (holdRS.errors &&
              holdRS.errors.errors != null &&
              holdRS.errors.errors.length > 0)
          )
            return holdRS;

          if (
            holdRS.hotelReservationList == null ||
            holdRS.hotelReservationList.length === 0
          )
            return holdRS;

          const hotelReservation = holdRS.hotelReservationList[0];

          thisRef.tripHotelItem =
            tripHotelItemTools.buildTripHotelItem(hotelReservation);
          return holdRS;
        })
        .catch((err) => {
          console.error(err);
          // router.push({ name: "error-500" });
        });
    },
    initiateReservation(hotelReserveFormModel) {
      const preBookResRQ = hotelReserveTools.buildPreBookResRQ(
        hotelReserveFormModel
      );
      const thisRef = this;
      return hotelReserveApi
        .preBook(preBookResRQ)
        .then((res) => {
          let preBookRS = res.data;
          if (
            preBookRS == null ||
            (preBookRS.errors &&
              preBookRS.errors.errors != null &&
              preBookRS.errors.errors.length > 0)
          )
            return preBookRS;

          thisRef.hotelBookResRQ = hotelReserveTools.buildBookResRQ(
            preBookResRQ,
            preBookRS
          );

          const hotelReservation = preBookRS.hotelReservationList[0];

          thisRef.tripHotelItem =
            tripHotelItemTools.buildTripHotelItem(hotelReservation);
          return preBookRS;
        })
        .catch((err) => {
          console.error(err);
          // router.push({ name: "error-500" });
        });
    },
  },
});
