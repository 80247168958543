<script setup>
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useSecurityStore } from "@/stores/security";
import { useBookingStore } from "@/stores/booking";
import { useTemplateStore } from "@/stores/template";
import SecurityApi from "@/apis/securityApi";
import BaseBookingSearchForm from "@/views/sales/booking/items/BaseBookingSearchForm.vue";
import { Pagination } from "ant-design-vue";

const APagination = Pagination;

const router = useRouter();
const securityStore = useSecurityStore();
const bookingStore = useBookingStore();
const templateStore = useTemplateStore();

const limitation = reactive({ limit: 6, offset: 0 });
const formModelRef = ref(null);

syncSessionAndSearch();

async function syncSessionAndSearch(formModel) {
  console.log(formModel);
  templateStore.pageLoader({ mode: "on" });
  if (securityStore.userInfo == null) {
    const { data } = await SecurityApi.getSessionInfo();
    securityStore.userInfo = data;
    if (securityStore.userInfo == null) {
      templateStore.pageLoader({ mode: "off" });
      router.push({ name: "auth-signin" });
    }
  }
  const userInfo = securityStore.userInfo;
  let condition = {
    type: "USERNAME",
    id: userInfo.username,
    limit: limitation.limit,
    offset: limitation.offset,
  };
  if (formModel != null) {
    formModelRef.value = formModel;
    condition = Object.assign(condition, formModel);
  } else if (formModelRef.value) {
    delete formModelRef.value["limit"];
    delete formModelRef.value["offset"];
    condition = Object.assign(condition, formModelRef.value);
  }
  await bookingStore.searchBookings(condition);
  templateStore.pageLoader({ mode: "off" });
}

const bookingInfos = computed(() => {
  if (
    bookingStore.bookingViewItems == null ||
    bookingStore.bookingViewItems.length == 0
  )
    return null;

  const bookingViewItems = bookingStore.bookingViewItems;
  const totalViewItems = bookingStore.totalViewItems;
  const airportCityMap = bookingStore.airportCityMap;
  return {
    bookingViewItems,
    totalViewItems,
    airportCityMap,
  };
});

let messageContent = reactive({
  show: false,
  message: null,
  type: null,
});

async function cancelBooking(bookingId) {
  templateStore.pageLoader({ mode: "on" });
  await bookingStore.cancelBooking(bookingId).then(async (res) => {
    templateStore.pageLoader({ mode: "off" });
    if (res.success) {
      await syncSessionAndSearch();
    } else {
      messageContent.show = true;
      messageContent.message = res.message;
      messageContent.showType = "alert-danger";
      messageContent.bookingId = bookingId;
      setTimeout(() => {
        messageContent.show = false;
      }, 5000);
    }
  });
}

function getProductName(bookingViewItem, airportCityMap) {
  let names = [];
  if (
    bookingViewItem.hotelItems &&
    bookingViewItem.flightItems &&
    Object.keys(airportCityMap).length > 0
  ) {
    bookingViewItem.hotelItems.forEach((hotelItem) => {
      names.push(hotelItem.hotelName);
    });
    bookingViewItem.flightItems.forEach((flightItem) => {
      flightItem.flightViewItems.forEach((flightViewItem) => {
        let originLocation = flightViewItem.originLocation;
        let destinationLocation = flightViewItem.destinationLocation;
        let originName =
          airportCityMap[originLocation] != null
            ? airportCityMap[originLocation].cityName
            : "";
        let destName =
          airportCityMap[destinationLocation] != null
            ? airportCityMap[destinationLocation].cityName
            : "";
        let way = originName + " to " + destName + "<br/>";

        names.push(way);
      });
    });
  } else if (bookingViewItem.hotelItems) {
    bookingViewItem.hotelItems.forEach((hotelItem) => {
      names.push(hotelItem.hotelName);
    });
  } else if (
    bookingViewItem.flightItems &&
    Object.keys(airportCityMap).length > 0
  ) {
    bookingViewItem.flightItems.forEach((flightItem) => {
      flightItem.flightViewItems.forEach((flightViewItem) => {
        let originLocation = flightViewItem.originLocation;
        let destinationLocation = flightViewItem.destinationLocation;
        let originName =
          airportCityMap[originLocation] != null
            ? airportCityMap[originLocation].cityName
            : "";
        let destName =
          airportCityMap[destinationLocation] != null
            ? airportCityMap[destinationLocation].cityName
            : "";
        let way = originName + " to " + destName + "<br/>";

        names.push(way);
      });
    });
  }
  return names.toString();
}

function searchBookings(formModel) {
  bookingStore.$reset();
  syncSessionAndSearch(formModel);
}

function jumpTo(bookingId) {
  router.push({ name: "sales-v2-booking-details", query: { bookingId } });
}

//region 分页

const currentPage = ref(1);
const onChange = (page, pageSize) => {
  let offset = (page - 1) * limitation.limit;
  if (pageSize) {
    limitation.limit = pageSize;
    offset = (page - 1) * pageSize;
  }
  limitation.offset = offset;
  syncSessionAndSearch();
};

//endregion 分页

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <h3>{{ $t("my-bookings") }}</h3>
  <BaseBookingSearchForm
    @submit="searchBookings"
    :equipment-type="equipmentType"
  />
  <div
    class="alert d-flex align-items-center justify-content-between"
    :class="{
      'alert-danger': messageContent.showType == 'alert-danger',
      'alert-success': messageContent.showType == 'alert-success',
    }"
    role="alert"
    v-if="messageContent.show"
  >
    <div class="flex-grow-1 me-3">
      <p class="mb-0">
        #{{ messageContent.bookingId }}{{ messageContent.message }}
      </p>
    </div>
    <div class="flex-shrink-0">
      <i
        class="fa fa-fw fa-times-circle"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></i>
    </div>
  </div>
  <div class="table-responsive-lg table_common_area">
    <table v-if="equipmentType == 'iphone'" class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th width="15%">{{ $t("type") }}</th>
          <th>{{ $t("status-uppercase") }}</th>
          <th>{{ $t("price-uppercase") }}</th>
        </tr>
      </thead>
      <tbody v-if="bookingInfos">
        <tr
          class="text-pointer"
          v-for="(
            bookingViewItem, bookingIndex
          ) in bookingInfos.bookingViewItems"
          :key="bookingIndex"
        >
          <td
            style="padding-left: 5px"
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.bookingId }}
          </td>
          <td @click="jumpTo(bookingViewItem.bookingId)">
            {{ bookingViewItem.productType }}
          </td>

          <td
            class="text-danger"
            v-if="bookingViewItem.bookingStatus == 'CANCELLED'"
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.bookingStatus }}
          </td>
          <td
            class="text-success"
            v-else
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.bookingStatus }}
          </td>

          <td
            class="font-size-xl text-center"
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.currency }}
            {{ bookingViewItem.totalPrice.toFixed(2) }}
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>{{ $t("type") }}</th>
          <th>{{ $t("guest-uppercase") }}</th>
          <th>{{ $t("info-uppercase") }}</th>
          <th>{{ $t("create-uppercase") }}</th>
          <th>{{ $t("status-uppercase") }}</th>
          <th>{{ $t("price-uppercase") }}</th>
          <!--<th>{{ $t("operation-uppercase") }}</th>-->
        </tr>
      </thead>
      <tbody v-if="bookingInfos">
        <tr
          class="text-pointer cursor-point"
          v-for="(
            bookingViewItem, bookingIndex
          ) in bookingInfos.bookingViewItems"
          :key="bookingIndex"
        >
          <td
            style="padding-left: 5px"
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.bookingId }}
          </td>
          <td @click="jumpTo(bookingViewItem.bookingId)">
            {{ bookingViewItem.productType }}
          </td>
          <td @click="jumpTo(bookingViewItem.bookingId)">
            {{ bookingViewItem.guestName }}
          </td>

          <td @click="jumpTo(bookingViewItem.bookingId)">
            <p
              v-html="
                getProductName(bookingViewItem, bookingInfos.airportCityMap)
              "
            ></p>
          </td>

          <td @click="jumpTo(bookingViewItem.bookingId)">
            {{ bookingViewItem.createDate }}
          </td>

          <td
            class="text-danger"
            v-if="bookingViewItem.bookingStatus == 'CANCELLED'"
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.bookingStatus }}
          </td>
          <td
            class="text-success"
            v-else
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.bookingStatus }}
          </td>

          <td
            class="font-size-xl text-center"
            @click="jumpTo(bookingViewItem.bookingId)"
          >
            {{ bookingViewItem.currency }}
            {{ bookingViewItem.totalPrice.toFixed(2) }}
          </td>
          <!--          <td class="font-size-xl text-center">-->
          <!--            <div-->
          <!--              class="btn-group"-->
          <!--              role="group"-->
          <!--              v-if="bookingViewItem.bookingStatus != 'CANCELLED'"-->
          <!--            >-->
          <!--              <button-->
          <!--                v-if="false"-->
          <!--                type="button"-->
          <!--                class="btn btn_theme"-->
          <!--                data-bs-toggle="modal"-->
          <!--                :data-bs-target="-->
          <!--                  '#modal-cancel-booking-' + bookingViewItem.bookingId-->
          <!--                "-->
          <!--              >-->
          <!--                {{ $t("cancel") }}-->
          <!--              </button>-->
          <!--            </div>-->
          <!--            &lt;!&ndash; Cancel Booking Slide Right &ndash;&gt;-->
          <!--            <div-->
          <!--              class="modal fade"-->
          <!--              :id="'modal-cancel-booking-' + bookingViewItem.bookingId"-->
          <!--              tabindex="-1"-->
          <!--              role="dialog"-->
          <!--              :aria-labelledby="-->
          <!--                'modal-cancel-booking-' + bookingViewItem.bookingId-->
          <!--              "-->
          <!--              aria-hidden="true"-->
          <!--            >-->
          <!--              <div class="modal-dialog modal-dialog-slideright" role="document">-->
          <!--                <div class="modal-content">-->
          <!--                  <div class="modal-header">-->
          <!--                    <h5 class="modal-title">{{ $t("cancel-booking") }}</h5>-->
          <!--                    <button-->
          <!--                      type="button"-->
          <!--                      class="btn-close"-->
          <!--                      data-bs-dismiss="modal"-->
          <!--                      aria-label="Close"-->
          <!--                    ></button>-->
          <!--                  </div>-->
          <!--                  <div class="modal-body">-->
          <!--                    <p>-->
          <!--                      {{-->
          <!--                        $t("sure-cancel-booking", {-->
          <!--                          bookingId: bookingViewItem.bookingId,-->
          <!--                        })-->
          <!--                      }}?-->
          <!--                    </p>-->
          <!--                  </div>-->
          <!--                  <div class="modal-footer">-->
          <!--                    <button-->
          <!--                      type="button"-->
          <!--                      class="btn btn-secondary"-->
          <!--                      data-bs-dismiss="modal"-->
          <!--                    >-->
          <!--                      {{ $t("close") }}-->
          <!--                    </button>-->
          <!--                    <button-->
          <!--                      v-if="false"-->
          <!--                      type="button"-->
          <!--                      class="btn btn_theme"-->
          <!--                      data-bs-dismiss="modal"-->
          <!--                      @click="cancelBooking(bookingViewItem.bookingId)"-->
          <!--                    >-->
          <!--                      {{ $t("confirm") }}-->
          <!--                    </button>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash; END Cancel Booking Slide Right &ndash;&gt;-->
          <!--          </td>-->
        </tr>
      </tbody>
    </table>
    <div
      v-if="bookingInfos && bookingInfos.totalViewItems"
      class="pagination_pannel"
      style="padding-left: 20%"
    >
      <APagination
        v-model:current="currentPage"
        show-quick-jumper
        :total="bookingInfos.totalViewItems"
        @change="onChange"
      />
    </div>
  </div>
</template>

<style scoped></style>
