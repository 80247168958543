import { HotelFeeAndTaxVO } from "@/viewobject/sales/hotel/availability/hotelFeeAndTaxVO";
import { HotelCancelPolicyVO } from "@/viewobject/sales/hotel/availability/hotelCancelPolicyVO";
import { HotelDailyRateVO } from "@/viewobject/sales/hotel/availability/hotelDailyRateVO";
import { HotelMealPlanVO } from "@/viewobject/sales/hotel/availability/hotelMealPlanVO";

export class HotelRoomVO {
  /**
   *
   * @param roomItemId string， 对应gallop中的room item id
   * @param supplier string，
   * @param bedTypeName string，
   * @param mealName string，
   * @param mealPlan Object, HotelMealPlanVo对象，包含餐饮信息
   * @param refundable boolean，
   * @param availableQuantity number，
   * @param checkInDate string，
   * @param checkOutDate string，
   * @param nightsStay number，
   * @param roomQuantityRequested number，
   * @param currencyCode string，
   * @param avgBaseUnitPrice number，
   * @param totalBasePrice number，基础总房价
   * @param totalFeesTaxesInc number，包含在总价的费用和税
   * @param feesTaxesExc Array
   * @param totalPrice number，
   * @param referenceId string，
   * @param specialRequest string，
   * @param cancelPolicies Array，
   * @param dailyRates Array，房间每天的价格明细
   * @param rateComment string, 费用描述，html格式，主要由hotelbeds提供
   * @param promotionDescriptions Array，字符串数组，费用相关优惠活动描述
   */
  constructor(
    roomItemId,
    supplier,
    bedTypeName,
    mealName,
    mealPlan,
    refundable,
    availableQuantity,
    checkInDate,
    checkOutDate,
    nightsStay,
    roomQuantityRequested,
    currencyCode,
    avgBaseUnitPrice,
    totalBasePrice,
    totalFeesTaxesInc,
    feesTaxesExc,
    totalPrice,
    referenceId,
    specialRequest,
    cancelPolicies,
    dailyRates,
    rateComment,
    promotionDescriptions
  ) {
    this.roomItemId = roomItemId;
    this.supplier = supplier;
    this.bedTypeName = bedTypeName;
    this.mealName = mealName;
    this.mealPlan = mealPlan;
    this.refundable = refundable;
    this.availableQuantity = availableQuantity;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.nightsStay = nightsStay;
    this.roomQuantityRequested = roomQuantityRequested;
    this.currencyCode = currencyCode;
    this.avgBaseUnitPrice = avgBaseUnitPrice;
    this.totalBasePrice = totalBasePrice;
    this.totalFeesTaxesInc = totalFeesTaxesInc;
    this.feesTaxesExc = feesTaxesExc;
    this.totalPrice = totalPrice;
    this.referenceId = referenceId;
    this.specialRequest = specialRequest;
    this.cancelPolicies = cancelPolicies;
    this.rateComment = rateComment;
    this.promotionDescriptions = promotionDescriptions;
    this.dailyRates = dailyRates;
  }

  addNewFeeTaxExc(amount, currencyCode, name, textFormat) {
    let feeAndTaxVO = new HotelFeeAndTaxVO(
      amount,
      currencyCode,
      name,
      textFormat
    );
    if (this.feesTaxesExc == null) this.feesTaxesExc = [];
    this.feesTaxesExc.push(feeAndTaxVO);
  }

  addNewCancelPolicy(beginDate, currencyCode, nmbrOfUnit, unitType) {
    let cancelPolicyVO = new HotelCancelPolicyVO(
      beginDate,
      currencyCode,
      nmbrOfUnit,
      unitType
    );
    if (this.cancelPolicies == null) this.cancelPolicies = [];
    this.cancelPolicies.push(cancelPolicyVO);
  }

  addNewDailyRate(
    date,
    roomBasePrice,
    taxAndFeesPrice,
    totalPrice,
    currency,
    feeDescriptions
  ) {
    let dailyRateVO = new HotelDailyRateVO(
      date,
      roomBasePrice,
      taxAndFeesPrice,
      totalPrice,
      currency,
      feeDescriptions
    );
    if (this.dailyRates == null) this.dailyRates = [];

    this.dailyRates.push(dailyRateVO);
    return dailyRateVO;
  }

  addNewMealPlan(breakfast, lunch, dinner) {
    this.mealPlan = new HotelMealPlanVO(breakfast, lunch, dinner);
    return this.mealPlan;
  }
}
