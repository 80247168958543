<script setup>
import Banner from "@/components/custom/Banner.vue";
import { reactive, ref, watch, watchEffect } from "vue";

import { useTemplateStore } from "@/stores/template";
import { useBookingStore } from "@/stores/booking";
import { useProfileStore } from "@/stores/profile";
import invoiceApi from "@/apis/invoiceApi";
import { useCmsContentStore } from "@/stores/cms";
import { useRoute, useRouter } from "vue-router";

const navigation = reactive({
  title: "Booking Success",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Booking Success",
    },
  ],
});

const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const cmsContentStore = useCmsContentStore();
const bookingStore = useBookingStore();
let bookingId = route.query.bookingId;
const invoiceId = route.query.invoiceId;
bookingStore.$reset();
const customerInfoRef = ref({});

//region cms内容管理
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

if (bookingId) {
  syncSessionAndSearch();
} else if (invoiceId) {
  invoiceApi.getInvoiceById(invoiceId).then((res) => {
    let data = res.data;
    bookingId = data.bookingId;
    syncSessionAndSearch();
  });
}

async function syncSessionAndSearch() {
  templateStore.pageLoader({ mode: "on" });
  let condition = {
    type: "BOOKING_ID",
    id: bookingId,
    limit: 1,
    offset: 0,
  };

  await bookingStore.searchBookingDetails(condition);
  templateStore.pageLoader({ mode: "off" });
}

let user = reactive(JSON.parse(sessionStorage.getItem("user")));

const profileStore = useProfileStore();
profileStore.$reset(); //重置状态

profileStore.loadUserProfile(user.username);

watchEffect(() => {
  if (bookingStore.bookingDetailsViewItem) {
    getCustomerInfo(bookingStore.bookingDetailsViewItem);
  }
});

function viewBookingDetails() {
  router.push({ name: "sales-v2-booking-details", query: { bookingId } });
}

function getCustomerInfo(bookingDetailsViewItem) {
  let customerName = "";
  if (bookingDetailsViewItem.productType.indexOf("Hotel") >= 0) {
    bookingDetailsViewItem.hotelItems.forEach((hotelItem) => {
      hotelItem.passengers.forEach((passenger) => {
        if (passenger.primaryIndicator) {
          customerName =
            passenger.nameTitle +
            ". " +
            passenger.surname +
            "/" +
            passenger.givenName;
        }
        customerInfoRef.value.customerName = customerName;
        customerInfoRef.value.emailAddress = passenger.emailAddress;
      });
    });
  } else if (bookingDetailsViewItem.productType.indexOf("Flight") >= 0) {
    bookingDetailsViewItem.flightItems.forEach((flightItem) => {
      flightItem.passengers.forEach((passenger) => {
        if (!customerInfoRef.value.customerName) {
          customerName =
            passenger.nameTitle +
            ". " +
            passenger.surname +
            "/" +
            passenger.givenName;
          customerInfoRef.value.customerName = customerName;
          customerInfoRef.value.emailAddress = passenger.emailAddress;
        }
      });
    });
  }
  return customerName;
}
</script>

<template>
  <!-- Common Banner Area -->
  <!-- Banner Area -->
  <Banner
    :background-image-url="backgroundImageUrlRef"
    :navigate="navigation"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row margin-top-20">
        <div class="col-lg-8" style="margin-left: 15%">
          <div class="tou_booking_form_Wrapper">
            <div class="tour_booking_form_box mb-4">
              <div class="booking_success_arae">
                <div class="booking_success_img">
                  <img src="@/assets/img/icon/right.png" alt="img" />
                </div>
                <div
                  class="booking_success_text"
                  v-if="bookingStore.bookingDetailsViewItem"
                >
                  <h3>
                    {{
                      $t("name-submitted-successfully", {
                        customerName: customerInfoRef.customerName,
                      })
                    }}
                  </h3>
                  <h6>
                    {{
                      $t("your-booking-detail", {
                        emailAddress: customerInfoRef.emailAddress,
                      })
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="col-lg-4">-->
        <!--          <div class="tour_details_right_sidebar_wrapper">-->
        <!--            <div class="tour_detail_right_sidebar">-->
        <!--              <div class="tour_details_right_boxed">-->
        <!--                <div class="tour_details_right_box_heading">-->
        <!--                  <h3>Booking details</h3>-->
        <!--                </div>-->
        <!--                <div class="tour_booking_amount_area">-->
        <!--                  <ul>-->
        <!--                    <li>Booking ID: <span>#RB5783GH</span></li>-->
        <!--                    <li>Booking date: <span>10 February 2022</span></li>-->
        <!--                    <li>Payment method: <span>Bank transfer</span></li>-->
        <!--                    <li>Booking status: <span>Success</span></li>-->
        <!--                  </ul>-->
        <!--                  <ul>-->
        <!--                    <li>Adult Price x 1 <span>$40,000.00</span></li>-->
        <!--                    <li class="remove_coupon_tour">-->
        <!--                      Discount <span>10%</span>-->
        <!--                    </li>-->
        <!--                    <li>Tax <span>5%</span></li>-->
        <!--                  </ul>-->
        <!--                  <div class="tour_bokking_subtotal_area">-->
        <!--                    <h6 class="remove_coupon_tour">-->
        <!--                      Subtotal <span>$38,000.00</span>-->
        <!--                    </h6>-->
        <!--                  </div>-->
        <!--                  <div class="coupon_add_area">-->
        <!--                    <h6>-->
        <!--                      <span class="remove_coupon_tour">Remove</span> Coupon code-->
        <!--                      (OFF 5000)-->
        <!--                      <span>$5,000.00</span>-->
        <!--                    </h6>-->
        <!--                  </div>-->
        <!--                  <div class="total_subtotal_booking">-->
        <!--                    <h6 class="remove_coupon_tour">-->
        <!--                      Total Amount <span>$33,000.00</span>-->
        <!--                    </h6>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="booking_tour_form_submit" style="margin-left: 35%">
          <button @click="viewBookingDetails" class="btn btn_theme btn_md">
            {{ $t("view-your-booking") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
