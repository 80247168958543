<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  bookingStatus: {},
  bookingId: {},
  items: {},
  itemType: {
    default: "hotel",
  },
  showContinueToPayment: {
    type: Boolean,
    default: false,
  },
  equipmentType: {},
});
let chooseItemId = ref(null);
let voucherItems = computed(() => {
  if (!props.items) return null;
  let voucherItems = [];
  props.items.forEach((item) => {
    voucherItems.push({
      itemId: item.itemId,
      name: item.hotelName,
    });
  });
  chooseItemId.value = voucherItems[0].itemId;
  return voucherItems;
});
</script>

<template>
  <div
    class="btn-group me-2 mb-2"
    role="group"
    v-if="bookingStatus !== 'CANCELLED'"
  >
    <button
      type="button"
      class="btn btn_theme"
      :class="{ btn_md: equipmentType !== 'iphone' }"
      data-bs-toggle="modal"
      data-bs-target="#modal-cancel-booking"
    >
      {{ $t("cancel-booking") }}
    </button>
  </div>
  <div
    class="btn-group me-2 mb-2"
    role="group"
    v-if="bookingStatus !== 'CANCELLED'"
  >
    <button
      type="button"
      class="btn btn_theme"
      :class="{ btn_md: equipmentType !== 'iphone' }"
      data-bs-toggle="modal"
      data-bs-target="#modal-print-voucher"
    >
      {{ $t("print-voucher") }}
    </button>
  </div>
  <div class="btn-group me-2 mb-2" role="group" v-if="showContinueToPayment">
    <button
      type="button"
      class="btn btn_theme"
      :class="{ btn_md: equipmentType !== 'iphone' }"
      @click="$emit('continueToPayment', bookingId)"
    >
      {{ $t("continue-to-payment") }}
    </button>
  </div>
  <!-- Cancel Booking Slide Right -->
  <div
    class="modal fade"
    id="modal-cancel-booking"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-cancel-booking"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("cancel-booking") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            {{
              $t("sure-cancel-booking", {
                bookingId: bookingId,
              })
            }}?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
          <button
            type="button"
            class="btn btn_theme"
            data-bs-dismiss="modal"
            @click="$emit('cancelBooking', bookingId)"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END Cancel Booking Slide Right -->

  <!-- Print Voucher Slide Right -->
  <div
    v-if="voucherItems"
    class="modal fade"
    id="modal-print-voucher"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-print-voucher"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("print-voucher") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            {{
              $t("sure-print-voucher", {
                bookingId: bookingId,
              })
            }}
          </p>
          <div class="tour_search_type">
            <div
              class="form-check"
              v-for="(voucherItem, voucherItemIndex) in voucherItems"
              :key="voucherItemIndex"
            >
              <input
                class="form-check-input"
                type="radio"
                :id="'voucher' + voucherItemIndex"
                name="example-radios-default"
                :value="voucherItem.itemId"
                v-model="chooseItemId"
                :checked="voucherItemIndex === 0"
              />
              <label
                class="form-check-label"
                :for="'voucher' + voucherItemIndex"
                >{{ voucherItem.name }}</label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
          <button
            type="button"
            class="btn btn_theme"
            data-bs-dismiss="modal"
            @click="$emit('printVoucher', bookingId, itemType, chooseItemId)"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END Print Voucher Slide Right -->
</template>

<style scoped></style>
