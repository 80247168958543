import Dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

Dayjs.extend(utc);
Dayjs.extend(tz);
Dayjs.tz.setDefault("Asia/Shanghai");

/*
 * 格式化日期，格式为yyyy-MM-dd
 * @param date object 必填，Date日期对象
 */

function format(date) {
  let fullYear = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let dayStr = "";
  if (day < 10) {
    dayStr = "0" + day;
  } else {
    dayStr = day;
  }

  let monStr = "";
  if (month < 10) {
    monStr = "0" + month;
  } else {
    monStr = month;
  }

  let dateStr = fullYear + "-" + monStr + "-" + dayStr;
  return dateStr;
}

/*
 * 根据前一天的日期，获取默认日期，默认日期为传入日期的第二天
 *
 * @param date object 可选，指定日期，若日期为空，则默认取当天
 * @param offset number 可选,指定日期后相隔天数，若offset为空，则默认取1
 *
 * @return string 格式化日期，例如，2023-05-12
 */
function getOffsetDate(date, offset) {
  date = date == null ? new Date() : date;
  let offsetDate = new Date(date);
  offsetDate.setDate(offsetDate.getDate() + offset);
  return format(offsetDate);
}

/*
 * 计算两个日期之间相隔的年数
 * @param startDate object 必填，为Date对象
 * @param endDate object 必填，为Date对象
 *
 * @return number 年数
 */
function getYearsByDateRange(startDate, endDate) {
  let startYear = startDate.getFullYear();
  let startMonth = startDate.getMonth();

  let endYear = endDate.getFullYear();
  let endMonth = endDate.getMonth();

  let yearsDec = endYear - startYear;
  if (endMonth > startMonth) yearsDec--;
  return yearsDec;
}

/*
 * 计算两个日期之间相隔的天数
 * @param startDate object 必填，为Date对象
 * @param endDate object 必填，为Date对象
 *
 * @return number 天数
 */
function getDaysByDateRange(startDate, endDate) {
  return parseInt(
    Math.abs(new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24
  );
}

/***
 * 计算当前日期是周几
 */

function getWeek(date) {
  let datelist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return datelist[new Date(date).getDay()];
}

/****
 * 比较时间a>b true
 * @param checkInDate
 * @param checkOutDate
 * @returns {boolean}
 */
function compareIfBefore(checkInDate, checkOutDate) {
  checkInDate = new Date(checkInDate);
  checkOutDate = new Date(checkOutDate);
  return checkInDate < checkOutDate ? true : false;
}

// 将分钟数量转换为小时和分钟字符串
function formatHourMinute(minutes) {
  //console.info(minutes);
  let time = {
    hour:
      Math.floor(minutes / 60) < 10 && Math.floor(minutes / 60) > 0
        ? "0" + Math.floor(minutes / 60)
        : Math.floor(minutes / 60),
    minute:
      minutes % 60 < 10 && minutes % 60 > 0
        ? "0" + (minutes % 60)
        : minutes % 60,
  };
  return time.hour + "h " + time.minute + "m";
}

function calcDurationInMinsWithTimeZone(startDateTime, endDateTime) {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);
  return Dayjs(endDate).diff(startDate, "minute");
}

//美式英语的时间格式
function formatEnglishDate(date) {
  date = new Date(date);
  let monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec",
  ];
  let suffix = ["st", "nd", "rd", "th"];

  let year = date.getFullYear(); //年
  let month = monthArr[date.getMonth()]; //月
  let ddate = date.getDate(); //日
  let time = date.toLocaleTimeString("chinese", { hour12: false }).slice(0, 5);

  if (ddate % 10 < 1 || ddate % 10 > 3) {
    ddate = ddate + suffix[3];
  } else if (ddate % 10 == 1) {
    ddate = ddate + suffix[0];
  } else if (ddate % 10 == 2) {
    ddate = ddate + suffix[1];
  } else {
    ddate = ddate + suffix[2];
  }
  return { time: time, ddate: ddate, month: month, year: year };
}

function formatDateTime(time) {
  let date = new Date(time);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;

  return y + "-" + m + "-" + d + " " + h + ":" + minute;
}

export default {
  format,
  getOffsetDate,
  getYearsByDateRange,
  getDaysByDateRange,
  getWeek,
  compareIfBefore,
  formatHourMinute,
  calcDurationInMinsWithTimeZone,
  formatEnglishDate,
  formatDateTime,
};
