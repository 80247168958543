<script setup>
import { ref, watch } from "vue";
import HotelDailyRatesPanel from "@/views/v2/sales/hotel/items/panel/HotelDailyRatesPanel.vue";

const props = defineProps({
  tripHotelItem: {},
});
const emits = defineEmits();
const tripHotelItemRef = ref(null);

const isPriceSummaryShowRef = ref(true);
const priceBreakdownContentRef = ref(true);

watch(
  () => props.tripHotelItem,
  (newValue) => {
    tripHotelItemRef.value = newValue;
    if (newValue) {
      const roomQuantity = newValue.roomQuantity;
      const roomTypeItem =
        newValue.roomTypes && newValue.roomTypes.length > 0
          ? newValue.roomTypes[0]
          : {};
      const roomTypeName = roomTypeItem.roomTypeName;
      const roomItem =
        roomTypeItem.rooms && roomTypeItem.rooms.length > 0
          ? roomTypeItem.rooms[0]
          : {};

      const nightsStay = roomItem.nightsStay;

      priceBreakdownContentRef.value = {
        roomInfo: roomItem,
        roomQuantity: roomQuantity,
        roomTypeName: roomTypeName,
        nightsStay: nightsStay,
      };
    }
  },
  { deep: true, immediate: true }
);

function changePriceSummaryShow() {
  isPriceSummaryShowRef.value = !isPriceSummaryShowRef.value;
}

function confirmBooking() {
  emits("confirmBooking");
}
</script>

<template>
  <div class="tour_details_boxed" v-if="tripHotelItemRef">
    <div class="row mg-b-20">
      <div class="col-6">
        <h4>
          {{
            isPriceSummaryShowRef ? $t("price-summary") : $t("price-breakdown")
          }}
        </h4>
      </div>
      <div class="col-6 text-end">
        <button
          class="btn btn_border"
          type="reset"
          @click="changePriceSummaryShow"
        >
          {{
            isPriceSummaryShowRef
              ? $t("view-price-breakdown")
              : $t("view-price-summary")
          }}
        </button>
      </div>
    </div>

    <div v-if="isPriceSummaryShowRef">
      <div class="row">
        <div class="col-12">
          <div class="row text-muted">
            <div class="col-8">{{ $t("description-uppercase") }}</div>
            <div class="col-4 text-end">
              {{ $t("price-uppercase") }} ({{ tripHotelItemRef.currencyCode }})
            </div>
          </div>
          <hr />
          <div v-if="priceBreakdownContentRef" class="row">
            <div class="col-lg-8 col-8">
              <figure>
                <div>
                  {{ priceBreakdownContentRef.roomQuantity }}
                  {{ $t("room") }} ({{ priceBreakdownContentRef.roomTypeName }})
                  *

                  {{ priceBreakdownContentRef.nightsStay }}
                  {{ $t("nights") }}
                  <span
                    class="text-pointer cursor-point mg-l-5 text-muted font-14"
                    data-bs-target="#modal-daily-prices"
                    data-bs-toggle="modal"
                  >
                    {{ $t("more-details") }} <i class="fa fa-circle-info"></i>
                  </span>
                </div>

                <div>
                  {{ $t("taxes-uppercase") }}
                </div>
              </figure>
            </div>
            <div
              v-if="priceBreakdownContentRef.roomInfo"
              class="col-lg-4 col-4"
            >
              <figure class="text-end">
                <div>
                  {{ tripHotelItemRef.currencyCode }}
                  {{ priceBreakdownContentRef.roomInfo.totalPrice.toFixed(2) }}
                </div>
                <div>
                  {{ tripHotelItemRef.currencyCode }}
                  {{
                    priceBreakdownContentRef.roomInfo.totalFeesTaxesInc.toFixed(
                      2
                    )
                  }}
                </div>
              </figure>
            </div>
            <hr class="mg-up-10" />
          </div>

          <div class="row font_weight">
            <div class="col-lg-8 col-8">
              <figure>
                <div>
                  {{ $t("total-uppercase") }}
                  ({{ tripHotelItemRef.currencyCode }})
                </div>
              </figure>
            </div>
            <div class="col-lg-4 col-4">
              <figure class="text-end text-danger">
                <div>
                  {{ tripHotelItemRef.currencyCode }}
                  {{ tripHotelItemRef.totalPrice.toFixed(2) }}
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <table
        v-if="
          tripHotelItemRef.roomTypes &&
          tripHotelItemRef.roomTypes.length > 0 &&
          tripHotelItemRef.roomTypes[0]
        "
        class="table table-bordered"
      >
        <thead>
          <tr>
            <th class="text-center">
              {{ $t("room-index") }}
            </th>
            <th class="text-center">{{ $t("date") }}</th>
            <th class="text-center">
              {{ $t("room-price") }}
            </th>
            <th class="text-center">{{ $t("taxes") }}</th>
            <th class="text-center">
              {{ $t("amount") }}
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(roomViewItem, roomIndex) in tripHotelItemRef.roomTypes[0]
            .rooms"
          :key="roomIndex"
        >
          <tr
            v-for="(dailyRate, rateIndex) in roomViewItem.dailyRates"
            :key="rateIndex"
          >
            <td class="text-center">{{ $t("room") }} {{ roomIndex + 1 }}</td>
            <td class="text-center">{{ dailyRate.date }}</td>
            <td class="text-center">
              {{ tripHotelItemRef.currencyCode }}
              {{ dailyRate.roomBasePrice.toFixed(2) }}
            </td>
            <td class="text-center">
              {{ tripHotelItemRef.currencyCode }}
              {{ dailyRate.taxAndFeesPrice.toFixed(2) }}
            </td>
            <td class="text-center">
              {{ tripHotelItemRef.currencyCode }}
              {{ dailyRate.totalPrice.toFixed(2) }}
            </td>
          </tr>

          <tr
            v-if="
              tripHotelItemRef.roomTypes[0].rooms.length > 0 &&
              roomIndex === tripHotelItemRef.roomTypes[0].rooms.length - 1
            "
          >
            <td class="text-end" colspan="4">
              {{ $t("room-amount") }}
            </td>
            <td class="text-center">
              {{ tripHotelItemRef.currencyCode }}
              {{
                (
                  roomViewItem.totalBasePrice *
                  tripHotelItemRef.roomTypes[0].rooms.length
                ).toFixed(2)
              }}
            </td>
          </tr>
          <tr
            v-if="
              tripHotelItemRef.roomTypes[0].rooms.length > 0 &&
              roomIndex === tripHotelItemRef.roomTypes[0].rooms.length - 1
            "
          >
            <td class="text-end" colspan="4">
              {{ $t("taxes-amount") }}
            </td>
            <td class="text-center">
              {{ tripHotelItemRef.currencyCode }}
              {{
                (
                  roomViewItem.totalFeesTaxesInc *
                  tripHotelItemRef.roomTypes[0].rooms.length
                ).toFixed(2)
              }}
            </td>
          </tr>
          <tr
            v-if="
              tripHotelItemRef.roomTypes[0].rooms.length > 0 &&
              roomIndex === tripHotelItemRef.roomTypes[0].rooms.length - 1 &&
              tripHotelItemRef
            "
            class="text-danger font_weight"
          >
            <td class="text-end text-danger" colspan="4">
              {{ $t("total-amount") }}
            </td>
            <td class="text-center text-danger">
              {{ tripHotelItemRef.currencyCode }}
              {{ tripHotelItemRef.totalPrice.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center">
      <button class="btn btn_theme btn_md" @click="confirmBooking">
        {{ $t("confirm-booking") }}
      </button>
    </div>
  </div>

  <!-- 日期价格 -->
  <div
    id="modal-daily-prices"
    aria-hidden="true"
    aria-labelledby="modal-daily-prices"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <HotelDailyRatesPanel
          :room-item="
            priceBreakdownContentRef ? priceBreakdownContentRef.roomInfo : {}
          "
          :room-type-name="
            priceBreakdownContentRef
              ? priceBreakdownContentRef.roomTypeName
              : null
          "
        />
      </div>
    </div>
  </div>
  <!-- 日期价格 END-->
</template>

<style scoped></style>
