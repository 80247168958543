//#region 获取请求对象- Searching Hotels Request Object

/*开始 列表页相关*/

/*
 *
 * @param flightSearchFormModel object FlightSearchFormModel表单对象
 * */
function getLowFareSearchRQ(flightSearchFormModel) {
  let originDestinations = flightSearchFormModel.originDestinations;
  let adultNum = flightSearchFormModel.adultNum;
  let childNum = flightSearchFormModel.childNum;
  let infantNum = flightSearchFormModel.infantNum;
  let cabinClass = flightSearchFormModel.cabinClass;
  let infantNumOnLab = flightSearchFormModel.infantNumOnLab;

  //构建请求
  let originDestinationInformationList = [];
  if (originDestinations.length > 0) {
    originDestinations.forEach((originDestination) => {
      let originDestinationInformation = {
        departureDateTime: originDestination.departureDateTime,
        originLocation: {
          locationCode: originDestination.originLocationCode,
        },
        destinationLocation: {
          locationCode: originDestination.destinationLocationCode,
        },
      };
      originDestinationInformationList.push(originDestinationInformation);
    });
  }

  //console.info(request)
  let passengerTypeQuantityList = [];
  if (adultNum > 0) {
    passengerTypeQuantityList.push({
      code: "ADT",
      quantity: adultNum,
    });
  }
  if (childNum > 0) {
    passengerTypeQuantityList.push({
      code: "CNN",
      quantity: childNum,
    });
  }
  //default is in seat
  if (infantNum > 0) {
    passengerTypeQuantityList.push({
      code: "INF",
      quantity: infantNum,
    });
  }
  if (infantNumOnLab > 0) {
    passengerTypeQuantityList.push({
      code: "INF",
      quantity: infantNumOnLab,
    });
  }

  return {
    originDestinationInformationList: originDestinationInformationList,
    travelerInfoSummary: {
      airTravelerAvailList: [
        {
          passengerTypeQuantityList: passengerTypeQuantityList,
        },
      ],
    },
    travelPreferencesList: [
      {
        cabinPrefList: [
          {
            cabin: cabinClass,
          },
        ],
      },
    ],
  };
}

function getDefaultFlightSearchFormModel(
  originDestinations,
  adultNum,
  childNum,
  infantNum,
  cabinClass
) {
  return new FlightSearchFormModel(
    originDestinations,
    adultNum,
    childNum,
    infantNum,
    cabinClass
  );
}

class FlightSearchFormModel {
  /*
   *
   * @param originDestinations OriginDestinationItem Array 必填，行程内容，包含起始点，到达点，起飞日期
   *
   * */
  constructor(originDestinations, adultNum, childNum, infantNum, cabinClass) {
    this.originDestinations = originDestinations;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.infantNum = infantNum;
    this.cabinClass = cabinClass;
  }
}

class OriginDestinationItem {
  /*
   *
   * @param departureDateTime string 起飞日期，格式为 yyyy-MM-dd，2011-11-11
   * */
  constructor(
    originLocationCode,
    originLocationName,
    originAirportName,
    destinationLocationCode,
    destinationLocationName,
    destinationAirportName,
    departureDateTime
  ) {
    this.originLocationCode = originLocationCode;
    this.originLocationName = originLocationName;
    this.originAirportName = originAirportName;
    this.destinationLocationCode = destinationLocationCode;
    this.destinationLocationName = destinationLocationName;
    this.destinationAirportName = destinationAirportName;
    this.departureDateTime = departureDateTime;
  }
}

/*结束 请求相关*/

/*开始 详情页相关*/
function getVerifyPriceRQ(pricedItinerary) {
  let airItinerary = pricedItinerary ? pricedItinerary.airItinerary : null;

  let passengerTypeQuantityList = [];
  if (
    pricedItinerary &&
    pricedItinerary.airItineraryPricingInfo &&
    pricedItinerary.airItineraryPricingInfo.PTC_FareBreakdowns.length > 0
  ) {
    pricedItinerary.airItineraryPricingInfo.PTC_FareBreakdowns.forEach(
      (fareBreakdown) => {
        passengerTypeQuantityList.push(fareBreakdown.passengerTypeQuantity);
      }
    );

    return {
      type: "PRICE",
      airItinerary: airItinerary,
      travelerInfoSummary: {
        airTravelerAvailList: [
          {
            passengerTypeQuantityList: passengerTypeQuantityList,
          },
        ],
      },
    };
  }
}

function getSpecifiedPricedItinerary(itineraryRPH, pricedItineraryList) {
  if (!pricedItineraryList || pricedItineraryList.length === 0) return null;

  for (let i = 0; i < pricedItineraryList.length; i++) {
    let pricedItinerary = pricedItineraryList[i];
    let airItinerary = pricedItinerary ? pricedItinerary.airItinerary : null;
    let airItineraryRPH = airItinerary.airItineraryRPH;
    if (itineraryRPH === airItineraryRPH) return pricedItinerary;
  }

  return null; //not found
}

function getAirRuleRQ(rph) {
  return {
    abbreviatedRuleTextInd: false,
    ruleReqInfo: {
      // this rph is from airprice RS
      rph: rph,
    },
  };
}

/*结束 详情页相关*/

/*开始 Offer相关*/
function getAirGetOfferRQ(quoteId) {
  return {
    requestCriterion: {
      airItinerary: {
        itineraries: [
          {
            priced: false,
            rph: quoteId,
          },
        ],
      },
    },
  };
}

function getPriceWithOfferRQ(priceIds, itineraryRph) {
  return {
    travelerInfoSummary: {
      priceRequestInformation: {},
    },
    offer: {
      priced: priceIds,
      summary: [
        {
          itineraryRPH: itineraryRph,
        },
      ],
    },
  };
}

/*结束 Offer相关*/

/*开始 预定页相关*/

/*
 *
 * @param flightBookingFormModel object FlightBookingFormModel表单对象
 * */

function getFlightPassengersForm(fareBreakdowns) {
  let flightPassengersFormModel = {
    passengers: [],
  };
  fareBreakdowns.forEach((fareBreakdown) => {
    let code = fareBreakdown.passengerTypeQuantity.code;
    let defaultNameTitle = "MR";
    if (code == "CNN" || code == "INF") {
      defaultNameTitle = "MSTR";
    }
    fareBreakdown.travelerRefNumberList.forEach((travelerRefNumber) => {
      let rph = travelerRefNumber.rph;
      let flightPassengersFormItem = new FlightPassengersFormItem(
        rph,
        code,
        null,
        null,
        defaultNameTitle,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      flightPassengersFormModel.passengers.push(flightPassengersFormItem);
    });
  });
  console.info(flightPassengersFormModel);
  return flightPassengersFormModel;
}

function getPassengerTicketFareBreakdownItems(fareBreakdowns) {
  let passengerTicketFareBreakdownItems = [];
  fareBreakdowns.forEach((fareBreakdown) => {
    fareBreakdown.passengerFareList.forEach((passengerFare) => {
      if ("TicketFee" === passengerFare.usage) {
        let baseFare = passengerFare.baseFare;
        let baseFareAmount = baseFare.amount;
        let currencyCode = baseFare.currencyCode;
        let taxesAmount = passengerFare.taxes.amount;
        let totalFare = passengerFare.totalFare;
        let totalAmount = totalFare.amount;
        let passengerTypeCode = fareBreakdown.passengerTypeQuantity.code;

        let baggageAllowanceMap = {};
        if (
          passengerFare &&
          passengerFare.fareBaggageAllowanceList &&
          passengerFare.fareBaggageAllowanceList.length > 0
        ) {
          passengerFare.fareBaggageAllowanceList.forEach(
            (fareBaggageAllowance) => {
              let flightSegmentRPH = fareBaggageAllowance.flightSegmentRPH;
              let unitOfMeasure = fareBaggageAllowance.unitOfMeasure;
              let unitOfMeasureQuantity =
                fareBaggageAllowance.unitOfMeasureQuantity;
              baggageAllowanceMap[fareBaggageAllowance.flightSegmentRPH] =
                new BaggageAllowanceViewItem(
                  unitOfMeasureQuantity,
                  unitOfMeasure,
                  flightSegmentRPH
                );
            }
          );
        }

        fareBreakdown.travelerRefNumberList.forEach((travelerRefNumber) => {
          let rph = travelerRefNumber.rph;
          let passengerFareViewItem = new PassengerFareViewItem(
            rph,
            baseFareAmount,
            taxesAmount,
            totalAmount,
            currencyCode,
            baggageAllowanceMap,
            passengerTypeCode
          );
          passengerTicketFareBreakdownItems.push(passengerFareViewItem);
        });
      }
    });
  });

  return passengerTicketFareBreakdownItems;
}

function getAirBookRQ(flightBookingFormModel) {
  let travelerInfos = flightBookingFormModel.travelerInfos;
  let quoteID = flightBookingFormModel.quoteID;
  let username = flightBookingFormModel.username;
  let seatRequests = flightBookingFormModel.seatRequests;
  let pricedOffers = flightBookingFormModel.pricedOffers;

  let airTravelerList = [];
  let i = 0;
  travelerInfos.forEach((travelerInfo) => {
    let emailList = null;
    let telephoneList = null;
    if (travelerInfo.emailAddress) {
      emailList = [
        {
          emailAddress: travelerInfo.emailAddress,
        },
      ];
    }

    let custLoyaltyList = [];
    if (
      travelerInfo.ffpNumber != null &&
      travelerInfo.ffpNumber != "" &&
      travelerInfo.flyerAirline != null &&
      travelerInfo.flyerAirline != ""
    ) {
      custLoyaltyList.push({
        vendorCode: travelerInfo.flyerAirline,
        membershipID: travelerInfo.ffpNumber,
      });
    }

    if (travelerInfo.phoneNumber) {
      telephoneList = [
        {
          countryAccessCode: travelerInfo.countryAccessCode,
          areaCityCode: travelerInfo.countryAccessCode,
          phoneNumber: travelerInfo.phoneNumber,
        },
      ];
    }
    if (travelerInfo.passengerTypeCode == "ADT") {
      // console.info(travelerInfo);
      let airPassenger = {
        travelerRefNumber: {
          rph: travelerInfo.travelerRefNumber,
        },
        passengerTypeCode: travelerInfo.passengerTypeCode,
        personName: {
          namePrefix: travelerInfo.nameTitle,
          givenName: travelerInfo.givenName,
          surname: travelerInfo.surname,
          nameTitle: travelerInfo.nameTitle,
        },
        documents: [
          {
            docID: travelerInfo.docID,
            expireDate: travelerInfo.docExpireDate,
            docIssueCountry: travelerInfo.docHolderNationality,
            docHolderNationality: travelerInfo.docHolderNationality,
          },
        ],
        birthDate: travelerInfo.birthDate,
        emailList: emailList,
        telephoneList: telephoneList,
        custLoyaltyList: custLoyaltyList,
      };
      airTravelerList.push(airPassenger);
    } else {
      let airPassenger = {
        travelerRefNumber: {
          rph: travelerInfo.travelerRefNumber,
        },
        passengerTypeCode: travelerInfo.passengerTypeCode,
        personName: {
          namePrefix: travelerInfo.nameTitle,
          givenName: travelerInfo.givenName,
          surname: travelerInfo.surname,
          nameTitle: travelerInfo.nameTitle,
        },
        documents: [
          {
            docID: travelerInfo.docID,
            expireDate: travelerInfo.docExpireDate,
            docIssueCountry: travelerInfo.docHolderNationality,
            docHolderNationality: travelerInfo.docHolderNationality,
          },
        ],
        birthDate: travelerInfo.birthDate,
        emailList: emailList,
        telephoneList: telephoneList,
        custLoyaltyList: custLoyaltyList,
      };
      airTravelerList.push(airPassenger);
    }
  });

  return {
    targetName: "PROD",
    airItinerary: {
      airItineraryRPH: quoteID,
    },
    priceInfo: {
      quoteID: quoteID,
    },
    travelerInfo: {
      airTravelerList: airTravelerList,
      specialReqDetails: {
        seatRequests: seatRequests,
      },
    },
    offer: {
      priced: pricedOffers,
    },
    pos: {
      sourceList: [
        {
          bookingChannel: {
            companyName: {
              code: "connexus",
            },
            type: "7",
          },
          requestorID: {
            id: username,
          },
        },
      ],
    },
  };
}

/*结束 预定页相关*/

//#endregion 获取请求对象- Searching Hotels Request Object

//#region 获取机票信息视图对象

function getFlightViewItemsGroup(pricedItineraryList) {
  let flightViewItemsGroup = {};
  if (!pricedItineraryList || pricedItineraryList.length == 0)
    return flightViewItemsGroup;

  let rphMap = {}; //唯一去重
  let preItineraryEqualsKeyArr = [];

  pricedItineraryList.forEach((pricedItinerary) => {
    //获取origDestRPH -》 Offer的映射表
    let origDestRPHOfferMap = getOrigDestRPHOfferMap(pricedItinerary);
    let airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const quoteID = airItineraryPricingInfo.quoteID;
    const score = pricedItinerary.score;
    let passengerFareViewItems = [];
    // if (priceDetailsIndicator)
    //   passengerFareViewItems = getPassengerFareViewItems(
    //     airItineraryPricingInfo
    //   );

    let relatedFlightViewItemsTotal = getRelatedFlightViewItemsTotal(
      airItineraryPricingInfo
    );

    let airItinerary = pricedItinerary ? pricedItinerary.airItinerary : null;
    let airItineraryRPH = airItinerary.airItineraryRPH;
    let itineraryEqualsKey = airItinerary.equalsKey;
    airItinerary.originDestinationOptions.forEach((originDestinationOption) => {
      let rph = originDestinationOption.rph; //指代不同（航班+branded fare）的组合，当equateKey相同而offer不同时，该rph也不相同
      let equateKey = originDestinationOption.equateKey; //表示同一个航班，包括航线和飞机型号，主要区分相同航班，不同branded fare
      let groupNumber = originDestinationOption.flightSegments[0].groupNumber; //按照groupNumber进行行程分组
      let preItineraryEqualsKey = itineraryEqualsKey.substring(
        0,
        itineraryEqualsKey.indexOf(equateKey)
      );
      if (rphMap[rph]) {
        flightViewItemsGroup[groupNumber][equateKey].forEach(
          (flightViewItem) => {
            //比较获取最优惠价格，主要是为了计算不同计划下是否需要加额外的费用
            if (
              flightViewItem.rph === rph &&
              flightViewItem.bestPrice > relatedFlightViewItemsTotal.amount
            ) {
              flightViewItem.bestAirItineraryRPH = airItineraryRPH;
              flightViewItem.bestItineraryEqualsKey = itineraryEqualsKey;
              flightViewItem.bestPrice = relatedFlightViewItemsTotal.amount;
            }
          }
        );
      }

      //rph相同，但是itinerayQualsKey不同
      if (
        !rphMap[rph] ||
        !preItineraryEqualsKeyArr.includes(preItineraryEqualsKey)
      ) {
        rphMap[rph] = 1;
        preItineraryEqualsKeyArr.push(preItineraryEqualsKey);

        if (!flightViewItemsGroup[groupNumber])
          flightViewItemsGroup[groupNumber] = {};

        if (!flightViewItemsGroup[groupNumber][equateKey])
          flightViewItemsGroup[groupNumber][equateKey] = [];
        let supplierCode =
          airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode;
        let flightViewItem = getFlightViewItem(
          origDestRPHOfferMap,
          rph,
          originDestinationOption,
          relatedFlightViewItemsTotal,
          equateKey,
          itineraryEqualsKey,
          airItineraryRPH,
          score,
          supplierCode,
          quoteID
        );
        flightViewItemsGroup[groupNumber][equateKey].push(flightViewItem);
      }
    });
  });

  return flightViewItemsGroup;
}

function getFlightViewItemQuoteID(pricedItineraryList) {
  let flightViewItemsGroup = {};
  if (!pricedItineraryList || pricedItineraryList.length == 0)
    return flightViewItemsGroup;

  let quoteID = null;
  pricedItineraryList.forEach((pricedItinerary) => {
    //获取origDestRPH -》 Offer的映射表
    let airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    quoteID = airItineraryPricingInfo.quoteID;
  });

  return quoteID;
}

function getOrigDestRPHOfferMap(pricedItinerary) {
  let origDestRPHOfferMap = {};
  if (pricedItinerary.offer && pricedItinerary.offer.summary) {
    pricedItinerary.offer.summary.forEach((offerSummary) => {
      let origDestRPH = offerSummary.origDestRPH;
      let bundleID = offerSummary.bundleID;
      origDestRPHOfferMap[origDestRPH] = bundleID;
    });
  }
  return origDestRPHOfferMap;
}

function getFlightViewItem(
  origDestRPHOfferMap,
  rph,
  originDestinationOption,
  relatedFlightViewItemsTotal,
  equateKey,
  itineraryEqualsKey,
  airItineraryRPH,
  score,
  supplierCode,
  quoteID
) {
  const bundleID = origDestRPHOfferMap[rph];
  const originLocation = originDestinationOption.originLocation;
  const destinationLocation = originDestinationOption.destinationLocation;
  let departureDateTime = null;
  let arrivalDateTime = null;
  const duration = originDestinationOption.duration;
  const connections = originDestinationOption.connections;
  const durationInMins = originDestinationOption.durationInMins;
  const numStops = originDestinationOption.numStops;
  const totalPrice = relatedFlightViewItemsTotal.amount;
  const bestPrice = relatedFlightViewItemsTotal.amount;
  const currency = relatedFlightViewItemsTotal.currencyCode;
  let segments = [];
  originDestinationOption.flightSegments.forEach((e) => {
    let flightSegmentViewItem = getFlightSegmentViewItem(e);
    let d1 = new Date(flightSegmentViewItem.departureDateTime);
    let d2 = new Date(flightSegmentViewItem.arrivalDateTime);
    if (
      departureDateTime == null ||
      departureDateTime.getTime() > d1.getTime()
    ) {
      departureDateTime = d1;
    }

    if (arrivalDateTime == null || arrivalDateTime.getTime() < d2.getTime()) {
      arrivalDateTime = d2;
    }
    segments.push(flightSegmentViewItem);
  });
  // console.info(departureDateTime);
  let flightViewItem = new FlightViewItem(
    originLocation,
    destinationLocation,
    departureDateTime,
    arrivalDateTime,
    duration,
    equateKey,
    rph,
    connections,
    durationInMins,
    numStops,
    totalPrice,
    currency,
    segments,
    itineraryEqualsKey,
    airItineraryRPH,
    bundleID,
    bestPrice,
    score,
    supplierCode,
    quoteID
  );
  return flightViewItem;
}

function getFlightSegmentViewItem(flightSegment) {
  const departureAirportCode = flightSegment.departureAirportCode;
  const arrivalAirportCode = flightSegment.arrivalAirportCode;
  const departureDateTime = flightSegment.departureDateTime;
  const arrivalDateTime = flightSegment.arrivalDateTime;
  const originTerminal = flightSegment.originTerminal;
  const destinationTerminal = flightSegment.destinationTerminal;
  const marketingAirlineCode = flightSegment.marketingAirlineCode;
  const flightNumber = flightSegment.flightNumber;
  const cabinClass = flightSegment.cabinClass;
  const operatingAirlineCode = flightSegment.operatingAirlineCode;
  const operatingFlightNumber = flightSegment.operatingFlightNumber;
  const equipmentCode = flightSegment.equipmentCode;
  const segmentRPH = flightSegment.rph;
  const stopoverInd = flightSegment.stopoverInd;
  const numberInParty = flightSegment.numberInParty;
  const groupNumber = flightSegment.groupNumber;
  const fareBasisCode = flightSegment.fareBasisCode;
  const resBookDesigCode = flightSegment.resBookDesigCode;
  let flightSegmentViewItem = new FlightSegmentViewItem(
    departureAirportCode,
    arrivalAirportCode,
    departureDateTime,
    arrivalDateTime,
    originTerminal,
    destinationTerminal,
    marketingAirlineCode,
    flightNumber,
    cabinClass,
    operatingAirlineCode,
    operatingFlightNumber,
    equipmentCode,
    segmentRPH,
    stopoverInd,
    numberInParty,
    groupNumber,
    fareBasisCode,
    resBookDesigCode
  );
  return flightSegmentViewItem;
}

function getAirportCodeMap(pricedItineraryList) {
  if (!pricedItineraryList || pricedItineraryList.length == 0) return null;

  let airportCodeMap = {};
  pricedItineraryList.forEach((pricedItinerary) => {
    let airItinerary = pricedItinerary ? pricedItinerary.airItinerary : null;
    airItinerary.originDestinationOptions.forEach((originDestinationOption) => {
      originDestinationOption.flightSegments.forEach((flightSegment) => {
        const departureAirportCode = flightSegment.departureAirportCode;
        const arrivalAirportCode = flightSegment.arrivalAirportCode;
        airportCodeMap[departureAirportCode] = null;
        airportCodeMap[arrivalAirportCode] = null;
      });
    });
  });

  return airportCodeMap;
}

function getOrigDestGroupMap(pricedItineraryList, airportCityMap) {
  if (!pricedItineraryList || pricedItineraryList.length == 0) return null;
  let origDestGroupMap = {};
  const pricedItinerary = pricedItineraryList[0];
  const airItinerary = pricedItinerary.airItinerary;
  airItinerary.originDestinationOptions.forEach((originDestinationOption) => {
    let groupNumber = 0;
    originDestinationOption.flightSegments.forEach((flightSegment) => {
      groupNumber = flightSegment.groupNumber;
    });

    const originLocation = originDestinationOption.originLocation;
    let originLocationName = originDestinationOption.originLocation;
    const destinationLocation = originDestinationOption.destinationLocation;
    let destinationLocationName = originDestinationOption.destinationLocation;

    if (airportCityMap && airportCityMap[originLocation]) {
      originLocationName = airportCityMap[originLocation].cityName;
    }
    if (airportCityMap && airportCityMap[destinationLocation]) {
      destinationLocationName = airportCityMap[destinationLocation].cityName;
    }

    origDestGroupMap[groupNumber] = {
      originLocation,
      originLocationName,
      destinationLocation,
      destinationLocationName,
    };
  });

  return origDestGroupMap;
}

function getPassengerFareViewItems(airItineraryPricingInfo) {
  let passengerFareViewItems = [];
  // airItineraryPricingInfo.PTC_FareBreakdowns.forEach((breakdown) => {
  //   let refNumbers = breakdown.travelerRefNumberList;
  //
  //   let ticketFare = getTicketFare(breakdown);
  //   let baseFare = ticketFare.baseFare;
  //   let taxes = ticketFare.taxes;
  //   let totalFare = ticketFare.totalFare;
  //   let baggageAllowances = ticketFare.fareBaggageAllowanceList;
  //   let passengerTypeCode = ticketFare.passengerTypeCode;
  //
  //   let passengerFareViewItem = new PassengerFareViewItem(
  //     refNumbers,
  //     baseFare,
  //     taxes,
  //     totalFare,
  //     baggageAllowances,
  //     passengerTypeCode
  //   );
  //   passengerFareViewItems.push(passengerFareViewItem);
  // });
  passengerFareViewItems = getPassengerTicketFareBreakdownItems(
    airItineraryPricingInfo.PTC_FareBreakdowns
  );
  return passengerFareViewItems;
}

function getPricedItineraryViewItem(pricedItinerary) {
  //获取origDestRPH -》 Offer的映射表
  let origDestRPHOfferMap = getOrigDestRPHOfferMap(pricedItinerary);
  let airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
  //Get price details
  let passengerFareViewItems = getPassengerFareViewItems(
    airItineraryPricingInfo
  );

  let relatedFlightViewItemsTotal = getRelatedFlightViewItemsTotal(
    airItineraryPricingInfo
  );

  let airItinerary = pricedItinerary ? pricedItinerary.airItinerary : null;
  let airItineraryRPH = airItinerary.airItineraryRPH;
  let itineraryEqualsKey = airItinerary.equalsKey;
  let flightViewItems = [];
  let groupIndex = 0;
  airItinerary.originDestinationOptions.forEach((originDestinationOption) => {
    let rph = originDestinationOption.rph; //指代不同（航班+branded fare）的组合，当equateKey相同而offer不同时，该rph也不相同
    let equateKey = originDestinationOption.equateKey; //表示同一个航班，包括航线和飞机型号，主要区分相同航班，不同branded fare
    let groupNumber = originDestinationOption.flightSegments[0].groupNumber; //按照groupNumber进行行程分组
    if (groupNumber == null) {
      groupNumber = groupIndex;
      groupIndex++;
    }

    let flightViewItem = getFlightViewItem(
      origDestRPHOfferMap,
      rph,
      originDestinationOption,
      relatedFlightViewItemsTotal,
      equateKey,
      itineraryEqualsKey,
      airItineraryRPH
    );
    flightViewItems[groupNumber] = flightViewItem;
  });

  let passengers = [];
  pricedItinerary.travelerInfo.airTravelerList.forEach((airTraveler) => {
    let personName = airTraveler.personName;
    let nameTitle = personName.nameTitle;
    let givenName = personName.givenName;
    let surname = personName.surname;
    let passengerTypeCode = airTraveler.passengerTypeCode;
    let surnameRefNumber = airTraveler.travelerRefNumber.surnameRefNumber;
    let travelerRph = airTraveler.travelerRefNumber.rph;
    let phoneNumber = null;
    if (airTraveler.telephoneList && airTraveler.telephoneList.length > 0) {
      let areaCityCode =
        airTraveler.telephoneList[0].areaCityCode == null
          ? ""
          : airTraveler.telephoneList[0].areaCityCode;
      phoneNumber = areaCityCode + airTraveler.telephoneList[0].phoneNumber;
    }
    let emailList = airTraveler.emailList;
    let emailAddress = null;
    if (emailList && emailList.length > 0) {
      emailAddress = emailList[0].emailAddress;
    }

    let documents = airTraveler.documents;
    let docID = null;
    let expireDate = null;
    let docHolderNationality = null;
    if (documents && documents.length > 0) {
      let document = documents[0];
      docID = document.docID;
      expireDate = document.expireDate;
      docHolderNationality = document.docHolderNationality;
    }

    let flightPassengersFormItem = new FlightPassengersFormItem(
      travelerRph,
      passengerTypeCode,
      givenName,
      surname,
      nameTitle,
      null,
      docID,
      expireDate,
      docHolderNationality,
      docHolderNationality,
      emailAddress,
      phoneNumber,
      null,
      null,
      null
    );
    passengers.push(flightPassengersFormItem);
  });

  let pricedItineraryViewItem = new PricedItineraryViewItem(
    flightViewItems,
    passengerFareViewItems,
    null,
    passengers
  );
  return pricedItineraryViewItem;
}

function getTicketFare(breakdown) {
  let ticketFare = null;
  breakdown.passengerFareList.forEach((passengerFare) => {
    if ("TicketFee" === passengerFare.usage) {
      ticketFare = passengerFare;
    }
  });
  return ticketFare;
}

function getRelatedFlightViewItemsTotal(airItineraryPricingInfo) {
  const fareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;
  let amount = null;
  let currencyCode = getTicketFare(
    airItineraryPricingInfo.PTC_FareBreakdowns[0]
  ).totalFare.currencyCode;
  if (fareBreakdowns && fareBreakdowns.length > 0) {
    fareBreakdowns.forEach((fareItem) => {
      let passengerFareList = fareItem.passengerFareList;
      let passengerFare = null;
      if (passengerFareList && passengerFareList.length > 0) {
        // "usage": "TicketFee"
        passengerFare = passengerFareList.find(
          (item) => item.usage === "TicketFee"
        );
        amount += passengerFare.totalFare.amount;
      }
    });
  }
  // let amount =
  //   airItineraryPricingInfo.itinTotalFares &&
  //   airItineraryPricingInfo.itinTotalFares.length > 0
  //     ? airItineraryPricingInfo.itinTotalFares[0].totalFare.amount
  //     : null;

  return {
    amount,
    currencyCode,
  };
}

function getOfferViewItemMap(offerSummary) {
  let offerViewItemMap = {};
  if (!offerSummary || offerSummary.length == 0) return offerViewItemMap;
  offerSummary.forEach((offerInfo) => {
    let name = offerInfo.name;
    let bundleID = offerInfo.bundleID;
    if (offerInfo.shortDescription && offerInfo.shortDescription.subSections) {
      let descriptions = [];
      offerInfo.shortDescription.subSections.forEach((subSection) => {
        if (subSection.paragraphs) {
          let text = subSection.paragraphs[0].text;
          let subCode = subSection.subCode;
          descriptions.push({
            text: text,
            subCode: subCode,
          });
        }
      });
      offerViewItemMap[bundleID] = new OfferViewItem(
        name,
        bundleID,
        descriptions
      );
    }
  });
  return offerViewItemMap;
}

function getMatchFlightViewItemsGroup(
  flightViewItemsGroup,
  groupNumber,
  equateKey
) {
  let specifiedFlightViewItemsEle = flightViewItemsGroup[groupNumber];
  let matchFlightViewItemsGroup = {};
  if (equateKey) {
    //console.log(equateKey);
    Object.keys(specifiedFlightViewItemsEle).forEach((key) => {
      let specifiedFlightViewItems = specifiedFlightViewItemsEle[key];
      let matchFLightViewItems = [];
      specifiedFlightViewItems.forEach((e) => {
        let itineraryEqualsKey = e.itineraryEqualsKey;
        let indexOf = itineraryEqualsKey.indexOf(equateKey);
        if (indexOf === 0) {
          matchFLightViewItems.push(e);
        }
      });

      if (matchFLightViewItems && matchFLightViewItems.length > 0) {
        matchFlightViewItemsGroup[key] = matchFLightViewItems;
      }
    });
  } else {
    matchFlightViewItemsGroup = flightViewItemsGroup[groupNumber];
  }

  return matchFlightViewItemsGroup;
}

function getFlightRuleViewItemMap(fareRuleResponseInfos) {
  if (fareRuleResponseInfos == null || fareRuleResponseInfos.length === 0)
    return null;

  let flightRuleViewItemMap = {};

  fareRuleResponseInfos.forEach((fareRuleResponseInfo) => {
    let fareRuleInfo = fareRuleResponseInfo.fareRuleInfo;
    let departureDate = fareRuleInfo.departureDate;
    let departureAirportCode = fareRuleInfo.departureAirportCode;
    let arrivalAirportCode = fareRuleInfo.arrivalAirportCode;
    let ruleKey =
      departureDate + ":" + departureAirportCode + "-" + arrivalAirportCode;

    let subSections = fareRuleResponseInfo.fareRules.subSections;
    let descriptions = [];
    subSections.forEach((subSection) => {
      let subTitle = subSection.subTitle;
      let subCode = subSection.subCode;
      let subSectionNumber = subSection.subSectionNumber;
      let paragraphs = subSection.paragraphs;
      let content = "";
      paragraphs.forEach((paragraph) => {
        let text = paragraph.text.replaceAll("\n", "<br/>");
        content += text + "<br/>";
      });

      let ruleDescriptionViewItem = new RuleDescriptionViewItem(
        subTitle,
        content,
        parseInt(subSectionNumber),
        subCode
      );
      descriptions.push(ruleDescriptionViewItem);
    });

    let flightRuleViewItem = new FlightRuleViewItem(
      departureDate,
      departureAirportCode,
      arrivalAirportCode,
      descriptions
    );
    flightRuleViewItemMap[ruleKey] = flightRuleViewItem;
  });

  return flightRuleViewItemMap;
}

class FlightViewItem {
  constructor(
    originLocation,
    destinationLocation,
    departureDateTime,
    arrivalDateTime,
    duration,
    equateKey,
    rph,
    connections,
    durationInMins,
    numStops,
    totalPrice,
    currency,
    segments,
    itineraryEqualsKey,
    airItineraryRPH,
    bundleID,
    bestPrice,
    score,
    supplierCode,
    quoteID
  ) {
    this.originLocation = originLocation;
    this.destinationLocation = destinationLocation;
    this.destinationLocation = destinationLocation;
    this.departureDateTime = departureDateTime;
    this.arrivalDateTime = arrivalDateTime;
    this.duration = duration;
    this.equateKey = equateKey;
    this.rph = rph;
    this.connections = connections;
    this.durationInMins = durationInMins;
    this.numStops = numStops;
    this.totalPrice = totalPrice;
    this.currency = currency;
    this.segments = segments;
    this.itineraryEqualsKey = itineraryEqualsKey;
    this.airItineraryRPH = airItineraryRPH;
    this.bundleID = bundleID;
    this.bestPrice = bestPrice;
    this.score = score;
    this.supplierCode = supplierCode;
    this.quoteID = quoteID;
  }
}

class FlightSegmentViewItem {
  constructor(
    departureAirportCode,
    arrivalAirportCode,
    departureDateTime,
    arrivalDateTime,
    originTerminal,
    destinationTerminal,
    marketingAirlineCode,
    flightNumber,
    cabinClass,
    operatingAirlineCode,
    operatingFlightNumber,
    equipmentCode,
    segmentRPH,
    stopoverInd,
    numberInParty,
    groupNumber,
    fareBasisCode,
    resBookDesigCode
  ) {
    this.departureAirportCode = departureAirportCode;
    this.arrivalAirportCode = arrivalAirportCode;
    this.departureDateTime = departureDateTime;
    this.arrivalDateTime = arrivalDateTime;
    this.originTerminal = originTerminal;
    this.destinationTerminal = destinationTerminal;
    this.marketingAirlineCode = marketingAirlineCode;
    this.flightNumber = flightNumber;
    this.cabinClass = cabinClass;
    this.operatingAirlineCode = operatingAirlineCode;
    this.operatingFlightNumber = operatingFlightNumber;
    this.equipmentCode = equipmentCode;
    this.segmentRPH = segmentRPH;
    this.stopoverInd = stopoverInd;
    this.numberInParty = numberInParty;
    this.groupNumber = groupNumber;
    this.fareBasisCode = fareBasisCode;
    this.resBookDesigCode = resBookDesigCode;
  }
}

class PassengerFareViewItem {
  constructor(
    refNumbers,
    baseFare,
    taxes,
    totalFare,
    currency,
    baggageAllowanceMap,
    passengerTypeCode
  ) {
    this.refNumbers = refNumbers;
    this.baseFare = baseFare;
    this.taxes = taxes;
    this.totalFare = totalFare;
    this.currency = currency;
    this.baggageAllowanceMap = baggageAllowanceMap;
    this.passengerTypeCode = passengerTypeCode;
  }
}

class PricedItineraryViewItem {
  constructor(
    flightViewItems,
    passengerFareViewItems,
    offerViewItems,
    passengers
  ) {
    this.flightViewItems = flightViewItems;
    this.passengerFareViewItems = passengerFareViewItems;
    this.offerViewItems = offerViewItems;
    this.passengers = passengers;
  }
}

class OfferViewItem {
  constructor(name, bundleID, descriptions) {
    this.name = name;
    this.bundleID = bundleID;
    this.descriptions = descriptions;
  }
}

class FlightRuleViewItem {
  /*
   *
   * @param ruleDescriptions Array RuleDescriptionViewItem对象数组
   * */
  constructor(
    departureDate,
    departureAirportCode,
    arrivalAirportCode,
    ruleDescriptions
  ) {
    this.departureDate = departureDate;
    this.departureDate = departureAirportCode;
    this.arrivalAirportCode = arrivalAirportCode;
    this.ruleDescriptions = ruleDescriptions;
  }
}

class RuleDescriptionViewItem {
  constructor(title, content, number, subCode) {
    this.title = title;
    this.content = content;
    this.number = number;
    this.subCode = subCode;
  }
}

class FlightBookingFormModel {
  /*
   * @param travelInfos Array FlightPassengerFormTravelerItem对象数组
   * */
  constructor(quoteID, travelerInfos, seatRequests, pricedOffers, username) {
    this.quoteID = quoteID;
    this.travelerInfos = travelerInfos;
    this.seatRequests = seatRequests;
    this.pricedOffers = pricedOffers;
    this.username = username;
  }
}

class FlightPassengersFormModel {
  /*
   * @param travelInfos Array FlightPassengersFormItem对象数组
   * */
  constructor(travelerInfos) {
    this.travelerInfos = travelerInfos;
  }
}

class FlightPassengersFormItem {
  constructor(
    travelerRefNumber,
    passengerTypeCode,
    givenName,
    surname,
    nameTitle,
    birthDate,
    docID,
    docExpireDate,
    docHolderNationality,
    docHolderNationalityName,
    emailAddress,
    phoneNumber,
    phoneCountryAccessCode,
    ffpNumber,
    flyerAirline
  ) {
    this.travelerRefNumber = travelerRefNumber;
    this.givenName = givenName;
    this.surname = surname;
    this.nameTitle = nameTitle;
    this.birthDate = birthDate;
    this.passengerTypeCode = passengerTypeCode;
    this.docID = docID;
    this.docExpireDate = docExpireDate;
    this.docHolderNationality = docHolderNationality;
    this.docHolderNationalityName = docHolderNationalityName;
    this.emailAddress = emailAddress;
    this.phoneNumber = phoneNumber;
    this.phoneCountryAccessCode = phoneCountryAccessCode;
    //frequent flyer number
    this.ffpNumber = ffpNumber;
    this.flyerAirline = flyerAirline;
  }
}

/*
 * 机票搜索排序
 * */

// eslint-disable-next-line no-unused-vars
class SortingOption {
  constructor(type, order) {
    //排序类型，可为PRICE，NAME，STAR_RATING，USER_RATING
    this.type = type;
    //升序降序，可为ASC-升序，DSC-降序
    this.order = order;
  }
}

/*
 * 机票搜索过滤
 * */
// eslint-disable-next-line no-unused-vars
// class FilterOption {
//   constructor(starRatings, userRating, propertyClass, mealPlans, priceRange) {
//     // number， 可选，酒店星级
//     this.starRatings = starRatings;
//     // number，可选，用户评分
//     this.userRating = userRating;
//     // array，可选，住宿类型
//     this.propertyClass = propertyClass;
//     // array，可选，餐饮类型
//     this.mealPlans = mealPlans;
//     // object，可选，价格过滤，结构可参考下面PriceRange对象
//     this.priceRange = priceRange;
//   }
// }
class FlightSeatFormModel {
  constructor(seatRequests) {
    this.seatRequests = seatRequests;
  }
}

class FlightSeatFormItem {
  constructor(seatNumber, rowNumber, offerId) {
    this.seatNumber = seatNumber;
    this.rowNumber = rowNumber;
    this.offerId = offerId;
  }
}

class FlightPricedOfferFormModel {
  constructor() {}
}

class PassengerTicketFareBreakdownViewItem {
  constructor(
    baseFare,
    currencyCode,
    taxesAmount,
    totalAmount,
    baggageAllowanceMap
  ) {
    this.baseFare = baseFare;
    this.currencyCode = currencyCode;
    this.taxesAmount = taxesAmount;
    this.totalAmount = totalAmount;
    this.baggageAllowanceMap = baggageAllowanceMap;
  }
}

class BaggageAllowanceViewItem {
  constructor(unitOfMeasureQuantity, unitOfMeasure, flightSegmentRPH) {
    this.unitOfMeasureQuantity = unitOfMeasureQuantity;
    this.unitOfMeasure = unitOfMeasure;
    this.flightSegmentRPH = flightSegmentRPH;
  }
}

//#endregion 获取机票信息视图对象

export default {
  getLowFareSearchRQ,
  getVerifyPriceRQ,
  getAirGetOfferRQ,
  getAirRuleRQ,
  getPriceWithOfferRQ,
  getAirBookRQ,
  getFlightViewItemsGroup,
  getFlightViewItemQuoteID,
  getAirportCodeMap,
  getOrigDestGroupMap,
  getOfferViewItemMap,
  getMatchFlightViewItemsGroup,
  getSpecifiedPricedItinerary,
  getPricedItineraryViewItem,
  getPassengerTicketFareBreakdownItems,
  getFlightRuleViewItemMap,
  FlightSearchFormModel,
  SortingOption,
  getDefaultFlightSearchFormModel,
  OriginDestinationItem,
  getFlightPassengersForm,
};
