<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useBookingStore } from "@/stores/booking";
import { computed, reactive, ref, watch, watchEffect } from "vue";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength } from "@vuelidate/validators";
import BackButton from "@/components/custom/BackButton.vue";
import BaseBookingCommonOperationItem from "@/views/sales/booking/items/BaseBookingCommonOperationItem.vue";
import BaseBookingFlightItemsPanel from "@/views/sales/booking/items/BaseBookingFlightItemsPanel.vue";
import BaseMixProductPriceSummaryPanel from "@/views/sales/booking/items/BaseMixProductPriceSummaryPanel.vue";
import BaseBookingHotelItemsPanel from "@/views/sales/booking/items/BaseBookingHotelItemsPanel.vue";
import BaseBookingOverViewPanel from "@/views/sales/booking/items/BaseBookingOverViewPanel.vue";
import { useCmsContentStore } from "@/stores/cms";
import Banner from "@/components/custom/Banner.vue";
import bookingApi from "@/apis/bookingApi";
import BaseBookingPackageHotelItemsPanel from "@/views/sales/booking/items/BaseBookingPackageHotelItemsPanel.vue";

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);

const navigation = reactive({
  title: "Booking Details",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Customer Dashboard",
      path: "/profiles/customer-dashboard",
    },
    {
      name: "Booking Details",
    },
  ],
});
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const bookingStore = useBookingStore();
const bookingId = route.query.bookingId;

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

bookingStore.$reset();

syncSessionAndSearch();

async function syncSessionAndSearch() {
  templateStore.pageLoader({ mode: "on" });
  let condition = {
    type: "BOOKING_ID",
    id: bookingId,
    limit: 1,
    offset: 0,
  };

  await bookingStore.searchBookingDetails(condition);
  templateStore.pageLoader({ mode: "off" });
}

const totalResAmount = computed(() => {
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.hotelItems
  ) {
    let totalResAmount = 0;
    bookingStore.bookingDetailsViewItem.hotelItems.forEach((hotelItem) => {
      hotelItem.roomViewItems.forEach((roomViewItem) => {
        const totalPrice = roomViewItem.totalPrice;
        totalResAmount += totalPrice;
      });
    });
    return totalResAmount;
  }
  return null;
});

function goBack() {
  router.back();
}

let messageContent = reactive({
  show: false,
  message: null,
  type: null,
});

async function cancelBooking(bookingId) {
  templateStore.pageLoader({ mode: "on" });
  await bookingStore.cancelBooking(bookingId).then(async (res) => {
    templateStore.pageLoader({ mode: "off" });
    if (res.success) {
      await syncSessionAndSearch();
    } else {
      messageContent.show = true;
      messageContent.message = res.message;
      messageContent.showType = "alert-danger";
      setTimeout(() => {
        messageContent.show = false;
      }, 5000);
    }
  });
}

function printVoucher(bookingId, type, itemId) {
  bookingApi.printVoucher({
    bookingId,
    type,
    itemId,
  });
}

function continueToPayment(bookingId) {
  router.push({
    name: "payment-methods",
    query: {
      bookingId: bookingId,
      invoiceId: bookingStore.bookingDetailsViewItem.invoiceId,
      productType: "hotel",
    },
  });
}

// Validation rules
const rules = {
  pnr: {
    minLength: minLength(6),
    maxLength: maxLength(6),
  },
};
let synchroniszeFormModel = reactive({
  pnr: null,
});
const v$ = useVuelidate(rules, synchroniszeFormModel);

let passengerFares = reactive({
  flightPassengerFareItems: null,
});
let expireInSecond = ref(0);

watchEffect(() => {
  if (bookingStore.bookingDetailsViewItem) {
    let createTime = new Date(
      bookingStore.bookingDetailsViewItem.createDate
    ).getTime();
    let nowTime = new Date().getTime();
    //20 * 60 second
    expireInSecond.value = (createTime - nowTime) / 1000 + 15 * 60;
  }

  let flightPassengerFareItems = null;
  let hotelPassengerFareItems = null;
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.flightItems
  ) {
    flightPassengerFareItems = [];
    bookingStore.bookingDetailsViewItem.flightItems.forEach((flightItem) => {
      let passengerFareItems = flightItem.passengerFareViewItems;
      flightPassengerFareItems.push(...passengerFareItems);
    });
  }
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.hotelItems &&
    bookingStore.bookingDetailsViewItem.hotelItems.length
  ) {
    hotelPassengerFareItems = [];
    bookingStore.bookingDetailsViewItem.hotelItems.forEach((hotelItem) => {
      let roomTypeNames = hotelItem.roomTypeNames;
      let roomTypeName = roomTypeNames.split(",")[0];
      hotelItem.roomViewItems.forEach((roomViewItem) => {
        let totalPrice = roomViewItem.totalPrice;
        let currency = roomViewItem.currency;
        hotelPassengerFareItems.push({
          totalPrice,
          currency,
          roomTypeName,
        });
      });
    });
  }
  passengerFares.flightPassengerFareItems = flightPassengerFareItems;
  passengerFares.hotelPassengerFareItems = hotelPassengerFareItems;

  let flightTotalAmount = 0;
  let hotelTotalAmount = 0;
  let currency = null;
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (flightPassengerFareItems) {
    flightPassengerFareItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      flightTotalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  if (hotelPassengerFareItems) {
    hotelPassengerFareItems.forEach((hotelPassengerFareItem) => {
      hotelTotalAmount += hotelPassengerFareItem.totalPrice;
      if (!currency && hotelPassengerFareItem.currency) {
        currency = hotelPassengerFareItem.currency;
      }
    });
  }
  let totalAmountContent = null;
  if (hotelPassengerFareItems && flightPassengerFareItems) {
    totalAmountContent = {
      totalAmount: hotelTotalAmount + flightTotalAmount,
      currency,
      adultTotalAmount,
      childTotalAmount,
      infantTotalAmount,
      infantNum,
      adultNum,
      childNum,
      taxesTotalAmount,
    };
  } else if (flightPassengerFareItems && !hotelPassengerFareItems) {
    totalAmountContent = {
      totalAmount: flightTotalAmount,
      currency,
      adultTotalAmount,
      childTotalAmount,
      infantTotalAmount,
      infantNum,
      adultNum,
      childNum,
      taxesTotalAmount,
    };
  } else if (!flightPassengerFareItems && hotelPassengerFareItems) {
    totalAmountContent = {
      totalAmount: hotelTotalAmount,
      currency,
      adultTotalAmount,
      childTotalAmount,
      infantTotalAmount,
      infantNum,
      adultNum,
      childNum,
      taxesTotalAmount,
    };
  }

  passengerFares.totalAmountContent = totalAmountContent;
});

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType != 'iphone'">
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-12"
          style="margin-left: 8%"
        >
          <BackButton
            :button-name="'Go to booking list'"
            :route-name="'profiles-customer-dashboard'"
          ></BackButton>
        </div>
      </div>
      <div class="row margin-top-20">
        <div class="col-lg-10 mx-auto">
          <div class="tou_booking_form_Wrapper">
            <div
              class="alert d-flex align-items-center justify-content-between"
              :class="{
                'alert-danger': messageContent.showType == 'alert-danger',
                'alert-success': messageContent.showType == 'alert-success',
              }"
              role="alert"
              v-if="messageContent.show"
            >
              <div class="flex-grow-1 me-3">
                <p class="mb-0">
                  {{ messageContent.message }}
                </p>
              </div>
              <div class="flex-shrink-0">
                <i
                  class="fa fa-fw fa-times-circle"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></i>
              </div>
            </div>

            <!-- Page Content -->
            <div class="content" v-if="bookingStore.bookingDetailsViewItem">
              <BaseBlock>
                <!--       Common Operation         -->
                <BaseBookingCommonOperationItem
                  :equipment-type="equipmentType"
                  v-if="bookingStore.bookingDetailsViewItem.bookingId"
                  :booking-id="bookingStore.bookingDetailsViewItem.bookingId"
                  :booking-status="
                    bookingStore.bookingDetailsViewItem.bookingStatus
                  "
                  @cancelBooking="cancelBooking"
                  @continueToPayment="continueToPayment"
                  @printVoucher="printVoucher"
                  :itemtype="bookingStore.bookingDetailsViewItem.productType"
                  :items="bookingStore.bookingDetailsViewItem.hotelItems"
                  :show-continue-to-payment="
                    expireInSecond > 0 &&
                    bookingStore.bookingDetailsViewItem.bookingStatus !==
                      'CANCELLED' &&
                    bookingStore.bookingDetailsViewItem.bookingStatus !==
                      'CONFIRMED' &&
                    bookingStore.bookingDetailsViewItem.bookingStatus !==
                      'BOOKING_RECEIVED'
                  "
                />
                <!--     End   Common Operation         -->

                <!--  Booking common info                -->
                <BaseBookingOverViewPanel
                  class="booking_tour_form margin-bottom-20"
                  v-if="bookingStore.bookingDetailsViewItem"
                  :booking-details-view-item="
                    bookingStore.bookingDetailsViewItem
                  "
                  :expire-in-second="expireInSecond"
                  :total-amount-content="passengerFares.totalAmountContent"
                />
                <!-- End Booking common info                -->

                <!-- Hotel Info -->
                <BaseBookingHotelItemsPanel
                  :equipment-type="equipmentType"
                  class="booking_tour_form margin-top-20"
                  v-if="
                    bookingStore.bookingDetailsViewItem &&
                    bookingStore.bookingDetailsViewItem.hotelItems &&
                    !bookingStore.bookingDetailsViewItem.flightItems
                  "
                  :hotel-items="bookingStore.bookingDetailsViewItem.hotelItems"
                />
                <!-- END Hotel Info -->

                <!--Package Hotel Info -->
                <BaseBookingPackageHotelItemsPanel
                  :equipment-type="equipmentType"
                  class="booking_tour_form margin-top-20"
                  v-if="
                    bookingStore.bookingDetailsViewItem &&
                    bookingStore.bookingDetailsViewItem.hotelItems &&
                    bookingStore.bookingDetailsViewItem.flightItems
                  "
                  :hotel-items="bookingStore.bookingDetailsViewItem.hotelItems"
                />
                <!-- END Package Hotel Info -->

                <!-- Flight Info -  Bookings Info -->
                <BaseBookingFlightItemsPanel
                  :equipment-type="equipmentType"
                  v-if="
                    bookingStore.bookingDetailsViewItem &&
                    bookingStore.bookingDetailsViewItem.flightItems
                  "
                  :flight-items="
                    bookingStore.bookingDetailsViewItem.flightItems
                  "
                  :airport-city-map="bookingStore.airportCityMap"
                />
                <!-- END Flight Info -->

                <!-- Price Summary Info -->
                <div class="booking_tour_form margin-top-20">
                  <BaseMixProductPriceSummaryPanel
                    :equipment-type="equipmentType"
                    v-if="
                      (passengerFares.flightPassengerFareItems &&
                        passengerFares.flightPassengerFareItems.length > 0) ||
                      (passengerFares.hotelPassengerFareItems &&
                        passengerFares.hotelPassengerFareItems.length > 0)
                    "
                    :flight-passenger-fare-items="
                      passengerFares.flightPassengerFareItems
                    "
                    :hotel-passenger-fare-items="
                      passengerFares.hotelPassengerFareItems
                    "
                    :total-amount-content="passengerFares.totalAmountContent"
                  />
                </div>
                <!-- END Price Summary Info -->
              </BaseBlock>
            </div>
            <!-- END Page Content -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
