import { defineStore } from "pinia";
import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";
import flightBookTools from "@/tools/v2/flight/book/flightBookTools";

export const useFlightBookStore = defineStore("flightBook", {
  state: () => ({ tripAirItem: null }),
  actions: {
    airBook(flightBookingFormModel) {
      const airBookRQ = flightBookTools.buildAirBookRQ(flightBookingFormModel);
      let thisRef = this;
      return flightPrimeBookingApi.book(airBookRQ).then((res) => {
        thisRef.airBookRS = res.data;
        return thisRef.airBookRS;
      });
    },
  },
});
