export class HotelDailyRateVO {
    /**
     *
     * @param date string，日期，格式为yyyy-MM-dd
     * @param roomBasePrice number，基础房价（不包含税费和Markup）
     * @param taxAndFeesPrice number，其他费用
     * @param totalPrice number，单日房价总价
     * @param currency string，货币单位
     * @param feeDescriptions Array，string数组，费用描述
     */
    constructor(
        date,
        roomBasePrice,
        taxAndFeesPrice,
        totalPrice,
        currency,
        feeDescriptions
    ) {
        this.date = date;
        this.roomBasePrice = roomBasePrice;
        this.taxAndFeesPrice = taxAndFeesPrice;
        this.totalPrice = totalPrice;
        this.currency = currency;
        this.feeDescriptions = feeDescriptions;
    }

    addFeeDescription(description){
        if (this.feeDescriptions==null)this.feeDescriptions = [];
        this.feeDescriptions.push(description);
    }
}