import { FlightPriceClassVO } from "@/viewobject/sales/flight/shopping/flightPriceClassVO";

//region Price Classes

function buildPriceClasses(offer) {
  if (!offer || !offer.summary || offer.summary.length === 0) return null;

  const summary = offer.summary;

  const priceClasses = [];
  for (const summaryItem of summary) {
    const name = summaryItem.name;
    const bundleID = summaryItem.bundleID;
    const subSections =
      summaryItem.shortDescription && summaryItem.shortDescription.subSections
        ? summaryItem.shortDescription.subSections
        : null;

    const flightPriceClassVO = new FlightPriceClassVO(bundleID, name, null);

    if (subSections) {
      for (const subSection of subSections) {
        const subCode = subSection.subCode;
        const text = subSection.paragraphs[0].text;
        flightPriceClassVO.addNewPriceDescription(subCode, text);
      }
    }

    priceClasses.push(flightPriceClassVO);
  }

  return priceClasses;
}

//endregion Price Class

export default {
  buildPriceClasses,
};
