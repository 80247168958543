<script setup>
import { useTemplateStore } from "@/stores/template";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useCmsContentStore } from "@/stores/cms";
import { createVNode, reactive, ref, watch } from "vue";
import { useHotelReserveStore } from "@/stores/v2/hotel/reserve/hotelReserveStore";
import hotelCommonTools from "@/tools/v2/hotel/common/hotelCommonTools";
import { HotelReserveFormModel } from "@/formmodel/sales/hotel/reserve/hotelReserveFormModel";
import useVuelidate from "@vuelidate/core";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import HotelItinerarySummaryPanel from "@/views/v2/sales/hotel/items/panel/HotelItinerarySummaryPanel.vue";
import HotelCheckOutContactInfoForm from "@/views/v2/sales/hotel/items/form/checkout/HotelCheckOutContactInfoForm.vue";
import HotelCheckOutRoomPassengerForm from "@/views/v2/sales/hotel/items/form/checkout/HotelCheckOutRoomPassengerForm.vue";
import HotelPoliciesPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelPoliciesPanel.vue";
import HotelPriceSummaryOrBreakdownPanel from "@/views/v2/sales/hotel/items/panel/HotelPriceSummaryOrBreakdownPanel.vue";

const templateStore = useTemplateStore();
const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const AModal = Modal;
const hotelReserveStore = useHotelReserveStore();
//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getPromotionManagements();
cmsContentStore.getFlightPromotionDeals();
cmsContentStore.getPromotionManagements();
cmsContentStore.getFlightPromotionPrimaryDeals();
cmsContentStore.getOurPartner();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "Check Out",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/hotel/hotel-list",
    },
    {
      name: "Hotel Details",
      path: "/sales/hotel/hotel-list",
    },
    {
      name: "Check Out",
    },
  ],
});
const hotelReserveFormModelRef = ref(null);
const tripHotelItemRef = ref(null);
const tripContactInfoRef = ref({
  phoneNumber: null,
  emailAddress: null,
  countryAccessCode: null,
}); //联系电话
//#region query参数请求，进行hold
if (route.query != null && Object.keys(route.query).length > 0) {
  hotelReserveFormModelRef.value = parseHotelReserveFormModel(route.query);
  holdRoomInventory(hotelReserveFormModelRef.value);
}

function parseHotelReserveFormModel(query) {
  const referenceId = query.referenceId;
  const checkIn = query.checkIn;
  const checkOut = query.checkOut;
  const bedTypeName = query.bedTypeName;
  const roomTypeName = query.roomTypeName;
  const roomPassengersFormat = query.roomPassengersFormat;
  const roomPassengers =
    hotelCommonTools.parseRoomPassengers(roomPassengersFormat);

  const hotelReserveFormModel = new HotelReserveFormModel(
    checkIn,
    checkOut,
    null
  );

  roomPassengers.forEach((roomPassenger) => {
    //添加房间
    const roomResGuestFormItem = hotelReserveFormModel.addNewRoomResGuest(
      referenceId,
      roomTypeName,
      bedTypeName
    );
    const adultNum = roomPassenger.adultNum;
    const childAges = roomPassenger.childAges;

    let guestRPH = 0;
    if (adultNum) {
      for (let i = 0; i < adultNum; i++) {
        //添加住客
        roomResGuestFormItem.addNewResGuest(null, guestRPH, "10", 30);
        guestRPH++;
      }
    }
    if (childAges && childAges.length > 0) {
      for (const childAge of childAges) {
        const age = childAge.age;
        roomResGuestFormItem.addNewResGuest(null, guestRPH, "8", age);
        guestRPH++;
      }
    }
  });
  return hotelReserveFormModel;
}

//#endregion

watch(
  () => hotelReserveStore.tripHotelItem,
  (newValue) => {
    tripHotelItemRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//region预定相关逻辑

const v = useVuelidate();

async function confirmBooking(hotelReserveFormModel) {
  const result = await v.value.$validate();
  if (!result) return;

  const hotelReserveFormModelCopy = JSON.parse(
    JSON.stringify(hotelReserveFormModel)
  );
  hotelReserveFormModel = mergeFormModel(
    hotelReserveFormModelCopy,
    tripContactInfoRef.value
  );

  AModal.confirm({
    content: t("you-will-confirm-the-reservation-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        templateStore.pageLoader({ mode: "on" });
        hotelReserveStore
          .initiateReservation(hotelReserveFormModel)
          .then((res) => {
            if (res && !res.success) {
              let error = "";
              if (
                res.errors &&
                res.errors.errors &&
                res.errors.errors.length > 0
              ) {
                error = res.errors.errors[0].stringValue;
              }
              openMassageModal(error, 2);
            } else {
              const hotelBookResRQ = hotelReserveStore.hotelBookResRQ;

              const hotelReservationList = hotelBookResRQ.hotelReservationList;
              let uniqueIDList = null;
              if (hotelReservationList && hotelReservationList.length > 0) {
                uniqueIDList = hotelReservationList[0].uniqueIDList;
              }

              const bookingInfo = getBookingInfo(uniqueIDList);
              router.push({
                name: "payment-methods",
                query: {
                  bookingId: bookingInfo.bookingId,
                  invoiceId: bookingInfo.invoiceId,
                  productType: "hotel",
                },
              });
            }
            AModal.destroyAll();
            templateStore.pageLoader({ mode: "off" });
          });
      }).catch(() => {
        router.push({
          name: "error-500",
          query: { message: "Server Error!" },
        });
        templateStore.pageLoader({ mode: "off" });
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function mergeFormModel(hotelReserveFormModel, tripContactInfo) {
  const roomResGuests = hotelReserveFormModel.roomResGuests;
  for (const roomResGuest of roomResGuests) {
    const resGuests = roomResGuest.resGuests;
    for (const resGuest of resGuests) {
      if (resGuest.primaryIndicator) {
        resGuest.phoneNumber = tripContactInfo.phoneNumber;
        resGuest.countryAccessCode = tripContactInfo.countryAccessCode;
        resGuest.emailAddress = tripContactInfo.emailAddress;
      }
    }
  }
  return hotelReserveFormModel;
}

function getBookingInfo(bookingReferenceIdList) {
  let bookingId = null;
  let invoiceId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "BOOKING_ID") {
        bookingId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      }
    });
  }
  return {
    bookingId,
    invoiceId,
  };
}

//endregion 预定相关逻辑

//region 辅助函数

function holdRoomInventory(hotelReserveFormModel) {
  templateStore.pageLoader({ mode: "on" });
  hotelReserveStore.holdRoomInventory(hotelReserveFormModel).then((res) => {
    if (res && !res.success) {
      let error = "";
      if (res.errors && res.errors.errors && res.errors.errors.length > 0) {
        error = res.errors.errors[0].stringValue;
      }
      swalWarning(error);
    }
    templateStore.pageLoader({ mode: "off" });
  });
}

function goToHotelList() {
  let query = route.query;
  if (query.ttiCode) {
    delete query.ttiCode;
  }
  router.push({ name: "sales-hotel-list-v2", query: query });
}

function goToHotelDetails(query) {
  //details页面带过来的多余参数不清楚会不会有影响，先删掉
  if (query.checkIn) {
    delete query.checkIn;
  }
  if (query.checkOut) {
    delete query.checkOut;
  }
  if (query.roomTypeName) {
    delete query.roomTypeName;
  }
  if (query.bedTypeName) {
    delete query.bedTypeName;
  }
  if (query.referenceId) {
    delete query.referenceId;
  }
  router.push({ name: "sales-hotel-details-v2", query: route.query });
}

//endregion

//region 设备相关

const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);

//endregion

function openMassageModal(message, type, functionType) {
  if (type === 1) {
    AModal.success({
      title: "This is a success message",
      content: message,
      centered: true,
      width: 600,
    });
  } else {
    if (functionType === "AIR_PRICE") {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
      });
    } else {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
      });
    }
  }
}
</script>

<template>
  <!--   Banner Area -->
  <section id="page_banner">
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("check-out") }}</h2>
        <ul>
          <li class="pre-page" @click="goToHotelDetails">
            {{ $t("hotel-details") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("check-out") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToHotelDetails">
              <i
                class="bi bi-arrow-left-square"
                size="40"
                style="
                  font-size: 28px;
                  color: #7ae3c3;
                  cursor: pointer;
                  margin-right: 10px;
                "
              ></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>

      <div v-if="tripHotelItemRef" class="row">
        <div class="col-10">
          <!-- hotel Info -->
          <HotelItinerarySummaryPanel :trip-hotel-item="tripHotelItemRef" />
          <!-- hotel Info END-->

          <!-- Contact Info Form -->
          <HotelCheckOutContactInfoForm
            :tripContactInfo="tripContactInfoRef"
            :equipment-type="equipmentType"
          />
          <!-- Contact Info Form END-->

          <!--Room  Passenger Form -->
          <HotelCheckOutRoomPassengerForm
            :room-passenger-items="
              hotelReserveFormModelRef && hotelReserveFormModelRef.roomResGuests
                ? hotelReserveFormModelRef.roomResGuests
                : []
            "
            :equipment-type="equipmentType"
          />
          <!--Room Passenger Form END-->

          <!--   Policies     -->
          <HotelPoliciesPanel
            v-if="
              tripHotelItemRef.policies && tripHotelItemRef.policies.length > 0
            "
            :policies="tripHotelItemRef.policies"
            :title="$t('policies')"
          />
          <!--   Policies   END  -->

          <!-- Price Summary  Or Breakdown-->
          <HotelPriceSummaryOrBreakdownPanel
            :trip-hotel-item="tripHotelItemRef"
            @confirmBooking="confirmBooking(hotelReserveFormModelRef)"
          />
          <!-- Price Summary  Or Breakdown END -->
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
