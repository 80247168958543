<script setup>
defineProps({
  roomViewItems: {
    type: Array,
  },
  roomTypes: {
    type: Array,
  },
});

function isRoomRemarksShow(roomViewItems) {
  if (roomViewItems && roomViewItems.length > 0) {
    return roomViewItems.some((item) => item.remarks);
  }
}
</script>

<template>
  <div
    class="tour_booking_form_box"
    v-if="roomViewItems && isRoomRemarksShow(roomViewItems)"
  >
    <p class="h4 mb-2">{{ $t("special-requests") }}</p>
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <p class="h6">{{ $t("room-name") }}</p>
        <p v-for="(roomViewItem, roomIndex) in roomViewItems" :key="roomIndex">
          {{ roomTypes[roomIndex] }} - {{ roomIndex + 1 }}
        </p>
      </div>
      <div class="col-lg-6 col-sm-12">
        <p class="h6">{{ $t("special-requests") }}</p>
        <p v-for="(roomViewItem, roomIndex) in roomViewItems" :key="roomIndex">
          <span v-if="roomViewItem.remarks"> {{ roomViewItem.remarks }}</span>
          <br v-else />
        </p>
      </div>
    </div>
    <!--      <div class="valid_date_area">-->
    <!--      <div class="valid_date_area_one">-->
    <!--        <p class="h6">{{ $t("room-name") }}</p>-->
    <!--        <p v-for="(roomViewItem, roomIndex) in roomViewItems" :key="roomIndex">-->
    <!--          {{ roomTypes[roomIndex] }} - {{ roomIndex + 1 }}-->
    <!--        </p>-->
    <!--      </div>-->
    <!--      <div class="valid_date_area_one">-->
    <!--        <p class="h6">{{ $t("special-requests") }}</p>-->
    <!--        <p v-for="(roomViewItem, roomIndex) in roomViewItems" :key="roomIndex">-->
    <!--          <span v-if="roomViewItem.remarks"> {{ roomViewItem.remarks }}</span>-->
    <!--          <br v-else />-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<style scoped></style>
