import { HotelRoomVO } from "@/viewobject/sales/hotel/availability/hotelRoomVO";

export class HotelRoomTypeVO {
  /**
   *
   * @param roomTypeName
   * @param roomTypeCode
   * @param ttiCode
   * @param roomDescription
   * @param minOccupancy
   * @param maxOccupancy
   * @param amenities
   * @param rooms
   */
  constructor(
    roomTypeName,
    roomTypeCode,
    ttiCode,
    roomDescription,
    minOccupancy,
    maxOccupancy,
    amenities,
    rooms
  ) {
    this.ttiCode = ttiCode;
    this.roomTypeName = roomTypeName;
    this.roomTypeCode = roomTypeCode;
    this.roomDescription = roomDescription;
    this.minOccupancy = minOccupancy;
    this.maxOccupancy = maxOccupancy;
    this.amenities = amenities;
    this.rooms = rooms;
  }

  addNewRoom(
    supplier,
    bedTypeName,
    mealName,
    refundable,
    availableQuantity,
    checkInDate,
    checkOutDate,
    nightsStay,
    roomQuantityRequested,
    currencyCode,
    avgBaseUnitPrice,
    feesTaxesInc,
    feesTaxesExc,
    totalPrice,
    referenceId,
    specialRequest,
    cancelPolicies,
    rateComment,
    promotionDescriptions
  ) {
    let roomVO = new HotelRoomVO(
      null,
      supplier,
      bedTypeName,
      mealName,
      refundable,
      availableQuantity,
      checkInDate,
      checkOutDate,
      nightsStay,
      roomQuantityRequested,
      currencyCode,
      avgBaseUnitPrice,
      feesTaxesInc,
      feesTaxesExc,
      totalPrice,
      referenceId,
      specialRequest,
      cancelPolicies,
      rateComment,
      promotionDescriptions
    );

    if (this.rooms == null) this.rooms = [];
    this.rooms.push(roomVO);
  }

  addRoom(roomVO) {
    if (this.rooms == null) this.rooms = [];
    this.rooms.push(roomVO);
  }
}
