<script setup>
import { onMounted, ref, watch } from "vue";
import { FlightShoppingSortFormModel } from "@/formmodel/sales/flight/shopping/flightShoppingSortFormModel";
import { FlightShoppingFilterFormModel } from "@/formmodel/sales/flight/shopping/flightShoppingFilterFormModel";
import { useRoute, useRouter } from "vue-router";
import { Drawer, Empty, Spin } from "ant-design-vue";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import { useTemplateStore } from "@/stores/template";
import flightSortTools from "@/tools/v2/flight/shopping/flightSortTools";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";
import flightShoppingTools from "@/tools/v2/flight/shopping/flightShoppingTools";
import FlightShoppingFilterAndSortForm from "@/views/v2/sales/flight/items/form/FlightShoppingFilterAndSortForm.vue";
import BaseFlightListItem from "@/views/v2/sales/flight/items/group/BaseFlightListItem.vue";
import BaseFlightListMobileItem from "@/views/v2/sales/flight/items/group/BaseFlightListMobileItem.vue";
import FlightFareOptionsSelectPanel from "@/views/v2/sales/flight/items/panel/FlightFareOptionsSelectPanel.vue";

const props = defineProps({
  equipmentType: {},
});
const route = useRoute();
const router = useRouter();
const AEmpty = Empty;
const ADrawer = Drawer;
const ASpin = Spin;
const templateStore = useTemplateStore();
const flightShoppingStore = useFlightShoppingStore();

const fareOptionShow = ref(false);
const flightItemsShowRef = ref(null);
const flightItemsShowLengthRef = ref(null);
const itineraryArrangementRef = ref(null);
//过滤排序相关
const shoppingSortFormModelRef = ref(
  new FlightShoppingSortFormModel("PRICE", "ASC")
);
const shoppingFilterFormModelRef = ref(
  new FlightShoppingFilterFormModel([], [], [], [], [], [], [])
);
const filterOptionsRef = ref(null);
const combineKeys = ref([]); //更新flightItemsShowRef相关
const flightGroupInfosRef = ref([]);
const activeFlightGroupNumberRef = ref(0); //记录当前行程序号

const specificFlightFareOptionsRef = ref(null);
const fareOptionsSelectedRef = ref([]);
//#region pinia监听，数据获取
watch(
  () => flightShoppingStore.flightItemsShow,
  (newValue) => {
    flightItemsShowRef.value = flightSortTools.sortFlightItems(
      newValue,
      shoppingSortFormModelRef.value
    );

    if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
      flightItemsShowLengthRef.value = flightItemsShowRef.value.length;
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => flightShoppingStore.filterOptions,
  (newValue) => {
    filterOptionsRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => flightShoppingStore.itineraryArrangement,
  (newValue) => {
    if (newValue) {
      itineraryArrangementRef.value = newValue.flights;

      if (
        itineraryArrangementRef.value &&
        itineraryArrangementRef.value.length > 0
      ) {
        for (let i = 0; i < itineraryArrangementRef.value.length; i++) {
          flightGroupInfosRef.value[i] = {
            groupNumber: i,
            marketingAirline: null,
            bestTotalPrice: null,
            combineKey: null,
            flightInfo: null,
          };
        }
      }
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => flightShoppingStore.flightFareOptionsShow,
  (newValue) => {
    if (newValue) {
      specificFlightFareOptionsRef.value = newValue;
    }
  },
  { immediate: true, deep: true }
);
//endregion

const showMaxIndex = ref(15);
const isLoadMore = ref(true);

function resetShowMore() {
  showMaxIndex.value = 15;
  isLoadMore.value = true;
}

function loadMoreItems() {
  showMaxIndex.value += 15;
  if (showMaxIndex.value >= flightItemsShowLengthRef.value) {
    isLoadMore.value = false;
  }
}

//#region 过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  const flightItemsShowCopy = JSON.parse(
    JSON.stringify(flightShoppingStore.flightItemsShow)
  );
  if (filterFormModel) {
    shoppingFilterFormModelRef.value = filterFormModel;
    flightItemsShowRef.value = flightFilterTools.filterFlightItems(
      flightItemsShowCopy,
      shoppingFilterFormModelRef.value
    );
  }

  if (sortFormModel) {
    shoppingSortFormModelRef.value = sortFormModel;
    flightItemsShowRef.value = flightSortTools.sortFlightItems(
      flightItemsShowRef.value,
      shoppingSortFormModelRef.value
    );
  }

  if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
    flightItemsShowLengthRef.value = flightItemsShowRef.value.length;
  }
  resetShowMore();
}

//#endregion

//#region 辅助函数
function getAirportCityName(code) {
  if (
    !flightShoppingStore.airportCityMap ||
    !flightShoppingStore.airportCityMap[code]
  )
    return code;
  //可以通过语言获取不同类型的名字
  return flightShoppingStore.airportCityMap[code].cityName;
}

//显示指定飞行顺序的航班列表
function goToSpecificFlightGroup(
  changeType,
  tripSequenceNumber,
  itineraryArrangement,
  fareOptionsSelected,
  flightGroupInfos
) {
  // console.info("Change");
  //完成所有航班Fare Options的选择
  if (
    tripSequenceNumber === itineraryArrangement.length &&
    tripSequenceNumber === fareOptionsSelected.length &&
    changeType === "OPTION"
  ) {
    //选完
    const itineraryFareOptionKey =
      flightShoppingTools.buildItineraryFareOptionKey(fareOptionsSelected);
    const airItineraryRPH = flightShoppingStore.findItineraryRPH(
      itineraryFareOptionKey
    );

    const routeUrl = router.resolve({
      name: "sales-flight-check-out-v2",
    });

    window.open(
      routeUrl.href + "?airItineraryRPH=" + airItineraryRPH,
      "_blank"
    );
  }
  //通过change flight，跳转至Flight options
  else if (changeType === "CHANGE" && tripSequenceNumber >= 0) {
    const specificFlightGroupInfo = flightGroupInfos[tripSequenceNumber];
    combineKeys.value = specificFlightGroupInfo.combineKey;
    clearFlightAndFareOptionSelected(tripSequenceNumber);

    if (tripSequenceNumber === 0) {
      flightShoppingStore.changeFlightItemsShow(0, null);
    } else {
      const preTripSequenceNumber = tripSequenceNumber - 1;
      flightShoppingStore.changeFlightItemsShow(
        tripSequenceNumber,
        combineKeys.value.join("#"),
        fareOptionsSelected,
        fareOptionsSelected[preTripSequenceNumber].totalPrice,
        "UP"
      );
    }
    activeFlightGroupNumberRef.value = tripSequenceNumber;
    fareOptionShow.value = false;
  }
  //选择完Fare options，跳转至下一航班Flight options
  else if (changeType === "OPTION") {
    if (tripSequenceNumber === 0) {
      flightShoppingStore.changeFlightItemsShow(0, null);
    } else {
      const preTripSequenceNumber = tripSequenceNumber - 1;
      const specificFlightGroupInfo =
        flightGroupInfosRef.value[tripSequenceNumber];
      //选择完option之后存储价格
      specificFlightGroupInfo.bestTotalPrice =
        fareOptionsSelected[preTripSequenceNumber].totalPrice;
      const combineKey = specificFlightGroupInfo.combineKey;
      //改变机票列表显示数据
      flightShoppingStore.changeFlightItemsShow(
        tripSequenceNumber,
        combineKey,
        fareOptionsSelected,
        fareOptionsSelected[preTripSequenceNumber].totalPrice,
        "UP"
      );
    }

    if (
      !(
        tripSequenceNumber === itineraryArrangement.length &&
        tripSequenceNumber === fareOptionsSelected.length
      )
    ) {
      activeFlightGroupNumberRef.value = tripSequenceNumber;
      fareOptionShow.value = false;
    }
  }

  clearFlightSortAndFilterConditions();
}

//查看Fare options
function viewFareOptions(
  currentTripSequenceNumber,
  itineraryArrangement,
  fareOptionsSelected,
  originDestinationKey,
  supplierCodes,
  bestTotalPrice,
  flightInfo
) {
  if (!combineKeys.value) combineKeys.value = [];

  combineKeys.value.push(originDestinationKey);
  //记录当前选择航班
  flightGroupInfosRef.value[currentTripSequenceNumber].flightInfo = flightInfo;
  flightGroupInfosRef.value[currentTripSequenceNumber].marketingAirline =
    flightInfo.flightSegments[0].marketingAirlineCode;
  //存储下一航班所需过滤条件
  const nextTripSequenceNumber = currentTripSequenceNumber + 1;
  if (currentTripSequenceNumber < itineraryArrangement.length - 1) {
    const specificFlightGroupInfo =
      flightGroupInfosRef.value[nextTripSequenceNumber];
    specificFlightGroupInfo.supplierCodes = supplierCodes;

    specificFlightGroupInfo.combineKey = combineKeys.value;

    specificFlightGroupInfo.marketingAirline =
      flightInfo.flightSegments[0].marketingAirlineCode;
  }

  fareOptionsSelected.splice(
    currentTripSequenceNumber,
    fareOptionsSelected.length - currentTripSequenceNumber
  );
  flightShoppingStore.changeFareOptionsShow(
    fareOptionsSelected,
    supplierCodes,
    originDestinationKey,
    bestTotalPrice,
    "UP"
  );
  fareOptionShow.value = true;
}

//清除指定飞行顺序后的所有已选择的航班
function clearFlightAndFareOptionSelected(activeFlightGroupNumber) {
  fareOptionsSelectedRef.value.splice(
    activeFlightGroupNumber,
    fareOptionsSelectedRef.value.length - activeFlightGroupNumber
  );
  for (
    let i = activeFlightGroupNumber + 1;
    i < flightGroupInfosRef.value.length;
    i++
  ) {
    const specificFlightGroupInfo =
      flightGroupInfosRef.value[activeFlightGroupNumber];
    specificFlightGroupInfo.flightInfo = null;
  }
}

function clearFlightSortAndFilterConditions() {
  shoppingSortFormModelRef.value = new FlightShoppingSortFormModel(
    "PRICE",
    "ASC"
  );
  shoppingFilterFormModelRef.value = new FlightShoppingFilterFormModel(
    [],
    [],
    [],
    [],
    [],
    [],
    []
  );
}

function getAirlineName(airlineCode) {
  return "airlineName." + airlineCode;
}

//--------------移动端操作--------------
const filterAndSortOpenRef = ref(false);
const showFilterAndSortDrawer = () => {
  filterAndSortOpenRef.value = true;
};
const closeFilterAndSortDrawer = () => {
  filterAndSortOpenRef.value = false;
};

//----------移动端加载----------------

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  const distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;

  if (distance === 0) {
    loadMoreItems();
  }
}

//--------------移动端操作END--------------
//#endregion
</script>

<template>
  <section id="explore_area" class="section_padding">
    <div class="container">
      <div
        class="row"
        v-if="itineraryArrangementRef && itineraryArrangementRef.length > 0"
      >
        <div class="col-lg-12">
          <div class="flight-list-navigation p-3 font-14">
            <div
              v-if="
                itineraryArrangementRef && itineraryArrangementRef.length > 0
              "
              class="flex-row align-items-center"
            >
              <div class="flex-row list-navigation-items justify-content-start">
                <div
                  v-for="(
                    originDesItem, originDesIndex
                  ) in itineraryArrangementRef"
                  :key="originDesIndex"
                  class="list-nav-item mg-r-8 flex cursor-point"
                >
                  <div class="flex-column">
                    <div
                      :class="{
                        font_weight:
                          activeFlightGroupNumberRef === originDesIndex &&
                          !fareOptionShow,
                        'text-muted':
                          activeFlightGroupNumberRef !== originDesIndex,
                      }"
                    >
                      <div
                        v-if="
                          originDesIndex < activeFlightGroupNumberRef ||
                          (originDesIndex === activeFlightGroupNumberRef &&
                            fareOptionShow)
                        "
                      >
                        <div>
                          {{
                            $t(
                              getAirlineName(
                                flightGroupInfosRef[activeFlightGroupNumberRef]
                                  .marketingAirline
                              )
                            )
                          }}
                          · {{ originDesItem.originLocationCode }} ->
                          {{ originDesItem.destinationLocationCode }}
                        </div>
                        <span
                          v-if="
                            (fareOptionShow &&
                              originDesIndex <= activeFlightGroupNumberRef) ||
                            (!fareOptionShow &&
                              originDesIndex < activeFlightGroupNumberRef)
                          "
                          class="text-primary"
                          @click="
                            goToSpecificFlightGroup(
                              'CHANGE',
                              originDesIndex,
                              itineraryArrangementRef,
                              fareOptionsSelectedRef,
                              flightGroupInfosRef
                            )
                          "
                          >{{ $t("change-flight") }}</span
                        >
                      </div>
                      <div
                        v-else-if="
                          originDesIndex === activeFlightGroupNumberRef &&
                          !fareOptionShow
                        "
                      >
                        {{
                          $t("choose-flight-to", {
                            locationName: getAirportCityName(
                              itineraryArrangementRef[originDesIndex]
                                .destinationLocationCode
                            ),
                          })
                        }}
                      </div>
                      <div
                        v-if="
                          originDesIndex > activeFlightGroupNumberRef &&
                          !fareOptionShow
                        "
                      >
                        {{
                          $t("choose-flight-to", {
                            locationName: getAirportCityName(
                              originDesItem.destinationLocationCode
                            ),
                          })
                        }}
                      </div>
                    </div>
                  </div>
                  <i
                    v-if="
                      (originDesIndex <= activeFlightGroupNumberRef &&
                        fareOptionShow) ||
                      (originDesIndex < itineraryArrangementRef.length - 1 &&
                        !fareOptionShow)
                    "
                    class="fa-solid fa-angle-right mg-t-3 mg-l-8"
                  ></i>
                  <div
                    v-if="
                      fareOptionShow &&
                      activeFlightGroupNumberRef === originDesIndex
                    "
                    :class="{
                      font_weight: fareOptionShow,
                    }"
                    class="mg-l-8"
                  >
                    {{ $t("fare-options") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 手机端过滤排序展示-->
      <div class="row" v-if="equipmentType === 'iphone'">
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-12 mg-t-15"
          v-if="
            shoppingFilterFormModelRef &&
            shoppingSortFormModelRef &&
            !fareOptionShow
          "
        >
          <div class="filter-sort">
            <div class="filtrate_btn h5" @click="showFilterAndSortDrawer">
              {{ $t("filter-and-sort") }}<i class="fa fa-fw fa-angle-down"></i>
            </div>
          </div>
          <a-drawer
            title="Filter Option"
            placement="bottom"
            :open="filterAndSortOpenRef"
            @close="closeFilterAndSortDrawer"
          >
            <FlightShoppingFilterAndSortForm
              :active-flight-group-info="
                itineraryArrangementRef && itineraryArrangementRef.length
                  ? itineraryArrangementRef[activeFlightGroupNumberRef]
                  : {}
              "
              :filterOptions="filterOptionsRef"
              :flightItemsLength="flightItemsShowLengthRef"
              :shoppingFilterFormModel="shoppingFilterFormModelRef"
              :shoppingSortFormModel="shoppingSortFormModelRef"
              @sortAndFilter="sortAndFilter"
            />
          </a-drawer>
        </div>
      </div>
      <!-- 手机端过滤排序展示END-->

      <!-- 过滤排序和机票列表-->
      <div class="row" v-if="!fareOptionShow">
        <div class="col-lg-3" v-if="equipmentType !== 'iphone'">
          <!-- 过滤排序-->
          <div class="left_side_search_area">
            <FlightShoppingFilterAndSortForm
              :active-flight-group-info="
                itineraryArrangementRef && itineraryArrangementRef.length
                  ? itineraryArrangementRef[activeFlightGroupNumberRef]
                  : {}
              "
              :filterOptions="filterOptionsRef"
              :flightItemsLength="flightItemsShowLengthRef"
              :shoppingFilterFormModel="shoppingFilterFormModelRef"
              :shoppingSortFormModel="shoppingSortFormModelRef"
              @sortAndFilter="sortAndFilter"
            />
          </div>
          <!-- 过滤排序END-->
        </div>
        <!-- 机票列表-->
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="flight_list"
                v-if="flightItemsShowRef && flightItemsShowRef.length > 0"
              >
                <div
                  v-for="(flightItem, flightIndex) in flightItemsShowRef"
                  :key="flightIndex"
                >
                  <BaseFlightListItem
                    v-if="
                      flightIndex < showMaxIndex && equipmentType !== 'iphone'
                    "
                    :flightInfo="flightItem"
                    :flightInfoIndex="flightIndex"
                    :previous-option-price="
                      flightGroupInfosRef[activeFlightGroupNumberRef] &&
                      flightGroupInfosRef[activeFlightGroupNumberRef]
                        .bestTotalPrice
                        ? flightGroupInfosRef[activeFlightGroupNumberRef]
                            .bestTotalPrice
                        : null
                    "
                    @viewFareOptions="
                      (
                        originDestinationKey,
                        supplierCodes,
                        bestTotalPrice,
                        flightInfo
                      ) =>
                        viewFareOptions(
                          activeFlightGroupNumberRef,
                          itineraryArrangementRef,
                          fareOptionsSelectedRef,
                          originDestinationKey,
                          supplierCodes,
                          bestTotalPrice,
                          flightInfo
                        )
                    "
                  />
                  <BaseFlightListMobileItem
                    v-else-if="
                      showMaxIndex > flightIndex && equipmentType === 'iphone'
                    "
                    :flightInfo="flightItem"
                    :flightInfoIndex="flightIndex"
                    :previous-option-price="
                      flightGroupInfosRef[activeFlightGroupNumberRef] &&
                      flightGroupInfosRef[activeFlightGroupNumberRef]
                        .bestTotalPrice
                        ? flightGroupInfosRef[activeFlightGroupNumberRef]
                            .bestTotalPrice
                        : null
                    "
                    @viewFareOptions="
                      (
                        originDestinationKey,
                        supplierCodes,
                        bestTotalPrice,
                        flightInfo
                      ) =>
                        viewFareOptions(
                          activeFlightGroupNumberRef,
                          itineraryArrangementRef,
                          fareOptionsSelectedRef,
                          originDestinationKey,
                          supplierCodes,
                          bestTotalPrice,
                          flightInfo
                        )
                    "
                  />
                </div>
              </div>

              <div class="text-center" v-else>
                <AEmpty></AEmpty>
              </div>

              <div
                class="load_more_flight"
                v-if="
                  flightItemsShowRef &&
                  flightItemsShowRef.length > 15 &&
                  isLoadMore
                "
              >
                <Spin></Spin>
              </div>
            </div>
          </div>
        </div>
        <!-- 机票列表END-->
      </div>
      <!-- 过滤排序和机票列表END-->

      <!-- Fare Options -->
      <div v-else class="row">
        <FlightFareOptionsSelectPanel
          :active-flight-group-number="activeFlightGroupNumberRef"
          :airport-city-map="flightShoppingStore.airportCityMap"
          :fare-option-selected="fareOptionsSelectedRef"
          :fare-options="specificFlightFareOptionsRef"
          :flight-info="
            flightGroupInfosRef[activeFlightGroupNumberRef]
              ? flightGroupInfosRef[activeFlightGroupNumberRef].flightInfo
              : null
          "
          @goToSpecificFlightGroup="
            (nextFlightGroupNumber) =>
              goToSpecificFlightGroup(
                'OPTION',
                nextFlightGroupNumber,
                itineraryArrangementRef,
                fareOptionsSelectedRef
              )
          "
        />
      </div>
      <!-- Fare Options END -->
    </div>
  </section>
</template>
<style scoped></style>
