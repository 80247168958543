<script setup>
import { reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { email, integer, required } from "@vuelidate/validators";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

const { t, locale } = useI18n(); // 解构调用函数

const props = defineProps({
  tripContactInfo: {},
  equipmentType: {},
});

const tripContactInfoRef = ref(null);
watch(
  () => props.tripContactInfo,
  (newValue) => {
    tripContactInfoRef.value = newValue;
  },
  { deep: true, immediate: true }
);
const rules = reactive({
  phoneNumber: { required, integer },
  emailAddress: { required, email },
  countryAccessCode: { required },
});

const v = useVuelidate(rules, tripContactInfoRef);

function countryChange(value) {
  tripContactInfoRef.value.countryAccessCode = value.dialCode;
}

//region 电话号码
function phoneNumberInput(value, event) {
  if (
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    tripContactInfoRef.value.phoneNumber = event.nationalNumber;
  } else if (
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null
  ) {
    tripContactInfoRef.value.phoneNumber = event.formatted;
  }
}
</script>

<template>
  <div class="tour_booking_form_box">
    <p class="h4 mb-2">
      {{ $t("contact-info") }}
    </p>
    <div class="row">
      <div
        class="col-lg-6 col-6"
        :class="{
          'col-lg-12': equipmentType === 'iphone',
          'col-12': equipmentType === 'iphone',
        }"
      >
        <label>
          {{ $t("phone-number") }}
          <small class="text-lowercase"><code>*</code></small>
        </label>
        <VueTelInput
          id="phoneEle"
          autocomplete="off"
          :autoFormat="false"
          :inputOptions="{
            placeholder: $t('please-enter'),
          }"
          v-model="v.phoneNumber.$model"
          :class="{
            'is-invalid': v.phoneNumber.$errors.length > 0,
          }"
          @blur="v.phoneNumber.$touch"
          @input="phoneNumberInput"
          @country-changed="countryChange"
          class="form-control flex"
        />
      </div>
      <div
        class="col-lg-6 col-6"
        :class="{
          'col-lg-12': equipmentType === 'iphone',
          'col-12': equipmentType === 'iphone',
        }"
      >
        <label>
          {{ $t("email") }}
          <small class="text-lowercase"><code>*</code></small>
        </label>
        <input
          type="email"
          class="form-control"
          id="passenger-form-email"
          name="passenger-form-email"
          placeholder="(e.g. xxxxxxx@gmail.com)"
          autocomplete="off"
          :class="{
            'is-invalid': v.emailAddress.$errors.length,
          }"
          v-model="v.emailAddress.$model"
          @blur="v.emailAddress.$touch"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
