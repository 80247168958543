import axios from "@/utils/axiosUtils.js";

/*
 * 飞机地点自动补全
 * @param keyword string 必填，地点关键字，自动补全采用prefix匹配模式
 * @param language string 可选，响应内容的语言内容，默认为en
 */
function airportCities(keyword, language) {
  language = language == null ? "en" : language;
  let url = "/api/v3/content/air/airport-cities";
  return axios.get(url, {
    params: {
      language: language,
      keyword: keyword,
      type: "http",
    },
  });
}

function frequentFlyerPrograms() {
  let url = "/api/v3/content/air/frequent-flyer-programs";
  return axios.get(url);
}

function airportCity(airportCode) {
  let url = "/api/v3/content/air/airportcity/" + airportCode;
  return axios.get(url);
}

function getVendorOptions() {
  let url = "/api/v3/sales/shopping/air/transmitting-systems";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  airportCity,
  airportCities,
  frequentFlyerPrograms,
  getVendorOptions,
};