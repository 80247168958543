<script setup>
import HotelCheckOutRoomPassengerItem from "@/views/v2/sales/hotel/items/form/checkout/HotelCheckOutRoomPassengerItem.vue";

defineProps({
  title: {},
  subTitle: {},
  resGuests: {},
  roomIndex: {},
  equipmentType: {},
});

function getPassengerNumAge(resGuests) {
  let adultNum = 0;
  let childNum = 0;
  let childAges = [];
  if (resGuests && resGuests.length > 0) {
    resGuests.forEach((item) => {
      if (item.ageQualifyingCode === "10") {
        adultNum++;
      } else {
        childNum++;
        childAges.push(item.age);
      }
    });
  }
  return {
    adultNum: adultNum,
    childAges: childAges,
    childNum: childNum,
  };
}

function sortChildAges(ages) {
  if (ages && ages.length > 0) {
    return ages.sort((a, b) => a - b);
  }
}

function initialResGuests(resGuests, roomIndex) {
  let hasPrimaryIndicator = false;

  for (const resGuest of resGuests) {
    if (!hasPrimaryIndicator) {
      if (resGuest.ageQualifyingCode === "10") resGuest.primaryIndicator = true;
      hasPrimaryIndicator = true;
    }
  }
  return resGuests;
}
</script>

<template>
  <div class="mb-3">
    <div class="row">
      <div class="col font-14">
        <h4>{{ title }}:</h4>
        <span class="font_weight">{{ subTitle }}</span>
        <span class="text-muted mg-l-8"
          >{{ getPassengerNumAge(resGuests).adultNum }} {{ $t("adult") }} ,{{
            getPassengerNumAge(resGuests).childNum
          }}
          {{ $t("child") }} (<span
            v-for="(age, index) in sortChildAges(
              getPassengerNumAge(resGuests).childAges
            )"
            :key="index"
            >{{ age }}
            <span
              v-if="index < getPassengerNumAge(resGuests).childAges.length - 1"
              >,</span
            ></span
          >{{ $t("years-old") }})
        </span>
      </div>
    </div>
    <div v-if="resGuests && resGuests.length > 0">
      <div
        v-for="(resGuest, guestIndex) in initialResGuests(resGuests, roomIndex)"
        :key="guestIndex"
      >
        <div
          v-if="
            resGuest.ageQualifyingCode === '10' &&
            (resGuest.primaryIndicator || resGuest.mainIndicator)
          "
        >
          <HotelCheckOutRoomPassengerItem
            :res-guest="resGuest"
            :equipment-type="equipmentType"
          />
        </div>
      </div>
    </div>

    <slot name="content"></slot>
  </div>
</template>

<style scoped></style>
