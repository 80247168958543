<script setup>
import { ref, watch } from "vue";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import dateUtils from "@/utils/dateUtils";

import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";

const AModal = Modal;
const { t, locale } = useI18n(); // 解构调用函数
const props = defineProps({
  fareOptions: {},
  flightInfo: {},
  fareOptionSelected: {},
  activeFlightGroupNumber: {},
  airportCityMap: {},
});

const emits = defineEmits();

const flightShoppingStore = useFlightShoppingStore();
const fareOptionGroupMapRef = ref(null);
const fareOptionsSelectedRef = ref([]);
const flightInfoRef = ref(null);
const fareActiveKeyIndex = ref(null);
const fareClockRef = ref(null);
const airportCityMapRef = ref(null);

watch(
  () => props.flightInfo,
  (newValue) => {
    flightInfoRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => props.fareOptionSelected,
  (newValue) => {
    fareOptionsSelectedRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => props.fareOptions,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      newValue.sort((a, b) => a.totalPrice - b.totalPrice); //排序

      fareOptionGroupMapRef.value = newValue.reduce((acc, currentObj) => {
        const key = currentObj.supplierCode;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(currentObj);
        return acc;
      }, {});

      fareActiveKeyIndex.value =
        Object.keys(fareOptionGroupMapRef.value)[0] + "-" + 0;
      const key = Object.keys(fareOptionGroupMapRef.value)[0];
      fareOptionsSelectedRef.value[props.activeFlightGroupNumber] =
        fareOptionGroupMapRef.value[key]
          ? fareOptionGroupMapRef.value[key][0]
          : {};

      clock(fareOptionsSelectedRef.value.expirationDateTime);
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.airportCityMap,
  (newValue) => {
    airportCityMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);

// 将分钟数量转换为小时和分钟字符串
function formatHourMinute(minutes) {
  let time = {
    hour:
      Math.floor(minutes / 60) < 10 && Math.floor(minutes / 60) > 0
        ? "0" + Math.floor(minutes / 60)
        : Math.floor(minutes / 60),
    minute:
      minutes % 60 < 10 && minutes % 60 > 0
        ? "0" + (minutes % 60)
        : minutes % 60,
  };
  return time.hour + "h " + time.minute + "m";
}

//当地时间
function formatLocalTime(dateTime) {
  let dateTimeStr = dateTime;
  if (dateTimeStr) {
    let timeZone = "";
    let timeSplit = dateTimeStr.split("T")[1];
    if (dateTimeStr.indexOf("Z") >= 0) {
      timeZone = "+00:00";
    } else {
      timeZone = timeSplit.substring(12);
    }

    let time = timeSplit.substring(0, 5);

    return {
      time: time,
      timeZone: timeZone,
    };
  }
}

function getAirportCityName(code) {
  if (
    !flightShoppingStore.airportCityMap ||
    !flightShoppingStore.airportCityMap[code]
  )
    return code;
  //可以通过语言获取不同类型的名字
  return flightShoppingStore.airportCityMap[code].cityName;
}

function getAirportName(code) {
  if (!airportCityMapRef.value || !airportCityMapRef.value[code]) return code;
  //可以通过语言获取不同类型的名字
  return airportCityMapRef.value[code].airport;
}

//美式英语的时间格式
function formatEnglishDate(date) {
  date = new Date(date);
  let monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec",
  ];
  let suffix = ["st", "nd", "rd", "th"];

  let year = date.getFullYear(); //年
  let month = monthArr[date.getMonth()]; //月
  let ddate = date.getDate(); //日
  let time = date.toLocaleTimeString("chinese", { hour12: false }).slice(0, 5);

  if (ddate % 10 < 1 || ddate % 10 > 3) {
    ddate = ddate + suffix[3];
  } else if (ddate % 10 == 1) {
    ddate = ddate + suffix[0];
  } else if (ddate % 10 == 2) {
    ddate = ddate + suffix[1];
  } else {
    ddate = ddate + suffix[2];
  }
  return { time: time, ddate: ddate, month: month, year: year };
}

function changeFare(activeKeyIndex, fareItem) {
  clearInterval(fareClockRef.value);
  clock(fareItem.expirationDateTime);
  fareActiveKeyIndex.value = activeKeyIndex;
  fareOptionsSelectedRef.value[props.activeFlightGroupNumber] = fareItem;
}

function filterFareDescriptions(priceClassId) {
  if (
    flightShoppingStore.priceClasses &&
    flightShoppingStore.priceClasses.length
  ) {
    return flightShoppingStore.priceClasses.find(
      (item) => item.priceClassId === priceClassId
    );
  } else {
    return {};
  }
}

function goToSpecificFlightGroup() {
  let nextFlightGroupNumber = props.activeFlightGroupNumber + 1;
  emits("goToSpecificFlightGroup", nextFlightGroupNumber);
}

function clock(expirationDate) {
  let isBefore = false;
  fareClockRef.value = setInterval(function () {
    isBefore = dateUtils.compareIfBefore(expirationDate, new Date());
    if (isBefore) {
      console.log(111);
      //如果比现在的时间早，提示fare 过期
      AModal.warning({
        title: "This is a warning message",
        content: "Fare is expired!",
        centered: true,
        width: 600,
        wrapClassName: "confirmModal",
        onOk() {
          return new Promise((resolve, reject) => {
            location.reload(); //刷新页面
            AModal.destroyAll();
          }).catch(() => console.log("Oops errors!"));
        },
      });
      isBefore = false;
      clearInterval(fareClockRef.value);
    }
  }, 1000);
}
</script>

<template>
  <div class="col-12">
    <div class="tour_booking_form_box">
      <div v-if="flightInfoRef" class="booking_tour_form">
        <h5>
          {{
            $t("flight-to", {
              locationCode: flightInfoRef.destinationLocationCode,
            })
          }}
          <span class="mg-l-10">
            {{ formatEnglishDate(flightInfoRef.departureDateTime).ddate }}
            {{ formatEnglishDate(flightInfoRef.departureDateTime).month }}
          </span>
        </h5>
        <div>
          <div class="orgin-des">
            <div class="location">
              {{ getAirportName(flightInfoRef.departureAirportCode) }}({{
                flightInfoRef.departureAirportCode
              }}) - {{ getAirportName(flightInfoRef.arrivalAirportCode) }}({{
                flightInfoRef.arrivalAirportCode
              }})
            </div>
            <div
              v-if="
                formatLocalTime(flightInfoRef.departureDateTime) &&
                formatLocalTime(flightInfoRef.arrivalDateTime)
              "
              class="text-muted"
            >
              {{ formatLocalTime(flightInfoRef.departureDateTime).time }} ({{
                formatLocalTime(flightInfoRef.departureDateTime).timeZone
              }}) -
              {{ formatLocalTime(flightInfoRef.arrivalDateTime).time }} ({{
                formatLocalTime(flightInfoRef.arrivalDateTime).timeZone
              }})
            </div>
            <div class="mg-t-5">
              {{ formatHourMinute(flightInfoRef.durationInMins) }} (
              {{ flightInfoRef.numStops }}-{{ $t("stop") }})
            </div>
          </div>
        </div>
      </div>
      <h5 v-if="fareOptionGroupMapRef" class="text-info">
        {{ $t("some-fare-options-for-you") }}
      </h5>

      <div v-if="fareOptionGroupMapRef">
        <div
          v-for="(fareGroup, fareKey) in fareOptionGroupMapRef"
          :key="fareKey"
          class="flight_ticket_list mg-t-5"
        >
          <div
            v-if="fareGroup && fareGroup.length > 0"
            class="font_weight font-18"
          >
            {{ $t("source") }} : {{ fareKey }}
          </div>
          <div v-if="fareGroup && fareGroup.length > 0">
            <div class="row mg-t-10">
              <div
                v-for="(fareItem, fareIndex) in fareOptionGroupMapRef[fareKey]"
                :key="fareIndex"
                class="col-3"
                @click="changeFare(fareKey + '-' + fareIndex, fareItem)"
              >
                <div
                  :class="{
                    active: fareActiveKeyIndex === fareKey + '-' + fareIndex,
                  }"
                  class="theme_common_box_two img_hover cursor-point"
                >
                  <div id="flight_type_1`" class="flight_ticket_content">
                    <h4 v-if="fareItem.priceClassId" class="text-muted">
                      {{
                        filterFareDescriptions(fareItem.priceClassId)
                          .priceClassName
                      }}
                    </h4>
                    <h4>
                      {{ fareItem.currencyCode }}
                      {{ fareItem.totalPrice.toFixed(2) }}
                    </h4>
                    <h6 v-if="fareItem.fareTypeName || fareItem.fareTypeCode">
                      {{ fareItem.fareTypeName }} ({{ fareItem.fareTypeCode }})
                    </h6>
                    <div>
                      <div v-if="fareItem.expirationDateTime">
                        {{ $t("expire-date") }}:
                        <span class="text-warning">{{
                          dateUtils.formatDateTime(fareItem.expirationDateTime)
                        }}</span>
                      </div>
                      <div
                        v-if="
                          fareItem.cabinTypes && fareItem.cabinTypes.length > 0
                        "
                      >
                        <span
                          >{{ $t("cabin") }}:
                          {{ fareItem.cabinTypes.toString() }}</span
                        >
                      </div>
                      <div class="text-center form-check">
                        <input
                          :checked="
                            fareActiveKeyIndex === fareKey + '-' + fareIndex
                          "
                          class="form-check-input"
                          style="width: 20px; height: 20px"
                          type="radio"
                        />
                      </div>
                      <div
                        v-if="
                          fareItem.priceClassId &&
                          filterFareDescriptions(fareItem.priceClassId)
                            .priceClassName
                        "
                        :data-bs-target="'#collapseExample' + 'C' + fareIndex"
                        aria-controls="collapseExample"
                        aria-expanded="false"
                        class="main_color cursor-point text-end"
                        data-bs-toggle="collapse"
                      >
                        {{ $t("see-fare-details") }}
                        <!-- <i class="fas fa-chevron-down"></i> -->
                      </div>
                    </div>

                    <div
                      v-if="fareItem.priceClassId"
                      :id="'collapseExample' + 'C' + fareIndex"
                      class="collapse"
                    >
                      <ul
                        v-if="
                          filterFareDescriptions(fareItem.priceClassId) &&
                          filterFareDescriptions(fareItem.priceClassId)
                            .priceDescriptions &&
                          filterFareDescriptions(fareItem.priceClassId)
                            .priceDescriptions.length
                        "
                      >
                        <li
                          v-for="(
                            descriptionItem, descriptionIndex
                          ) in filterFareDescriptions(fareItem.priceClassId)
                            .priceDescriptions"
                          :key="descriptionIndex"
                        >
                          <div
                            v-if="descriptionItem.code"
                            style="font-weight: 600"
                          >
                            {{ descriptionItem.code }}
                          </div>
                          <span class="review_count mg-t-5">{{
                            descriptionItem.description
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button class="btn btn_theme mb-2" @click="goToSpecificFlightGroup">
          {{ $t("continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
