<script setup>
import { onMounted, onUnmounted } from "vue";
import dayjs from "dayjs";

const props = defineProps({
  passengers: {},
  flightViewItems: {},
  equipmentType: {},
});
if (props.flightViewItems && props.flightViewItems.length > 0) {
  let dates = [];
  if (props.flightViewItems && props.flightViewItems.length > 0) {
    props.flightViewItems.forEach((item) => {
      dates.push(item.departureDateTime);
      dates.push(item.arrivalDateTime);
    });
  }
  props.passengers.forEach((passenger) => {
    let res = dates.some(
      (item) =>
        new Date(dayjs(item).add(6, "month").format("YYYY-MM-DD")).getTime() >=
        new Date(passenger.docExpireDate).getTime()
    );
    if (res) {
      passenger.warnExpireDate = true;
    }
  });
}
let tooltipTriggerList = [];
let tooltipList = [];
// Init tooltips on content loaded
onMounted(() => {
  // Grab all tooltip containers..
  tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  // ..and init them
  tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
    console.info(tooltipTriggerEl.dataset.bsContainer);
    console.info(tooltipTriggerEl.dataset.bsContainer || "#page-container");
    return new window.bootstrap.Tooltip(tooltipTriggerEl, {
      container: tooltipTriggerEl.dataset.bsContainer || "#page-container",
      animation:
        tooltipTriggerEl.dataset.bsAnimation &&
        tooltipTriggerEl.dataset.bsAnimation.toLowerCase() == "true"
          ? true
          : false,
    });
  });
});

// Dispose tooltips on unMounted
onUnmounted(() => {
  tooltipList.forEach((tooltip) => tooltip.dispose());
});

function filterPassengers(passengers) {
  if (passengers && passengers.length > 0) {
    return passengers.filter(
      (item) => item.ageQualifyingCode === "10" && item.phoneNumber
    );
  }
}
</script>

<template>
  <div class="tour_booking_form_box" v-if="passengers">
    <p class="h4 mb-2">{{ $t("traveler-info") }}</p>

    <div v-if="equipmentType === 'iphone' || equipmentType === 'ipad'">
      <div
        v-for="(passenger, passengerIndex) in filterPassengers(passengers)"
        :key="passengerIndex"
      >
        <div class="booking_form_box_item">
          <span class="h6">{{ $t("nameTitle") }}</span>
          <span class="text-muted"> {{ passenger.nameTitle }}</span>
        </div>
        <div class="booking_form_box_item">
          <span class="h6">{{ $t("surname") }}</span>
          <span class="text-muted"> {{ passenger.surname }}</span>
        </div>
        <div class="booking_form_box_item">
          <span class="h6">{{ $t("given-name") }}</span>
          <span class="text-muted"> {{ passenger.givenName }}</span>
        </div>

        <div v-if="passenger.emailAddress" class="booking_form_box_item">
          <span class="h6">{{ $t("email") }}</span>
          <span class="text-muted"> {{ passenger.emailAddress }}</span>
        </div>
        <div v-if="passenger.phoneNumber" class="booking_form_box_item">
          <span class="h6">{{ $t("phone") }}</span>
          <span class="text-muted">
            +{{ passenger.countryAccessCode }} {{ passenger.phoneNumber }}</span
          >
        </div>
      </div>
      <hr />
    </div>
    <div v-else>
      <div class="row text-center">
        <div class="col-1">
          <p class="h6">{{ $t("nameTitle") }}</p>
        </div>
        <div class="col-1">
          <p class="h6">{{ $t("surname") }}</p>
        </div>
        <div class="col-2">
          <p class="h6">{{ $t("given-name") }}</p>
        </div>
        <div class="col-4">
          <p class="h6">{{ $t("email-address") }}</p>
        </div>
        <div class="col-4">
          <p class="h6">{{ $t("phone-number") }}</p>
        </div>
      </div>
      <div
        class="row"
        v-for="(passenger, passengerIndex) in filterPassengers(passengers)"
        :key="passengerIndex"
      >
        <div class="col-1 text-center">
          <p>
            {{ passenger.nameTitle }}
          </p>
        </div>
        <div class="col-1 text-center">
          <p>
            {{ passenger.surname }}
          </p>
        </div>
        <div class="col-2 text-center">
          <p>
            {{ passenger.givenName }}
          </p>
        </div>
        <div class="col-4 text-center">
          <p>
            <span v-if="passenger.emailAddress">{{
              passenger.emailAddress
            }}</span>
            <br v-else />
          </p>
        </div>
        <div class="col-4 text-center">
          <p>
            <span v-if="passenger.phoneNumber"
              >+{{ passenger.countryAccessCode }}
              {{ passenger.phoneNumber }}</span
            >
            <br v-else />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
