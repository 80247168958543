<script setup>
import { ref, watch } from "vue";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const flightShoppingStore = useFlightShoppingStore();
const props = defineProps({
  flightInfo: {},
  flightInfoIndex: {},
  previousOptionPrice: {},
});

const { t, locale } = useI18n(); // 解构调用函数
let router = useRouter();
let route = useRoute();

const emits = defineEmits();

const flightInfoRef = ref(null);
watch(
  () => props.flightInfo,
  (newValue) => {
    flightInfoRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//当地时间
function formatLocalTime(dateTime) {
  let dateTimeStr = dateTime;
  if (dateTimeStr) {
    let timeZone = "";
    let timeSplit = dateTimeStr.split("T")[1];
    if (dateTimeStr.indexOf("Z") >= 0) {
      timeZone = "+00:00";
    } else {
      timeZone = timeSplit.substring(12);
    }

    let time = timeSplit.substring(0, 5);

    return {
      time: time,
      timeZone: timeZone,
    };
  }
}

function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
}

function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

function getAirportCityName(code) {
  if (
    !flightShoppingStore.airportCityMap ||
    !flightShoppingStore.airportCityMap[code]
  )
    return code;
  //可以通过语言获取不同类型的名字
  return flightShoppingStore.airportCityMap[code].cityName;
}

function getAircraftName(aircraftCode) {
  let aircraftNameKey = "aircraft." + aircraftCode;
  return aircraftNameKey;
}

// 将分钟数量转换为小时和分钟字符串
function formatHourMinute(minutes) {
  let time = {
    hour:
      Math.floor(minutes / 60) < 10 && Math.floor(minutes / 60) > 0
        ? "0" + Math.floor(minutes / 60)
        : Math.floor(minutes / 60),
    minute:
      minutes % 60 < 10 && minutes % 60 > 0
        ? "0" + (minutes % 60)
        : minutes % 60,
  };
  return time.hour + "h " + time.minute + "m";
}

//价格千分位表示
function formatPriceShow(currentTotalPrice, previousOptionPrice) {
  let priceDiff = parseInt(currentTotalPrice - previousOptionPrice).toString();
  return priceDiff
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ",") + prev;
    });
}

function getPolicyModelKey(modelIndex) {
  return "C" + modelIndex;
}

function select(
  originDestinationKey,
  supplierCodes,
  bestTotalPrice,
  flightInfo
) {
  //flightInfo用于记录在fare options部分显示所选行程信息
  emits(
    "viewFareOptions",
    originDestinationKey,
    supplierCodes,
    bestTotalPrice,
    flightInfo
  );
}

function TimeDecrement(timeOne, timeTwo) {
  timeOne = new Date(timeOne);
  timeTwo = new Date(timeTwo);
  let time = null;
  if (timeTwo > timeOne) {
    time = parseInt(timeTwo - timeOne) / 1000 / 60;
  } else {
    time = parseInt(timeOne - timeTwo) / 1000 / 60;
  }
  return formatHourMinute(time);
}

//美式英语的时间格式
function formatEnglishDate(date) {
  date = new Date(date);
  let monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec",
  ];
  let suffix = ["st", "nd", "rd", "th"];

  let year = date.getFullYear(); //年
  let month = monthArr[date.getMonth()]; //月
  let ddate = date.getDate(); //日
  let time = date.toLocaleTimeString("chinese", { hour12: false }).slice(0, 5);

  if (ddate % 10 < 1 || ddate % 10 > 3) {
    ddate = ddate + suffix[3];
  } else if (ddate % 10 == 1) {
    ddate = ddate + suffix[0];
  } else if (ddate % 10 == 2) {
    ddate = ddate + suffix[1];
  } else {
    ddate = ddate + suffix[2];
  }
  return { time: time, ddate: ddate, month: month, year: year };
}
</script>

<template>
  <div class="flight_list_item" v-if="flightInfoRef">
    <div class="row">
      <div class="col-6">
        <div class="orgin-des">
          <div class="font_weight">
            {{ formatLocalTime(flightInfoRef.departureDateTime).time }} ({{
              formatLocalTime(flightInfoRef.departureDateTime).timeZone
            }}) - {{ formatLocalTime(flightInfoRef.arrivalDateTime).time }} ({{
              formatLocalTime(flightInfoRef.arrivalDateTime).timeZone
            }})
          </div>

          <div class="airline-info flex align-content-center">
            <img
              :src="
                getAirlinePicture(
                  flightInfoRef.flightSegments[0].marketingAirlineCode
                )
              "
              alt="img"
              class="airline-logo"
            />
            <div class="markting-airline">
              <span
                v-for="(segment, segmentIndex) in flightInfoRef.flightSegments"
                :key="segmentIndex"
              >
                {{ $t(getAirlineName(segment.marketingAirlineCode)) }}
                <span
                  v-if="segmentIndex < flightInfoRef.flightSegments.length - 1"
                  class="mg-l-5 mg-r-8"
                  >-</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="price-info">
          <h6 class="mg-t-5">
            {{ formatHourMinute(flightInfoRef.durationInMins) }} (
            {{ flightInfoRef.numStops }}-{{ $t("stop") }})
          </h6>
          <div class="location font_weight text-muted">
            {{ flightInfoRef.departureAirportCode }} -
            {{ flightInfoRef.arrivalAirportCode }}
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="select-panel">
          <div class="main_color font_weight price">
            <span v-if="!previousOptionPrice" class="font-14 text-muted mg-r-8"
              ><i class="fa-solid fa-arrow-up"></i
            ></span>
            <span v-else class="mg-r-8">+</span>
            {{ flightInfoRef.currencyCode }}
            {{
              formatPriceShow(flightInfoRef.bestTotalPrice, previousOptionPrice)
            }}
          </div>

          <button
            class="btn btn-outline-primary"
            @click="
              select(
                flightInfoRef.originDestinationKey,
                flightInfoRef.supplierCodes,
                flightInfoRef.bestTotalPrice,
                flightInfoRef
              )
            "
          >
            {{ $t("select") }}
          </button>
          <div
            :data-bs-target="
              '#collapseExample' + getPolicyModelKey(flightInfoIndex)
            "
            aria-controls="collapseExample"
            aria-expanded="false"
            class="text-center cursor-point"
            data-bs-toggle="collapse"
          >
            <div class="font-14">
              {{ $t("view-segments") }}<i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :id="'collapseExample' + getPolicyModelKey(flightInfoIndex)"
      class="p-l-3 mg-up-10 mg-b-10 collapse"
    >
      <hr />
      <div
        v-for="(segment, segIndex) in flightInfoRef.flightSegments"
        :key="segIndex"
        class="flight_segments mg-up-20"
      >
        <div class="row">
          <div class="col-12">
            <div class="airline-details">
              <div class="img">
                <img
                  :src="getAirlinePicture(segment.marketingAirlineCode)"
                  alt="img"
                />
              </div>
              <span class="airlineName fw-500"
                >{{ $t(getAirlineName(segment.marketingAirlineCode)) }}
                {{ segment.flightNumber }} &nbsp;</span
              >
              <span v-if="segment.equipmentCode" class="flightNumber">{{
                $t(getAircraftName(segment.equipmentCode))
              }}</span>
            </div>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-9">
            <div class="row">
              <div class="col-5">
                <div>
                  <span class="font_weight">
                    {{ getAirportCityName(segment.departureAirportCode) }}</span
                  >
                  <span class="text-muted mg-l-8">
                    {{ formatLocalTime(segment.departureDateTime).time }} ({{
                      formatLocalTime(segment.departureDateTime).timeZone
                    }})</span
                  >
                </div>
                <div>
                  {{ $t(getAirportName(segment.departureAirportCode)) }}
                </div>
                <div class="font-14 text-muted">
                  {{ formatEnglishDate(segment.departureDateTime).ddate }}
                  {{ formatEnglishDate(segment.departureDateTime).month }}
                  {{ formatEnglishDate(segment.departureDateTime).year }}
                </div>
              </div>
              <div class="col-2">
                <div class="flight_segment_date_info">
                  <div class="arrow_right"></div>
                  <div class="font-14">
                    {{
                      TimeDecrement(
                        segment.arrivalDateTime,
                        segment.departureDateTime
                      )
                    }}
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div>
                  <span class="font_weight">
                    {{ getAirportCityName(segment.arrivalAirportCode) }}</span
                  >
                  <span class="text-muted mg-l-8">
                    {{ formatLocalTime(segment.arrivalDateTime).time }} ({{
                      formatLocalTime(segment.arrivalDateTime).timeZone
                    }})
                  </span>
                </div>
                <div>{{ $t(getAirportName(segment.arrivalAirportCode)) }}</div>
                <div class="font-14 text-muted">
                  {{ formatEnglishDate(segment.arrivalDateTime).ddate }}
                  {{ formatEnglishDate(segment.arrivalDateTime).month }}
                  {{ formatEnglishDate(segment.arrivalDateTime).year }}
                </div>
              </div>
            </div>
            <div class="basis-code mg-t-10">
              <div class="flex">
                <div class="mg-r-8">{{ $t("operating-airline") }}:</div>
                <div class="text-muted text-start width-200">
                  {{ $t(getAirlineName(segment.operatingAirlineCode)) }}
                </div>
              </div>

              <div class="flex">
                <div class="mg-r-8">{{ $t("baggage-allowance") }}:</div>
                <div
                  class="text-muted text-start width-200"
                  v-if="!segment.baggageAllowance"
                >
                  {{ $t("non-baggage") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
