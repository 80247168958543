export default {
  cancel: "取消",
  "print-voucher": "打印凭证",
  "available-credit": "可用信用额度",
  "under-17": "17岁以下",
  "please-enter-a-valid-firstname": "请输入一个有效的名字",
  "select-continue": "选择并继续",
  "how-many-rooms-need": "您需要多少个房间？",
  "order-type": "订单类型",
  "service-name": "服务名称",
  checkin: "到达",
  "hotel-price": "酒店价格",
  "child-less-than": "12 岁以下",
  "show-more-room-rate": "显示更多房价",
  "back-to-dinning": "返回餐饮",
  "who-is-traveling": "谁在旅行？",
  "applicant-personal-details": "申请人#{index}个人详细信息",
  "cancellation-policy-nights-caution":
    "在 {beginDate} 之前取消您的预订，您将获得全额退款。之后，我们将向您收取 {nmbrOfUnit} 晚的住宿费用以及税费。时间以酒店当地时间为准。",
  "special-requests-optional": "特殊要求（可选）",
  excellent: "极好的",
  "number-of-stops": "停靠站数量",
  "confirm-your-choices": "确认您的订单",
  pool: "水池",
  "how-many-guests-each-room": "每间客房将容纳多少位客人？",
  condo: "公寓",
  "passport-information": "护照信息",
  economy: "经济舱",
  "our-partners": "我们的伙伴",
  "first-name": "名",
  "available-dates": "可用日期",
  "search-by-destination": "按目的地搜索",
  nationality: "国籍",
  "error-500-message": "很抱歉，我们的服务器遇到了内部错误",
  unticketed: "无票",
  "flight-list": "航班列表",
  "meal-plans-available": "提供餐饮",
  "passenger-info": "乘客信息",
  aboard: "船上",
  adult: "成人",
  "next-passenger": "下一位乘客",
  "taxes-total": "税金总计",
  "bag-allow": "允许携带行李",
  amenities: "便利设施",
  "error-503-message": "很抱歉，我们的服务目前不可用",
  flight: "机票",
  "pick-return-location": "接送地点",
  Visa: "签证",
  "flight-price": "航班价格",
  "property-type": "财产种类",
  "info-source": "信息来源：{supplierCode}",
  "number-of-entries": "入境次数",
  search: "搜索",
  "traveler-name": "旅客姓名",
  "hotel-location": "酒店位置",
  "type-of-vehicle": "什么类型的车辆？",
  hotel: "酒店",
  "expect-to-wait": "乘客等待时间",
  "departure-to-return": "出发返回",
  "hotel-name": "酒店名称",
  close: "关闭",
  "price-summary": "价格汇总",
  "flight-number": "航班号",
  "number-of-nights": "入住晚数",
  "confirm-booking": "确认预订",
  address: "地址",
  "car-rental": "汽车出租",
  business: "商业",
  "price-per-applicant": "每位申请人的价格",
  "operation-uppercase": "手术",
  "select-weight-of-baggage": "选择您托运行李的重量",
  "view-terms": "查看条款",
  "apart-hotel": "公寓式酒店",
  "any-uppercase": "任何",
  "guest-rating": "宾客评价",
  register: "登记",
  "agency-portal": "机构门户",
  rooms: "客房",
  "your-trip-details": "您的旅行详情",
  "view-your-booking": "查看您的订单",
  "passport-number": "护照号",
  "choose-your-category-location-room": "选择您的{categoryLocation}房间",
  "reactive-account": "反应账户",
  "finding-driver": "寻找您的司机",
  chinese: "中文",
  "charge-type": "收费类型",
  "check-out-your-choices": "查看您的订单",
  contact: "联系",
  "your-personal-details": "你个人详细资料",
  airlines: "航空公司",
  radius: "半径",
  info: "信息",
  "please-enter-your-username": "请输入您的用户名",
  "apply-now-for-document": "立即申请{toCountryName}旅行证件",
  journey: "旅程",
  "passenger-room-class": "旅客、房间、舱位",
  "room-price-total": "房价总计",
  "trip-total": "行程总计",
  "car-transfer": "专车接送",
  "add-check-in-baggage": "添加托运行李",
  "room-index": "房间 - 索引",
  "max-stay": "最多停留时间",
  "fare-family": "票价系列",
  "agree-to-terms-conditions": "我同意条款和条件状况",
  "all-bookings": "所有预订",
  "infant-price": "婴儿价格",
  "booking-in-process": "预订中",
  "price-info": "价格信息",
  "please-enter-a-valid-username": "请输入有效的用户名",
  "night-number-high-to-low": "住宿晚数从高到低",
  bookings: "预订",
  "child-index-age": "孩子 {childIndex} 年龄",
  "num-hotel": "  找到 {length} 个酒店",
  "radius-search": "半径搜索",
  "driver-details": "司机详细信息",
  child: "孩子",
  "required-for-travel": "旅行所需",
  unconfirmed: "未确认",
  "please-contact-email": "请联系邮箱",
  "please-enter-a-valid-password": "请输入有效密码",
  "rooms-can-accommodate": "客房最多可容纳 4 位客人",
  "rail-pass-review": "铁路通票审查",
  origin: "起源",
  back: "返回",
  "night-number": "{number} 晚",
  "continue-to-payment": "继续付款",
  title: "标题",
  "select-your-seat": "选择您的座位",
  "please-enter-a-valid-lastname": "请输入有效的姓氏",
  remove: "删除",
  "arrival-time": "到达时间",
  "sign-in-now": "立即登录",
  "go-beyond-your-imagination": "超越你的想象",
  company: "公司",
  "hotel-item": "酒店项目",
  "sign-up": "注册",
  "by-credit-card": "用信用卡",
  "rail-ticket-review": "火车票评论",
  "call-us": "如需任何帮助请致电我们",
  "expire-date": "到期日期",
  "check-in-date": "入住日期",
  "sign-in-your-account": "登录您的帐户",
  "lower-available-rate": "较低价格",
  "journey-date": "旅程日期",
  "see-details": "查看具体信息",
  "guests-uppercase": "嘉宾",
  room: "房间",
  "cruise-home": "游轮首页",
  home: "首页",
  "flight-class": "飞行等级",
  "more-photos": "更多照片",
  "advance-question": "提前提问",
  "i-agree": "我同意",
  "insurance-home": "保险首页",
  "num-night-stay": "{num} 晚住宿",
  age: "年龄",
  "two-less-than": "2 - 12 岁以下",
  "flight-date": "航班日期",
  select: "选择",
  configurations: "配置",
  discount: "折扣",
  "log-out": "退出登录",
  "fare-rules": "票价规则",
  "total-amount": "总金额",
  "birth-date": "出生日期",
  "error-401-message": "很抱歉，您无权访问此页面",
  supplier: "供应商",
  "for-all-applicants": "对于所有申请人",
  "per-entry": "每个条目",
  "reactive-your-account": "回复您的帐户",
  "product-name": "产品名称",
  "booking-id": "预订编号",
  order: "命令",
  "your-application": "你的申请",
  passengers: "乘客",
  "baggage-option": "行李选项",
  "rate-comments": "评价评论",
  "drivers-can-wait": "司机最多可以等待",
  "each-passenger-can-select": "每位乘客可以选择一个选项。",
  index: "指数",
  "return-location": "返回地点",
  "residency-information": "居住信息",
  "frequent-answer-and-question": "常见的回答和问题",
  "ticketing-success": "票务成功",
  "cruise-itinerary": "邮轮行程",
  cancelled: "取消",
  "search-orders": "搜索订单",
  "airport-shuttle-included": "包括机场班车",
  "room-name-index": "房间 {index} { roomTypeName }",
  status: "地位",
  "today-price": "今日价格",
  "get-latest-news-offers": "获取最新消息和优惠",
  "price-starts-from": "{currency} {amount}价格从",
  "price-range": "价格范围",
  extras: "附加功能",
  "my-profile": "我的简历",
  "hostel-backpacker": "旅馆/背包客旅馆",
  holiday: "假期",
  "back-to-results": "返回结果",
  "privacy-policy": "隐私政策",
  "night-number-low-to-high": "住宿晚数从低到高",
  "common-area": "公共区域",
  "please-enter-a-valid-phone-number": "请输入一个有效的电话号码",
  surname: "姓",
  "your-booking-detail": "您的预订详细信息已发送至：{ emailAddress }",
  "room-uppercase": "房间",
  "stopover-in": "在#{到达机场名称}中途停留#{arrivalDateTime}",
  "bed-type": "床型",
  "cancellation-policy-refundable-caution":
    "在 {beginDate} 之前取消您的预订，您将获得全额退款。之后，我们将向您收取 {currency} {amount}。时间以酒店当地时间为准。",
  "sub-total": "小计",
  "select-the-child-age": "请选择孩子的年龄！",
  "base-fare": "基本做事",
  "basic-rate": "基本费率",
  "guest-total": "客人 { cabPriceIndex } 总计",
  "create-by": "创建者",
  "contact-with-us": "与我们联系",
  "num-passenger": "{passengersNum} 乘客",
  "no-seat-segment": "该航段无法选择座位",
  "copy-right": "版权所有 © 2023 保留所有权利",
  "processing-time-fee": "处理时间和费用",
  "travel-document-book": "旅行证件簿",
  "protections-extras": "附加功能",
  "subscribe-our-newsletter": "订阅我们的新闻",
  "total-uppercase": "全部价格",
  "enter-the-email": "请输入与您的帐户相结合的电子邮件",
  "enter-valid-credential": "请输入有效的凭据",
  "find-available-cruises": "查找可用的游轮",
  "travel-document-option": "旅行证件选项",
  "applying-for": "正在申请",
  fees: "费用",
  "select-your-type-of-inside": "选择您的房间类型",
  traveler: "游客",
  "double-room": "双人间",
  "round-trip": "往返",
  "price-detail": "价格详情",
  "ports-of-call": "停靠港",
  "delivered-at": "接送时间",
  "cabin-price": "舱位价格",
  "special-requests": "特别要求",
  "pax-num": "和平号",
  "discover-your-ideal": "与我们一起探索您的理想体验",
  "stateroom-amenities": "客舱设施",
  "submit-ticket-order": "提交门票订单",
  stopover: "中途停留",
  "expiry-date": "到期日",
  "pax-type": "乘客类型",
  "after-issued": "办理后",
  stock: "库存",
  "my-bookings": "我的预订",
  all: "全部",
  "pickup-and-return": "往返地点",
  "room-name": "房间名称",
  "show-less": "显示较少",
  "price-low-to-high": "价格：从低到高",
  "cabin-class": "舱位等级",
  "meet-your-driver": " 您的预订确认后，我们将发送与司机会面的分步说明。",
  spa: "温泉",
  "select-your-room": "选择您的房间",
  "add-another-person": "添加另一个人",
  "fill-following-details": "请填写以下详细信息以创建新帐户。",
  "logged-in-to-stay-in-touch": "登录以保持联系",
  "itinerary-review": "行程预览",
  "children-ages": "0至17岁儿童",
  "living-country": "居住的国家",
  "sort-by": "排序方式",
  "activities-list": "活动清单",
  "already-have-an-account": "已经有帐户？",
  position: "位置",
  basic: "基本的",
  "star-rating": "星级",
  "breakfast-included": "包含早餐",
  "same-return-location": "回程地点相同",
  "cabin-type": "舱型",
  exterior: "外部的",
  policies: "政策",
  "passenger-information": "旅客信息",
  "booking-summary": "预订摘要",
  "register-your-account": "注册您的帐户",
  "search-for-an-activities": "搜索活动",
  "where-am-i-from": "我从哪里来？",
  total: "全部的",
  "error-400-message": "您发出了错误的请求，或者请求已损坏并且服务器无法理解它",
  "guest-name": "客人姓名",
  "add-another-flight": "添加另一个航班",
  "are-you-sure-ticketing": "  您确定要出票#{ id } {idContext }吗？",
  reserve: "预订",
  "you-need-visa-travel":
    "如果您持有 {fromCountryName} 的护照，则需要签证才能前往 {toCountryName}",
  "check-in-to-check-out": "入住到退房",
  "remove-person": "删除人员",
  included: "已包含",
  "stay-in-touch": "保持联系",
  "return-time": "返回时间",
  "i-live-in": "我住在",
  or: "或者",
  "price-uppercase": "价格",
  "children-uppercase": "孩子们",
  "booking-success": "预订成功！",
  "arrival-date": "到达日期",
  "extra-service": "额外服务",
  "searching-result": "搜索结果",
  "order-list": "订单",
  any: "任何",
  "contact-information": "联系信息",
  "edit-seat": "编辑座位",
  "booking-preview": "订单预览",
  application: "应用",
  "Avg-person": "平均/人",
  activities: "活动",
  "hotel-detail": "酒店详情",
  "confirmation-number": "确认号码：",
  properties: "条",
  "phone-number": "电话号码",
  "export-to-sabre": "导出至 Sabre",
  "info-uppercase": "信息",
  "name-title": "名称",
  prev: "上一篇",
  "sailings-uppercase": "航行",
  "all-inclusive": "全包",
  "remember-me": "记住账号",
  ship: "船",
  "rail-home": "铁路之家",
  "select-fares": "选择票价",
  "reset-password": "重设密码",
  "hotel-home": "酒店首页",
  "send-mail": "发送邮件",
  "very-good-4.0+": "非常好4.0+",
  "send-message": "发信息",
  "create-date": "创建日期",
  "country-of-residence": "居住国家*",
  PNR: "订座记录",
  english: "英语",
  "choose-your-room": "选择您的房间",
  "view-dates": "{number} 个日期查看 ",
  "go-back": "返回",
  requester: "请求者",
  sleeps: "睡觉",
  "one-way": "单程",
  night: "晚",
  "pick-your-add-ons": "选择您的附加组件",
  "please-enter-a-valid-email": "请输入有效的电子邮件地址",
  "booking-confirm": "预订确认",
  "rate-description": "费率说明",
  "accessorial-service": "配套服务",
  "welcome-are-you-going": "你要去哪里",
  "print-invoice": "打印发票",
  stops: "停靠点",
  "travel-document": "旅行证件",
  "guest-rating-our-picks": "宾客评分 + 我们的精选",
  "rail-search-list": "铁路搜索列表",
  "air-item": "航空项目",
  "includes-taxes-fees": "包括税费",
  cabin: "舱",
  "passenger-class": "乘客，舱位",
  "order-status": "订单状态",
  "pick-up": "出发地",
  "please-enter-a-username": "请填入一个用户名",
  "select-segments": "选择细分",
  "wonderful-4.5+": "精彩4.5+",
  "taxes-fees-and-port-expenses": "税费、费用和港口费用",
  "lessons-hours": "10 节课 · 3 小时",
  "total-include-amount": "总计 {currency} {amount}",
  "departure-port": "出发港",
  "quote-type": "报价类型",
  currency: "货币",
  "ticketed-uppercase": "已出票",
  "booking-status": "预订状态",
  email: "电子邮件",
  "last-name": "姓",
  departs: "出发",
  "cancellation-policy": "取消政策",
  protections: "保护措施",
  "pick-up-location": "接人的地方",
  "get-order-prices": "获取订单价格",
  "order-id": "订单编号",
  "adult-price": "成人价",
  "holiday-customise": "假期定制",
  people: "人",
  "forgot-password": "忘记密码",
  "total-due": "应付总额",
  confirm: "确认",
  "explore-this-itinerary": "探索此行程",
  "traveler-info": "旅客信息",
  "learn-html5": "通过 10 个简单易懂的步骤学习 HTML5",
  marketing: "营销",
  "booking-date-range": "预订日期范围",
  "package-flight-hotel": "套票(机票+酒店)",
  "good-3.5+": "好3.5+",
  "given-name": "名",
  to: "到",
  "ticketing-confirmed": "您的票务 #{ TicketId } 已确认",
  "visiting-uppercase": "参观",
  "desk-name": "办公桌名称",
  "explore-our-hot-deals": "探索我们的热门优惠",
  insurance: "保险",
  "expiry-time": "到期时间",
  "car-rental-home": "租车首页",
  "lunch-included": "含午餐",
  "register-now": "现在注册",
  "return-date": "归期",
  "report-it": "举报",
  "less-than-two": "不到2年",
  "next-flight": "下一航班",
  "confirmed-uppercase": "确认的",
  luggage: "行李",
  "price-high-to-low": "价格：从高到低",
  continue: "继续",
  "book-now": "现在预订",
  details: "详情",
  "read-and-accept-conditions": "我已阅读并接受所有条款和条件",
  "total-price-range": "总价格范围",
  "general-question": "一般问题",
  "arr-time": "到达。时间",
  "back-to-home": "回到家",
  "see-remark": "查看备注",
  "price-details": "价格详情",
  "send-links": "发送链接",
  "taxes-and-fees": "税费",
  "your-vehicle": "接送您的车辆",
  name: "姓名",
  "room-prices-details": "房价详情",
  "check-out": "返回",
  "welcome-please-login": "欢迎您，请登录。",
  "valid-for": "有效期为",
  "dept-time": "部门时间",
  "no-room-type-name": "无房型名称",
  support: "支持",
  "continue-to-confirm": "继续确认",
  next: "下一个",
  "car-rental-list": "租车列表",
  submit: "提交",
  "booking-confirmed": "您的订单 #{ bookingId } 已确认",
  adults: "成年人",
  "please-enter-your-password": "请输入您的密码",
  destination: "目的地",
  "num-flight": "  找到 {length} 个航班",
  "get-price": "获取价格",
  "multi-city": "多城市",
  "passenger-type": "旅客类型",
  "select-status": "选择状态",
  "measurement-infos": "测量信息",
  "cancellation-policy-nonrefundable-caution":
    "在 {beginDate} 之前取消您的预订，您将获得全额退款。之后，您将被收取全部费用。时间以酒店当地时间为准。",
  "get-comfy-during-journey":
    " 旅途中尽享舒适！无论您想要安静的飞行还是只是想增加一点腿部空间，选择完全取决于您！",
  "your-choices": "您的选择",
  refundable: "可退款",
  "check-email-info":
    "您可以查看您的电子邮件以获取更多详细信息。我们已向您发送了一封包含详细信息的邮件。",
  "including-taxes": "含税",
  checkout: "查看",
  "price-total": "{ currency } {price} 总计",
  "provide-your-account":
    "请提供您帐户的电子邮件或用户名，我们将向您发送您的密码。",
  overview: "概述",
  "would-you-like-to-us-know": "您愿意让我们知道吗？",
  "email-address": "电子邮件地址",
  previous: "以前的",
  "traveling-for": "旅行的目的",
  "receipt-status": "收据状态",
  "view-fare-rules": "查看票价规则",
  start: "开始",
  "taxes-fees-port-expenses": "税费、费用和港口费用",
  "adults-uppercase": "成年人",
  "booking-amount": "预订金额",
  "hotel-search": "酒店搜索",
  "see-fare-details": " 查看票价详情",
  "family-name": "姓",
  "passport-expiry-date": "护照有效期为出发或返回后 6 个月内",
  "get-free-quote": "获取免费报价",
  hotels: "酒店",
  nights: "夜晚",
  "view-details": "查看详情",
  "room-quantity": "房间数量",
  "vans-trucks": "货车和卡车",
  "num-trip-advisor": "{num}/5 猫途鹰",
  taxes: "税收",
  itinerary: "行程",
  "we-choose": "我们选择",
  infant: "婴儿",
  type: "类型",
  "select-your-seats": " 选择您的座位",
  "select-passengers": "选择乘客",
  "dinner-included": "含晚餐",
  logout: "登出",
  "ticket-number": "票号",
  children: "孩子们",
  "sure-export-booking": "您确定要导出预订#{ bookingId }",
  "standard-days": "标准 - {num} 天",
  price: "价格",
  connection: "中转站",
  "all-photos": "所有照片",
  dashboard: "仪表板",
  "taxes-fees": "税费",
  "error-404-message": "很抱歉，找不到您要查找的页面",
  "expire-time-min-s": "{ 分钟 } 分 {秒} 秒后过期",
  "created-date-range": "创建日期范围",
  "please-enter-hotel-location": "请输入酒店位置",
  "where-am-i-going": "我要去哪里",
  "more-details": "更多细节",
  "payment-method": "付款方式",
  "child-price": "儿童价",
  "confirmation-number-with-supplier": "确认号({supplierName})",
  "pickup-and-return-time": "接载地点及时间",
  "minimum-driving-age-years": "最低驾驶年龄：{minimumDriverAge}岁",
  "cruise-dates": "邮轮日期",
  doors: "门",
  "your-passport-information": "您的护照信息",
  "status-uppercase": "地位",
  "pick-up-time": "接载时间",
  "bookings-list": "预订清单",
  "go-to-home": "返回首页",
  reset: "重置",
  "select-your-room-category": "选择您的舱型",
  "departure-date": "出发日期",
  "remove-room": "移除房间",
  "contact-us": "联系我们",
  "sign-in": "登录",
  "hotel-amenities": "酒店设施",
  "travel-document-home": "签证首页",
  "check-out-date": "离开日期",
  "inbox-menu": "收件箱菜单",
  "leave-us-a-message": "给我们留言",
  "filter-by-price": "按价格过滤",
  tourism: "旅游",
  "room-types-rate-comments": "评价评论 - { roomTypes } - {roomIndex}",
  confirmed: "确认的",
  "airline-RLOC": "航空公司 RLOC",
  vehicle: "车辆",
  "ferries-and-buses": "渡轮和巴士",
  "bookings-found": "找到预订",
  "add-ons-pick": "附加组件选择",
  "package-home": "套餐首页",
  "fare-basic": "基本票价",
  "invoice-number": "发票号码：",
  "number-of-connections": "中转站数",
  "must-agree-service-terms": "您必须同意服务条款！",
  "fare-basis": "票价基础",
  "frequent-flyer-airline": "飞行常客航空公司",
  "see-all-properties": "返回搜索结果",
  "create-uppercase": "创造",
  "need-help": "需要帮助吗？",
  "cancel-booking": "取消预订",
  "non-refundable": "不退还",
  date: "日期",
  booking: "预订",
  "reset-your-password": "重置你的密码",
  "seat-unavailable": "没有座位",
  "extra-service-total": "额外服务总计",
  "departing-from": "出发地",
  "add-another-room": "添加另一个房间",
  "regular-question": "常规问题",
  "holiday-home": "渡假村",
  "night-stay": "过夜",
  "name-submitted-successfully": "{surname}/{givenName}，您的订单已成功提交！",
  "taxes-include-total": "税费（包含在总额中）",
  sales: "销售量",
  "baggage-allowance": "行李限额",
  "check-in": "报到",
  "licenses-fees": "许可证和费用",
  "please-confirm the password": "请确认密码",
  "flexibility your own flying experience":
    "我们将为您带来全新的预订体验，让您拥有一切灵活定制属于您自己的飞行体验",
  "trip-advisor": "旅行顾问",
  discounts: "折扣",
  create: "创造",
  from: "从",
  "follow-us": "跟着我们",
  "for-room": "适合 { roomNum } 房间",
  "infant-less-than": "不到2年",
  package: "机票套餐",
  "edit-meal": "编辑膳食",
  "include-in-total-amount": "包含在总金额中",
  "multi-way": "多程",
  minutes: "分钟",
  rail: "轨",
  "search-for-the-best-rooms-available":
    "我们将搜索所选类别中可用的最佳房间。客房最多可容纳 4 位客人",
  tax: "税",
  done: "完成",
  travelers: "旅行者",
  "cabin-number": "舱位号码",
  "trip-total-price": "行程总价",
  cruise: "游轮",
  "show-details": "显示详细资料",
  departure: "离开",
  "who-we": "我们是谁",
  "apply-now": "现在申请",
  "cruise-search-results": "邮轮搜索结果",
  "dont-have-a-account": "没有账户",
  "register-account": "注册账户",
  error: "错误",
  "sure-cancel-booking": "您确定要取消预订吗#{ bookingId }",
  "please-provide-a-password": "请提供密码",
  "protection-plans": "保护计划",
  "select-date": "选择日期",
  "please-enter-your-departure-point": "请输入您的出发地点",
  "load-more": "加载更多",
  "available-extras": "可用的附加功能",
  "edit-baggage": "编辑行李",
  "frequent-flyer-airline-number": "飞行常客航空公司号码",
  "see-fare-basis": "查看票价基础",
  amount: "数量",
  "departure-time": "出发时间",
  "go-back-to-dashboard": "返回仪表板",
  "room-price": "房价",
  "passport-requirements": "护照要求",
  subscribe: "订阅",
  "search-result": "搜索结果",
  "start-now": "现在开始",
  "error-403-message": "很抱歉，您没有访问此页面的权限",
  "from-to": "从{from}到{to}",
  "show-more": "展示更多",
  "created-date": "创建日期",
  recommended: "推荐",
  meal: "一顿饭",
  "mail-us": "邮寄给我们的支持团队",
  "please-enter-your-arrival-point": "请输入您的到达地点",
  "third-party-liability": "第三方责任",
  reselect: "重新选择",
  "flight-home": "机票首页",
  "going-to": "目的地",
  "processing-speed": "处理速度（尚未选择）",
  "prev-passenger": "上一位乘客",
  front: "正面",
  flights: "机票",
  in: "在",
  "cruise-package-code": "游轮套餐代码",
  deposit: "押金",
  "deposit-in": "押金( {key} )",
  "trip-total-in": "总价格( {key} )",
  "guest-info": "访客信息",

  "side-overlay-content": "侧面叠加内容..",
  carts: "购物车",
  reports: "报表",
  "edit-profile": "编辑资料",
  "current-language": "当前语言",
  "all-languages": "所有语言",
  "filter-by": "过滤条件",
  "basic-search": "基础搜索",
  "passengers-summary": "{passengersNum} 乘客 ,{cabinName} ",
  "passengers-room-summary":
    "{passengersNum} 乘客 ,{roomCount} 房间 ,{cabinName} ",
  "hotel-shopping": "酒店预订",
  "travel-room-num": "{travelersNumer} 乘客 , {roomCount}  房间",
  "who-checking": "登记人员",
  "cancellation-policies": "退款政策",
  "daily-prices": "价格详情",
  "cruise-shopping": "游轮预定",
  "booking-progress": "预定",
  guests: "乘客",
  "select-dining-preference": "选择您的用餐偏好",
  "cruise-dinning":
    "2019年2月，名人峰会参加了名人船队革命。餐厅和休息室不仅仅是餐厅和消磨时间的地方。贵宾室变成了远离家乡的家和客人的私人避难所。名人峰会不再只是一艘船，而是一种更好的体验世界的方式。",
  location: "位置",
  carRental: "租车",
  "car-rental-search": "租车搜索",
  seats: "座",
  return: "返回地",
  "car-transfer-home": "车接首页",
  "search-car-transfer": "车接搜索",
  infants: "婴儿",
  "adult-child-infant": "{adultNum}  成人, {childNum} 小孩 , {infantNum}  婴儿",
  "add-another": "增加",
  "your-journey": "您的旅程",
  "travel-document-apply": "旅游证件申请",
  validity: "有效期",
  review: "检查",
  documents: "证件",
  applicant: "申请人",
  gender: "性别",
  "personal-details": "个人信息",
  "package-search": "套餐搜索",
  "hotel-result": "酒店结果",
  "terms-and-condition": "条款及细则",

  expired: "过期",

  1023: "过滤",
  "num-flights-found": "{flightViewItemsShowLength} 条机票搜索结果",

  passport: "护照",
  phone: "手机",

  sort: "排序",
  "search-by-departure": "按出发地点搜索",

  "taxes-fees-included": "含税及费用",

  "your-choice": "您的选择",
  "view-on-map": "在地图上查看",
  "where-are-you-going": "您要去哪里?",

  "enter-a-email-address": "输入邮箱地址",
  "enter-your-username": "输入用户名",
  "enter-your-password": "输入密码",
  "enter-your-first-name": "输入名字",
  "enter-your-last-name": "输入姓氏",
  "enter-your-email-address": "输入邮箱地址",
  "enter-your-phone-number": "输入电话",
  "please-enter-price-range": "请输入正确的价格范围",
};
