<script setup>
import { computed, onMounted, reactive } from "vue";
import { useProfileStore } from "@/stores/profile";
import { useTemplateStore } from "@/stores/template";
import useVuelidate from "@vuelidate/core";
import { email, integer, minLength, required } from "@vuelidate/validators";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

const templateStore = useTemplateStore();
let props = defineProps({
  profileInfo: {},
});

let user = reactive(JSON.parse(sessionStorage.getItem("user")));

const profileStore = useProfileStore();

async function modifyProfile() {
  const result = await v$.value.$validate();
  validatePhoneNumber();
  if (!result) {
    // notify user form is invalid
    return;
  }
  templateStore.pageLoader({ mode: "on" });
  await profileStore.modifyUserProfile(profileFormModel);
  if (profileStore.profileModifyRS) {
    profileStore.loadUserProfile(user.username);
    templateStore.pageLoader({ mode: "off" });
  }
}

let profileFormModel = reactive(JSON.parse(JSON.stringify(props.profileInfo)));
const rules = computed(() => {
  return {
    username: {
      required,
      minLength: minLength(3),
    },
    // password: {
    //   required,
    //   minLength: minLength(5),
    // },
    surname: {
      required,
      minLength: minLength(2),
    },
    givenName: {
      required,
      minLength: minLength(2),
    },
    emailAddress: {
      required,
      email,
    },
    phoneNumber: {
      required,
      integer,
    },
  };
});

// Use vuelidate
const v$ = useVuelidate(rules, profileFormModel);

function phoneNUmberInput(value, event) {
  if (
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    profileFormModel.phoneNumber = event.nationalNumber;
  } else if (
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null
  ) {
    profileFormModel.phoneNumber = event.formatted;
  }
}

function phoneBlur() {
  validatePhoneNumber();
}

function countryChange(value) {
  profileFormModel.countryAccessCode = value.dialCode;
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}

onMounted(() => {
  let phoneEle = document.getElementById("phoneEle");
  phoneEle.style.border = "none";
  for (let i = 0; i < phoneEle.children.length; i++) {
    let childListElement = phoneEle.children[i];
    // (childListElement);
    // (childListElement.tagName);
    if (childListElement.tagName === "INPUT") {
      childListElement.classList.add("form-control");
    }
  }
  profileFormModel;
});
</script>

<template>
  <h3>{{ $t("my-profile") }}</h3>
  <div class="profile_update_form">
    <form action="!#" id="profile_form_area" @submit.prevent="modifyProfile">
      <div class="row" v-if="profileFormModel">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="f-name">{{ $t("first-name") }}</label>
            <input
              type="text"
              class="form-control"
              id="profile-surname"
              name="profile-surname"
              :class="{
                'is-invalid': v$.surname.$errors.length,
              }"
              @blur="v$.surname.$touch"
              placeholder="Your Name"
              v-model="profileFormModel.surname"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="l-name">{{ $t("last-name") }}</label>
            <input
              type="text"
              class="form-control"
              id="profile-givenName"
              name="profile-givenName"
              :class="{
                'is-invalid': v$.givenName.$errors.length,
              }"
              @blur="v$.givenName.$touch"
              v-model="profileFormModel.givenName"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="mail-address">{{ $t("email-address") }}</label>
            <input
              type="text"
              class="form-control"
              id="profile-emailAddress"
              name="profile-emailAddress"
              :class="{
                'is-invalid': v$.emailAddress.$errors.length,
              }"
              @blur="v$.emailAddress.$touch"
              v-model="profileFormModel.emailAddress"
              placeholder="(e.g. xxxxxxx@gmail.com)"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="mobil-number">{{ $t("phone-number") }}</label>
            <VueTelInput
              mode="international"
              :class="{
                'is-invalid': v$.phoneNumber.$errors.length > 0,
              }"
              id="phoneEle"
              autocomplete="off"
              :autoFormat="false"
              :value="profileFormModel.phoneNumber"
              @input="phoneNUmberInput"
              @blur="phoneBlur"
              @country-changed="countryChange"
            />
          </div>
        </div>
        <!--        <div class="col-lg-6">-->
        <!--          <div class="form-group">-->
        <!--            <label for="mobil-number">Password</label>-->
        <!--            <input-->
        <!--              type="password"-->
        <!--              class="form-control"-->
        <!--              id="profile-password"-->
        <!--              placeholder="Enter your password"-->
        <!--              name="profile-password"-->
        <!--              :class="{-->
        <!--                'is-invalid': v$.password.$errors.length,-->
        <!--              }"-->
        <!--              @blur="v$.password.$touch"-->
        <!--              v-model="profileFormModel.password"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-lg-12" align="center" style="padding-top: 35px">
          <button class="btn btn_theme btn_md">{{ $t("submit") }}</button>
        </div>
        <!-- change password-->
        <!--        <div class="col-lg-6">-->
        <!--          <div class="form-group change_password_field">-->
        <!--            <label for="password">Password</label>-->
        <!--            <input-->
        <!--              type="password"-->
        <!--              class="form-control"-->
        <!--              id="password"-->
        <!--              value="cdkdkdd"-->
        <!--            />-->
        <!--            <p>Change password</p>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="change_password_input_boxed">-->
        <!--          <h3>Change password</h3>-->
        <!--          <div class="row">-->
        <!--            <div class="col-lg-6">-->
        <!--              <div class="form-group">-->
        <!--                <input-->
        <!--                  type="password"-->
        <!--                  class="form-control"-->
        <!--                  placeholder="Old Password"-->
        <!--                />-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="col-lg-6">-->
        <!--              <div class="form-group">-->
        <!--                <input-->
        <!--                  type="password"-->
        <!--                  class="form-control"-->
        <!--                  placeholder="New Password"-->
        <!--                />-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </form>
  </div>
</template>

<style scoped></style>
