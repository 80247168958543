<script setup>
import { createVNode, reactive, ref, watch } from "vue";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { FlightPriceFormModel } from "@/formmodel/sales/flight/price/flightPriceFormModel";
import { useFlightPriceStore } from "@/stores/v2/flight/price/flightPriceStore";
import { useVuelidate } from "@vuelidate/core";
import { FlightBookFormModel } from "@/formmodel/sales/flight/book/flightBookFormModel";
import { useFlightBookStore } from "@/stores/v2/flight/book/flightBookStore";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import FlightItinararyInfoPanel from "@/views/v2/sales/flight/items/panel/FlightItinararyInfoPanel.vue";
import FlightContactInfoForm from "@/views/v2/sales/flight/items/form/FlightContactInfoForm.vue";
import FlightPassengerForm from "@/views/v2/sales/flight/items/form/FlightPassengerForm.vue";
import FlightPriceSummaryOrBreakdownPanel from "@/views/v2/sales/flight/items/panel/FlightPriceSummaryOrBreakdownPanel.vue";

const { t, locale } = useI18n(); // 解构调用函数

const AModal = Modal;
const route = useRoute();
const router = useRouter();
let templateStore = useTemplateStore();
const flightPriceStore = useFlightPriceStore();
const flightBookStore = useFlightBookStore();

const navigation = reactive({
  title: "Check Out",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/v2/flight/flight-list",
    },
    {
      name: "Check Out",
    },
  ],
});

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const airItineraryRPH = ref(route.query.airItineraryRPH);
const errorServerMessage = ref("");
const flightPriceFormModelRef = ref(
  new FlightPriceFormModel("PRICE", airItineraryRPH.value, [])
);

const passengerFormModelItemsRef = ref(null);
const tripAirItemRef = ref(null);
const priceClassesRef = ref(false); //价格类型数组

const v = useVuelidate();
watch(
  () => flightPriceStore.passengerFormModelItems,
  (newValue) => {
    passengerFormModelItemsRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => flightPriceStore.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => flightPriceStore.priceClasses,
  (newValue) => {
    priceClassesRef.value = newValue;
  },
  { deep: true, immediate: true }
);

//Call backend
airPrice(flightPriceFormModelRef.value);

const tripContactInfoRef = ref({
  phoneNumber: null,
  emailAddress: null,
  countryAccessCode: null,
});

const paymentMethodOpenRef = ref(false); //选择支付方式
const paymentFormOpenRef = ref(false); //支付信息表单

function airPrice(flightPriceFormModel) {
  templateStore.pageLoader({ mode: "on" });
  flightPriceStore.airPrice(flightPriceFormModel).then((res) => {
    if (res && !res.success) {
      if (res.errors && res.errors.errors && res.errors.errors.length > 1) {
        let error = res.errors.errors.find((item) => item.type === "ERR");
        if (error) {
          errorServerMessage.value = error.stringValue;
        } else {
          errorServerMessage.value = "Sever error!";
        }
      } else {
        errorServerMessage.value =
          res.errors && res.errors.errors && res.errors.errors.length > 0
            ? res.errors.errors[0].stringValue
            : "Sever error!";
      }
      openMassageModal(errorServerMessage.value, 2, "AIR_PRICE");
    }
    templateStore.pageLoader({ mode: "off" });
  });
}

function goBack() {
  window.close();
}

async function confirmBooking(
  flightPriceFormModel,
  passengerFormModelItems,
  quoteID
) {
  const result = await v.value.$validate();
  if (!result) return;
  if (flightPriceFormModel) {
    const airItineraryRPH = flightPriceFormModel.airItineraryRPH;
    const flightBookFormModel = new FlightBookFormModel(
      airItineraryRPH,
      quoteID,
      passengerFormModelItems,
      null,
      null
    );

    AModal.confirm({
      content: t("you-will-confirm-the-reservation-now"),
      icon: createVNode(ExclamationCircleOutlined),
      centered: true,
      width: 600,
      wrapClassName: "confirmModal",
      onOk() {
        return new Promise((resolve, reject) => {
          const flightBookFormModelCopy = JSON.parse(
            JSON.stringify(flightBookFormModel)
          );
          const bookFormModel = mergeFormModel(
            flightBookFormModelCopy,
            tripContactInfoRef.value
          );
          //进行air book
          templateStore.pageLoader({ mode: "on" });

          console.log(bookFormModel);
          flightBookStore.airBook(bookFormModel).then((res) => {
            if (res.success) {
              const bookingInfo = getBookingInfo(
                res.airReservation.bookingReferenceIdList
              );
              goToPaymentPage(bookingInfo.tripId, bookingInfo.invoiceId);
            } else {
              errorServerMessage.value = res.errors.errors[0].stringValue;
              openMassageModal(errorServerMessage.value, 2);
            }
            AModal.destroyAll();
            templateStore.pageLoader({ mode: "off" });
          });
        }).catch(() => {
          router.push({
            name: "error-500",
            query: { message: "Server Error!" },
          });
          templateStore.pageLoader({ mode: "off" });
        });
      },
      cancelText: "Cancel",
      onCancel() {
        AModal.destroyAll();
      },
    });
  }
}

function goToPaymentPage(bookingId, invoiceId) {
  router.push({
    name: "payment-methods",
    query: {
      bookingId: bookingId,
      invoiceId: invoiceId,
      productType: "flight",
    },
  });
}

function getBookingInfo(bookingReferenceIdList) {
  let recordLocator = null;
  let idContext = null;
  let airItemId = null;
  let tripId = null;
  let invoiceId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "RECORD_LOCATOR") {
        recordLocator = item.id;
        idContext = item.id_Context;
      } else if (item.type === "BOOKING_AIR_ITEM_ID") {
        airItemId = item.id;
      } else if (item.type === "BOOKING_ID") {
        tripId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      }
    });
  }

  return {
    recordLocator,
    idContext,
    airItemId,
    tripId,
    invoiceId,
  };
}

function mergeFormModel(flightBookFormModel, tripContactInfo) {
  const passengers = flightBookFormModel.passengers;

  if (passengers && passengers.length > 0 && tripContactInfo) {
    passengers.forEach((passenger) => {
      if (!passenger.phoneNumber) {
        passenger.phoneNumber = tripContactInfo.phoneNumber;
        passenger.countryAccessCode = tripContactInfo.countryAccessCode;
      }
      if (!passenger.emailAddress) {
        passenger.emailAddress = tripContactInfo.emailAddress;
      }
    });
  }

  return flightBookFormModel;
}

function openMassageModal(message, type, functionType) {
  if (type === 1) {
    AModal.success({
      title: "This is a success message",
      content: message,
      centered: true,
      width: 600,
    });
  } else {
    if (functionType === "AIR_PRICE") {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
        onOk() {
          return new Promise((resolve, reject) => {
            window.close(); //air price的时候出问题直接关闭页面回到list页面重选
          }).catch(() => console.log("Oops errors!"));
        },
      });
    } else {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
      });
    }
  }
}

function closePaymentForm() {
  paymentFormOpenRef.value = false;
  paymentMethodOpenRef.value = false;
}

//region 设备区分
const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion
</script>

<template>
  <!--   Banner Area -->
  <section id="page_banner">
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("check-out") }}</h2>
        <ul>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("check-out") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goBack">
              <i
                class="bi bi-arrow-left-square"
                size="40"
                style="
                  font-size: 28px;
                  color: #7ae3c3;
                  cursor: pointer;
                  margin-right: 10px;
                "
              ></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <h3 class="heading_theme">{{ $t("check-out") }}</h3>

      <div class="row">
        <div class="col-10">
          <!-- Itinerary Info -->
          <div class="tour_booking_form_box reservation-review">
            <h4>{{ $t("itinerary-info") }}</h4>

            <FlightItinararyInfoPanel
              :price-classes="priceClassesRef"
              :trip-air-item="tripAirItemRef"
            />
          </div>
          <!-- Itinerary Info END -->

          <!-- Contact Info Form -->
          <div class="tour_booking_form_box">
            <h4>{{ $t("contact-info") }}</h4>
            <FlightContactInfoForm :tripContactInfo="tripContactInfoRef" />
          </div>
          <!-- Contact Info Form END -->

          <div class="tour_booking_form_box">
            <h4>{{ $t("who-is-checking-in") }}</h4>
            <FlightPassengerForm
              :passenger-form-model-items="passengerFormModelItemsRef"
              :trip-air-item="tripAirItemRef"
            />
          </div>

          <div class="tour_booking_form_box">
            <FlightPriceSummaryOrBreakdownPanel
              :flight-price-form-model="flightPriceFormModelRef"
              :passenger-form-model-items="passengerFormModelItemsRef"
              :services-selected="flightPriceFormModelRef.servicesSelected"
              :trip-air-item="tripAirItemRef"
            />

            <div class="text-center">
              <button
                class="btn btn_theme btn_md"
                @click="
                  confirmBooking(
                    flightPriceFormModelRef,
                    passengerFormModelItemsRef,
                    tripAirItemRef ? tripAirItemRef.quoteID : null
                  )
                "
              >
                {{ $t("confirm-booking") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style></style>
