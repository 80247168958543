<script setup>
import { ref, watchEffect } from "vue";

const props = defineProps({
  bookingDetailsViewItem: {},
  totalAmountContent: {},
  expireInSecond: {},
});

let second = ref(0);
let minutes = ref(0);
watchEffect(() => {
  let secondExpire = JSON.parse(JSON.stringify(props.expireInSecond));
  setInterval(() => {
    secondExpire--;
    second.value = Math.floor(secondExpire % 60);
    minutes.value = Math.floor(secondExpire / 60);
  }, 1000);
});
</script>

<template>
  <div class="tour_booking_form_box">
    <!-- Booking Numbers Info -->
    <div class="col-12 fs-sm">
      <p class="h4 mb-3">
        {{ $t("booking") }} #{{ bookingDetailsViewItem.bookingId }}
      </p>
      <p
        class="h6 text-danger"
        v-if="totalAmountContent && totalAmountContent.totalAmount"
      >
        {{ totalAmountContent.currency }}
        {{ totalAmountContent.totalAmount.toFixed(2) }}
      </p>
      <p class="h6">
        {{ $t("created-date") }}:
        <span class="text-muted">{{ bookingDetailsViewItem.createDate }}</span>
      </p>
      <p
        class="h6 text-warning"
        v-if="
          expireInSecond > 0 &&
          bookingDetailsViewItem.bookingStatus !== 'CANCELLED' &&
          bookingDetailsViewItem.bookingStatus !== 'CONFIRMED'
        "
      >
        {{ $t("expire-time-min-s", { minutes: minutes, second: second }) }}
      </p>
      <p
        class="fw-semibold rounded-pill bg-danger-light text-danger fs-sm"
        v-else-if="
          expireInSecond <= 0 &&
          bookingDetailsViewItem.bookingStatus !== 'CANCELLED' &&
          bookingDetailsViewItem.bookingStatus !== 'CONFIRMED'
        "
      >
        {{ $t("expired") }}
      </p>
      <p class="mg-b-10">
        <span
          v-if="bookingDetailsViewItem.bookingStatus == 'CANCELLED'"
          class="fw-semibold d-inline-block rounded-pill bg-danger-light text-danger fs-sm"
          >{{ bookingDetailsViewItem.bookingStatus }}</span
        >
        <span
          v-else-if="bookingDetailsViewItem.bookingStatus == 'UNPAID'"
          class="fw-semibold d-inline-block rounded-pill bg-info-light text-info fs-sm"
          >{{ bookingDetailsViewItem.bookingStatus }}</span
        >
        <span
          v-else
          class="fw-semibold d-inline-block rounded-pill bg-success-light text-success fs-sm"
          >{{ bookingDetailsViewItem.bookingStatus }}</span
        >
      </p>
    </div>
  </div>
</template>

<style scoped></style>
