<script setup>
import BaseCancelPolicyPanel from "@/views/sales/hotel/items/panel/BaseCancelPolicyPanel.vue";
import BaseBookingHotelRemarksPanel from "@/views/sales/booking/items/BaseBookingHotelRemarksPanel.vue";
import BaseBookingHotelRateCommentsPanel from "@/views/sales/booking/items/BaseBookingHotelRateCommentsPanel.vue";
import { useBookingStore } from "@/stores/booking";
import BaseBookingHotelPassengersPanel from "@/views/sales/booking/items/BaseBookingHotelPassengersPanel.vue";

defineProps({
  hotelItems: {},
  totalResAmount: {
    default: 0,
  },
  equipmentType: {},
});

const bookingStore = useBookingStore();
</script>

<template>
  <div
    class="mb-4"
    v-for="(hotelItem, hotelItemIndex) in hotelItems"
    :key="hotelItemIndex"
  >
    <div class="tour_booking_form_box">
      <div class="col-12 fs-sm">
        <p class="h4 mb-2">
          {{ hotelItem.hotelName }}
        </p>
        <address>
          {{ hotelItem.address }}
        </address>
        <p class="mb-2">
          {{ hotelItem.roomQuantity }}
          <span v-if="hotelItem.roomQuantity == 1">room *</span>
          <span v-else>rooms *</span>
          {{ hotelItem.roomTypeNames }}
        </p>
        <p
          class="fs-sm fw-medium mb-2"
          v-if="
            hotelItem.roomViewItems &&
            hotelItem.roomViewItems.length > 0 &&
            hotelItem.roomViewItems[0].bedTypeName
          "
        >
          {{ $t("bed-type") }}:
          <span class="text-muted">{{
            hotelItem.roomViewItems[0].bedTypeName
          }}</span>
        </p>
        <div
          v-if="
            hotelItem.roomViewItems &&
            hotelItem.roomViewItems.length &&
            hotelItem.roomViewItems[0].mealsIncluded
          "
        >
          <i class="fa fa-bell-concierge"></i>
          {{ hotelItem.roomViewItems[0].mealName }}
        </div>
        <!--        <p-->
        <!--          class="import-content mb-2"-->
        <!--          v-if="hotelItem.refundable"-->
        <!--          data-bs-toggle="modal"-->
        <!--          data-bs-target="#modal-cancel-policies"-->
        <!--          style="color: red"-->
        <!--        >-->
        <!--          {{ $t("refundable") }}-->
        <!--          <i class="fa fa-circle-info" style="color: red"></i>-->
        <!--        </p>-->
        <p class="import-content mb-2" style="color: red">
          {{ $t("non-refundable") }}
          <i class="fa fa-circle-info" style="color: red"></i>
        </p>
        <p class="h6 mb-3">
          {{ $t("check-in") }}
          <span class="text-muted">{{ hotelItem.checkIn }}</span>
        </p>
        <p class="h6 mb-3">
          {{ $t("check-out") }}
          <span class="text-muted">{{ hotelItem.checkOut }}</span>
        </p>
        <p class="h6 mb-3">
          {{ hotelItem.daysDuration }}-{{ $t("night-stay") }}
        </p>
      </div>
    </div>

    <div
      class="booking_tour_form margin-top-20"
      v-if="
        bookingStore.bookingDetailsViewItem &&
        !bookingStore.bookingDetailsViewItem.flightItems
      "
    >
      <BaseBookingHotelPassengersPanel
        :equipment-type="equipmentType"
        v-if="hotelItem.passengers"
        :passengers="hotelItem.passengers"
      />
    </div>

    <!-- Cancel Policies Slide Left Block Modal -->
    <div
      class="modal fade"
      id="modal-cancel-policies"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-cancel-policies"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-slideleft" role="document">
        <div class="modal-content">
          <BaseCancelPolicyPanel
            :title="'Cancellation Policies'"
            :room-type-name="hotelItem.roomTypeNames"
            :items="hotelItem.roomViewItems"
          ></BaseCancelPolicyPanel>
        </div>
      </div>
    </div>
    <!-- END Cancel Policies Slide Left Block Modal -->

    <!-- Special Requests Info -->
    <BaseBookingHotelRemarksPanel
      class="margin-top-20"
      v-if="hotelItem.roomViewItems"
      :room-view-items="hotelItem.roomViewItems"
      :room-types="hotelItem.roomTypeNames.split(',')"
    />
    <!-- END Special Requests Info -->

    <!-- Rate Comments Info -->
    <BaseBookingHotelRateCommentsPanel
      class="margin-top-20"
      v-if="hotelItem.roomViewItems"
      :room-view-items="hotelItem.roomViewItems"
      :room-types="hotelItem.roomTypeNames.split(',')"
    />
    <!-- END Rate Comments Info -->
  </div>
</template>

<style scoped></style>
