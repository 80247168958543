<script setup>
import { reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useProfileStore } from "@/stores/profile";
import Banner from "@/components/custom/Banner.vue";
import BaseCustomerMyProfilePanel from "@/views/profiles/items/panel/BaseCustomerMyProfilePanel.vue";
import BaseCusomterMyBookingsPanel from "@/views/profiles/items/panel/BaseCusomterMyBookingsPanel.vue";
import { useSecurityStore } from "@/stores/security";
import profile from "@/router/modules/profile";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "My Profiles",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "My Profiles",
    },
  ],
});

let securityStore = useSecurityStore();

let user = reactive(JSON.parse(sessionStorage.getItem("user")));

const profileStore = useProfileStore();
profileStore.$reset(); //重置状态

profileStore.loadUserProfile(user.username);

let active = ref("booking");

function changeActive(val) {
  active.value = val;
}

async function logOut() {
  sessionStorage.removeItem("user");
  await securityStore.signOut();
  user = null;
  router.push({ name: "home" });
}
</script>
<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="dashboard_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="$router.back">
              <i
                class="bi bi-arrow-left-square"
                size="40"
                style="
                  font-size: 28px;
                  color: #7ae3c3;
                  cursor: pointer;
                  margin-right: 10px;
                "
              ></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="dashboard_sidebar">
            <!-- 左上用戶信息展示-->
            <div
              class="dashboard_sidebar_user"
              v-if="profileStore.profileViewItem"
            >
              <!--              <img src="/assets/media/avatars/avatar10.jpg" alt="img" />-->
              <i
                class="bi bi-person-circle"
                style="font-size: 80px; color: white"
              ></i>
              <h3>
                {{ profileStore.profileViewItem.surname }}/{{
                  profileStore.profileViewItem.givenName
                }}
              </h3>
              <p>{{ profileStore.profileViewItem.username }}</p>
              <p>
                +{{ profileStore.profileViewItem.countryAccessCode }}
                {{ profileStore.profileViewItem.phoneNumber }}
              </p>
              <p>
                <a>{{ profileStore.profileViewItem.emailAddress }}</a>
              </p>
            </div>

            <div class="dashboard_menu_area">
              <ul>
                <li>
                  <!--                    添加点击事件，改变active值，决定显示哪个组件-->
                  <a
                    @click="changeActive('profile')"
                    :class="[active == 'profile' ? 'activeColor' : '']"
                    ><i class="fas fa-user-circle"></i>{{ $t("my-profile") }}</a
                  >
                </li>
                <li>
                  <a
                    @click="changeActive('booking')"
                    :class="[active == 'booking' ? 'activeColor' : '']"
                    ><i class="fas fa-user-circle"></i
                    >{{ $t("my-bookings") }}</a
                  >
                </li>
                <li>
                  <a @click="logOut"
                    ><i class="fas fa-bell"></i>{{ $t("logout") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="dashboard_common_table" v-if="active == 'profile'">
            <BaseCustomerMyProfilePanel
              v-if="profileStore.profileViewItem"
              :profile-info="profileStore.profileViewItem"
            ></BaseCustomerMyProfilePanel>
          </div>
          <div class="dashboard_common_table" v-else-if="active == 'booking'">
            <BaseCusomterMyBookingsPanel></BaseCusomterMyBookingsPanel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
