import BookingListView from "@/views/v2/sales/booking/BookingListView.vue";
import BookingDetailsView from "@/views/v2/sales/booking/BookingDetailsView.vue";

export default [
  {
    path: "/sales/v2/booking",
    redirect: "/sales/v2/booking/booking-list",
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "booking-list",
        name: "sales-v2-booking-list",
        component: BookingListView,
        meta: { requiresAuth: true },
      },
      {
        path: "booking-details",
        name: "sales-v2-booking-details",
        component: BookingDetailsView,
        meta: { requiresAuth: true },
      },
    ],
  },
];
