<script setup>
import { onMounted, onUnmounted } from "vue";
import dayjs from "dayjs";

const props = defineProps({
  passengers: {},
  flightViewItems: {},
  equipmentType: {},
});
if (props.flightViewItems && props.flightViewItems.length > 0) {
  let dates = [];
  if (props.flightViewItems && props.flightViewItems.length > 0) {
    props.flightViewItems.forEach((item) => {
      dates.push(item.departureDateTime);
      dates.push(item.arrivalDateTime);
    });
  }
  props.passengers.forEach((passenger) => {
    let res = dates.some(
      (item) =>
        new Date(dayjs(item).add(6, "month").format("YYYY-MM-DD")).getTime() >=
        new Date(passenger.docExpireDate).getTime()
    );
    if (res) {
      passenger.warnExpireDate = true;
    }
  });
}
let tooltipTriggerList = [];
let tooltipList = [];
// Init tooltips on content loaded
onMounted(() => {
  // Grab all tooltip containers..
  tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  // ..and init them
  tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
    console.info(tooltipTriggerEl.dataset.bsContainer);
    console.info(tooltipTriggerEl.dataset.bsContainer || "#page-container");
    return new window.bootstrap.Tooltip(tooltipTriggerEl, {
      container: tooltipTriggerEl.dataset.bsContainer || "#page-container",
      animation:
        tooltipTriggerEl.dataset.bsAnimation &&
        tooltipTriggerEl.dataset.bsAnimation.toLowerCase() == "true"
          ? true
          : false,
    });
  });
});

// Dispose tooltips on unMounted
onUnmounted(() => {
  tooltipList.forEach((tooltip) => tooltip.dispose());
});

function sortPassengers(passengers) {
  if (passengers && passengers.length > 0) {
    passengers.sort(function (a, b) {
      if (a.phoneNumber && !b.phoneNumber) {
        return -1;
      }
      if (b.phoneNumber && !a.phoneNumber) {
        return 1;
      }
      if (a.passengerTypeCode < b.passengerTypeCode) {
        return -1;
      }
      if (a.passengerTypeCode > b.passengerTypeCode) {
        return 1;
      }
      return 0;
    });
  }
  return passengers;
}
</script>

<template>
  <div v-if="passengers">
    <p class="h4 mb-2">{{ $t("traveler-info") }}</p>
    <div v-if="equipmentType === 'iphone' || equipmentType === 'ipad'">
      <div
        v-for="(passenger, passengerIndex) in sortPassengers(passengers)"
        :key="passengerIndex"
      >
        <div class="booking_form_box_item">
          <span class="h6">{{ $t("name") }}</span>
          <span class="text-muted">
            {{ passenger.nameTitle }} {{ passenger.surname }}/{{
              passenger.givenName
            }}</span
          >
        </div>
        <div v-if="passenger.docID" class="booking_form_box_item">
          <span class="h6">{{ $t("passport") }}</span>
          <span class="text-muted"> {{ passenger.docID }}</span>
        </div>
        <div v-if="passenger.docExpireDate" class="booking_form_box_item">
          <span class="h6">{{ $t("expiry-time") }}</span>
          <span class="text-muted">
            {{ passenger.docExpireDate
            }}<i
              data-bs-toggle="tooltip"
              id="page-container"
              data-bs-placement="bottom"
              title="Passport expiry date is within 6 months of departure or return"
              data-trigger="hover"
              class="bi bi-exclamation-circle"
              style="margin-left: 10px; color: #ffc107; cursor: pointer"
            ></i
          ></span>
        </div>
        <div
          v-if="passenger.docHolderNationalityName"
          class="booking_form_box_item"
        >
          <span class="h6">{{ $t("nationality") }}</span>
          <span class="text-muted">
            {{ passenger.docHolderNationalityName }}</span
          >
        </div>
        <div v-if="passenger.emailAddress" class="booking_form_box_item">
          <span class="h6">{{ $t("email") }}</span>
          <span class="text-muted"> {{ passenger.emailAddress }}</span>
        </div>
        <div v-if="passenger.phoneNumber" class="booking_form_box_item">
          <span class="h6">{{ $t("phone") }}</span>
          <span class="text-muted">
            +{{ passenger.countryAccessCode }} {{ passenger.phoneNumber }}</span
          >
        </div>
      </div>
      <hr />
    </div>

    <div v-else class="row">
      <div
        v-for="(passenger, paxIndex) in sortPassengers(passengers)"
        :key="paxIndex"
        class="col-6"
      >
        <div class="font_weight">{{ $t("passenger") }} {{ paxIndex + 1 }}</div>
        <div class="p-1">
          <div class="flex-between align-content-center">
            <div>{{ $t("guest-name") }}</div>
            <div>
              <span v-if="passenger.nameTitle">{{ passenger.nameTitle }}. </span
              >{{ passenger.surname }} / {{ passenger.givenName }}
              <span v-if="passenger.gender"
                >{{ $t("gender") }} : {{ passenger.gender }}</span
              >
            </div>
          </div>
          <div
            v-if="passenger.birthDate"
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("birth-date") }}</div>
            <div>
              <span>{{ passenger.birthDate }} </span>
            </div>
          </div>
          <div
            v-if="passenger.phoneNumber"
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("phone-number") }}</div>
            <div>
              <span>{{ passenger.phoneNumber }} </span>
            </div>
          </div>
          <div
            v-if="passenger.emailAddress"
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("email-address") }}</div>
            <div>
              <span>{{ passenger.emailAddress }} </span>
            </div>
          </div>
          <div
            v-if="passenger.docID"
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("passport") }}</div>
            <div>
              <span
                >{{ passenger.docHolderNationality }}
                {{ passenger.docID }}
              </span>
            </div>
          </div>
          <div
            v-if="passenger.docExpireDate"
            class="flex justify-content-between align-content-center mg-b-10"
          >
            <div>{{ $t("expire-date") }}</div>
            <div>
              <span>{{ passenger.docExpireDate }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
