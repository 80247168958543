import booking from "@/router/modules/booking";
import error from "@/router/modules/error";
import flight from "@/router/modules/flight";
import hotel from "@/router/modules/hotel";
import packages from "@/router/modules/dynamicPackage";
import templates from "@/router/modules/templates";
import security from "@/router/modules/security";
import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/sales/common/HomeView.vue";
import payment from "@/router/modules/payment";
import profile from "@/router/modules/profile";
import faq from "@/router/modules/faq";
import about from "@/router/modules/about";
import contact from "@/router/modules/contact";
import privacypolicy from "@/router/modules/privacypolicy";
import termsAndcondition from "@/router/modules/termsAndcondition";

import flightV2 from "@/router/v2/modules/sales/flight";
import hotelV2 from "@/router/v2/modules/sales/hotel";
import commonV2 from "@/router/v2/modules/sales/common";
import bookingV2 from "@/router/v2/modules/sales/booking";

const routes = [
  {
    path: "/",
    component: HomeView,
    name: "home",
    meta: {
      requireAuth: false, //此时表示进入这个路由是需要登录的
      footerShow: true,
    },
  },
  ...about,
  ...booking,
  ...contact,
  ...error,
  ...faq,
  ...flight,
  ...hotel,
  ...packages,
  ...security,
  ...templates,
  ...payment,
  ...privacypolicy,
  ...profile,
  ...termsAndcondition,

  ...flightV2,
  ...hotelV2,
  ...commonV2,
  ...bookingV2,
];

// Create Router
const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

export default router;
