/**
 *
 * @param flightPriceFormModel FlightPriceFormModel对象
 * @returns {{offer: {priced: []}, airItinerary: {airItineraryRPH: *}, type: *}}
 */
function buildAirPriceRQ(flightPriceFormModel) {
  const type = flightPriceFormModel.type;
  const airItineraryRPH = flightPriceFormModel.airItineraryRPH;
  const servicesSelected = flightPriceFormModel.servicesSelected;

  let offerPriced = [];

  if (servicesSelected && servicesSelected.length > 0) {
    for (const serviceSelected of servicesSelected) {
      const quantity = serviceSelected.quantity;
      const seatRowNumber = serviceSelected.seatRowNumber;
      const seatColumnNumber = serviceSelected.seatColumnNumber;
      const flightSegmentRPH = serviceSelected.flightSegmentRPH;
      const travelerRPH = serviceSelected.travelerRPH;
      const ancillaryId = serviceSelected.ancillaryId;
      let pricing = null;
      if (quantity) {
        pricing = {
          offerQty: quantity,
        };
      }

      offerPriced.push({
        id: ancillaryId,
        travelerRPH: travelerRPH,
        flightSegmentRPH: flightSegmentRPH,
        pricing: pricing,
      });
    }
  }

  return {
    type: type,
    airItinerary: {
      airItineraryRPH: airItineraryRPH,
    },
    offer: {
      priced: offerPriced,
    },
  };
}

export default {
  buildAirPriceRQ,
};
