import hotelAvailabilityTools from "@/tools/v2/hotel/availability/hotelAvailabilityTools";
import TripHotelItemVO from "@/viewobject/trip/hotel/tripHotelItemVO";

function buildTripHotelItem(hotelReservation) {
  const hotelRoomTypeVOMap = {};
  const roomStays = hotelReservation.roomStayList;
  const roomQuantity = roomStays.length;
  const basicPropertyInfo = roomStays[0].basicPropertyInfo;
  const infoSource = roomStays[0].infoSource;
  const tticode = basicPropertyInfo.tticode;
  const hotelName = basicPropertyInfo.hotelName;
  const cityName = basicPropertyInfo.hotelCtyName;
  const starRating = basicPropertyInfo.rating;
  const mainImageURL = basicPropertyInfo.mainImageURL;
  const position = basicPropertyInfo.position;
  const latitude = position ? position.latitude : null;
  const longitude = position ? position.longitude : null;
  const address = basicPropertyInfo.address
    ? basicPropertyInfo.address.addressString
    : null;
  const countryName = basicPropertyInfo.address
    ? basicPropertyInfo.address.countryName
    : null;
  const awards = basicPropertyInfo.awards;
  const description = basicPropertyInfo.shortDescriptions;
  const uniqueIDs = hotelReservation.uniqueIDList;
  const resGlobalInfo = hotelReservation.resGlobalInfo;
  const total = resGlobalInfo.total;
  const totalAmountReservation = total.amountIncludingMarkup;
  const currencyCode = total.currencyCode;
  let userRating = 0;
  if (awards != null && awards.length > 0) {
    awards.forEach((award) => {
      if (award.provider === "TRIPADVISOR") {
        userRating =
          award.rating == null || award.rating === "" || award.rating === "null"
            ? 0
            : parseFloat(award.rating);
      }
    });
  }

  const tripItemStatus = hotelReservation.resStatus;
  let tripItemId = null;
  let tripHotelItemId = null;
  let recordLocator = null;
  let idContext = null;
  for (const uniqueID of uniqueIDs) {
    const idType = uniqueID.type;
    const id = uniqueID.id;
    if (idType === "BOOKING_ITEM_ID") {
      tripItemId = id;
    } else if (idType === "BOOKING_HOTEL_ITEM_ID") {
      tripHotelItemId = id;
    } else if (idType === "REFERENCE_ID") {
      recordLocator = id;
      idContext = uniqueID.id_context;
    }
  }

  const tripHotelItemVO = new TripHotelItemVO(
    tripItemStatus,
    tripItemId,
    tripHotelItemId,
    recordLocator,
    idContext,
    infoSource,
    null,
    mainImageURL,
    hotelName,
    cityName,
    starRating,
    userRating,
    address,
    currencyCode,
    null,
    totalAmountReservation,
    null,
    longitude,
    latitude,
    tticode,
    description,
    roomQuantity,
    null,
    null,
    null,
    null
  );

  //酒店重要信息只初始化一次
  if (!tripHotelItemVO.policies || tripHotelItemVO.policies.length === 0) {
    let vendorMessages = basicPropertyInfo.vendorMessages;
    if (vendorMessages) {
      vendorMessages.forEach((vendorMessage) => {
        let policyCode = vendorMessage.infoType;
        let policyName = null;
        switch (policyCode) {
          case "CHECK_IN":
            policyName = "Check In";
            break;
          case "FEES":
            policyName = "Fees";
            break;
          case "POLICY":
            policyName = "Important Information";
            break;
          default:
        }

        let hotelPolicyVO = tripHotelItemVO.addNewPolicy(
          policyCode,
          policyName,
          null
        );
        if (vendorMessage.subSections) {
          vendorMessage.subSections[0].paragraphs.forEach((paragraph) => {
            hotelPolicyVO.addNewPolicyContent(paragraph.name, paragraph.text);
          });
        }
      });
    }
  }

  roomStays.forEach((roomStay) => {
    const roomTypeCode = roomStay.roomTypes[0].roomTypeCode;
    //每个房型信息只初始化一次
    const hotelRoomTypeVOKey = tticode + ":" + roomTypeCode;
    let hotelRoomTypeVO = hotelRoomTypeVOMap[hotelRoomTypeVOKey];
    if (!hotelRoomTypeVO) {
      hotelRoomTypeVO = hotelAvailabilityTools.buildBaseRoomTypeVO(roomStay);
      tripHotelItemVO.addRoomType(hotelRoomTypeVO);
      hotelRoomTypeVOMap[hotelRoomTypeVOKey] = hotelRoomTypeVO;
    }

    const roomVO = hotelAvailabilityTools.buildRoomVO(roomStay);
    hotelRoomTypeVO.addRoom(roomVO);
  });

  return tripHotelItemVO;
}

export default {
  buildTripHotelItem,
};
