<script setup>
import { email, integer, required } from "@vuelidate/validators";
import { onMounted, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import FlatPickr from "vue-flatpickr-component";
import * as regexUtils from "@/utils/regexUtils";
import dayjs from "dayjs";

let props = defineProps({
  passengerIndex: {
    type: Number,
  },
  passengerModel: {},
  frequentFlyerPrograms: {},
  ShowCodeOnList: {
    //是否在国家选择器内展示国际号
    type: Boolean,
    default: false,
  },
});

const nameRule = (value) => regexUtils.nameRegx.test(value);
const validateFAirRule = () => {
  if (
    (props.passengerModel.flyerAirline == null &&
      props.passengerModel.ffpNumber == null) ||
    (props.passengerModel.flyerAirline == null &&
      props.passengerModel.ffpNumber == "") ||
    (props.passengerModel.flyerAirline != null &&
      props.passengerModel.flyerAirline != "")
  ) {
    return true;
  }
};

const validateFFPRule = () => {
  if (
    (props.passengerModel.flyerAirline == null &&
      props.passengerModel.ffpNumber == null) ||
    (props.passengerModel.flyerAirline == null &&
      props.passengerModel.ffpNumber == "") ||
    (props.passengerModel.ffpNumber != null &&
      props.passengerModel.ffpNumber != "")
  ) {
    return true;
  }
};
let rules = reactive({
  nameTitle: { required },
  givenName: { required, nameRule },
  surname: { required, nameRule },
  docID: { required },
  docExpireDate: { required },
  docHolderNationality: { required },
  birthDate: { required },
  flyerAirline: { validateFAirRule },
  ffpNumber: { validateFFPRule },
});

if (
  props.passengerModel.passengerTypeCode === "ADT" &&
  props.passengerIndex == 1
) {
  rules.countryAccessCode = { required };
  rules.emailAddress = { required, email };
  rules.phoneNumber = { required, integer };
}

// Use vuelidate
let passengerModelItem = reactive(props.passengerModel);
const v$ = useVuelidate(rules, passengerModelItem);
let adultNameTitleOptions = ref([
  {
    value: "MR",
    label: "Mr.",
  },
  {
    value: "MS",
    label: "Ms.",
  },
  {
    value: "MRS",
    label: "Mrs.",
  },
  {
    value: "DR",
    label: "Dr.",
  },
  {
    value: "",
    label: "Prof.",
  },
]);

let childNameTitleOptions = ref([
  {
    value: "MSTR",
    label: "Master",
  },
  {
    value: "MISS",
    label: "Miss",
  },
]);

function getPassengerType(typeCode) {
  let typeName = "Adult";
  if (typeCode === "CNN") {
    typeName = "Child";
  } else if (typeCode === "INF") {
    typeName = "Infant";
  }
  return typeName;
}

function countryChange(value) {
  console.log(value.dialCode);
  props.passengerModel.countryAccessCode = value.dialCode;
}

//region 电话号码
function phoneNumberInput(value, event) {
  if (value && typeof value !== "object") {
    props.passengerModel.phoneNumber = value;
    validatePhoneNumber();
  }
}

function phoneBlur() {
  validatePhoneNumber();
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}

const validatePhoneNumberExpose = () => {
  if (!v$.value.phoneNumber) return;
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
};

function getPhoneElementId(passengerIndex) {
  return "phoneEle-" + passengerIndex;
}

function validateAllCustom() {
  validatePhoneNumberExpose();
  validatePassportExpose();
}

defineExpose({
  validatePhoneNumberExpose,
  validateAllCustom,
});

onMounted(() => {
  let phoneEle = document.getElementById(
    getPhoneElementId(props.passengerIndex)
  );
  if (phoneEle) {
    phoneEle.style.border = "none";
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("form-control");
        childListElement.classList.add("bg_input");
      }
    }
  }
});
//endregion 电话号码

//region 护照

function getPassportElementId(passengerIndex) {
  return "passportEle-" + passengerIndex;
}

onMounted(() => {
  let passportEle = document.getElementById(
    getPassportElementId(props.passengerIndex)
  );
  if (passportEle) {
    passportEle.style.border = "none";
    for (let i = 0; i < passportEle.children.length; i++) {
      let childListElement = passportEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("form-control");
        childListElement.classList.add("bg_input");
      }
    }
  }
});

function passportNumberInput(value, event) {
  if (typeof value !== "string") return;
  props.passengerModel.docID = value;
  if (event && event.country) {
    let countryNameFormat = event.country.name.substring(
      0,
      event.country.name.indexOf("(")
    );
    props.passengerModel.docHolderNationality = event.country.iso2;
    props.passengerModel.docHolderNationalityName = countryNameFormat;
  }
}

function passportBlur() {
  validatePassportExpose();
}

const validatePassportExpose = () => {
  if (!v$.value.docID) return;
  v$.value.docID.$touch();
  if (v$.value.docID.$errors.length > 0) {
    let passportEle = document.getElementById(
      getPassportElementId(props.passengerIndex)
    );
    for (let i = 0; i < passportEle.children.length; i++) {
      let childListElement = passportEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let passportEle = document.getElementById(
      getPassportElementId(props.passengerIndex)
    );
    for (let i = 0; i < passportEle.children.length; i++) {
      let childListElement = passportEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
};

//endregion 护照

let warnExpireDate = ref(false);

function chooseExpireDate(date) {
  warnExpireDate.value = false;
  let flightSearchFormModel = JSON.parse(
    sessionStorage.getItem("flightSearchFormModel")
  );
  let originDestinations = flightSearchFormModel.originDestinations;
  let dates = [];
  if (originDestinations && originDestinations.length > 0) {
    originDestinations.forEach((item) => {
      dates.push(item.departureDateTime);
      if (item.returnDateTime != null && item.returnDateTime != "undefined") {
        dates.push(item.returnDateTime);
      }
    });
  }
  let res = dates.some(
    (item) =>
      new Date(
        dayjs(new Date(item)).add(6, "month").format("YYYY-MM-DD")
      ).getTime() >= new Date(date).getTime()
  );
  if (res) {
    warnExpireDate.value = true;
  }
}

//获取生日的最早时间选择
function getMinDate(type) {
  let day = new Date();
  if (type === "ch") {
    day.setFullYear(day.getFullYear() - 10);

    day.setDate(day.getDate() + 1);
    return day;
  } else {
    day.setFullYear(day.getFullYear() - 2);
    //不是去年的今天，应该是去年今天后一天，如果是去年去年今天出生，他到今天就满了两岁，不符合条件
    day.setDate(day.getDate() + 1);
    return day;
  }
}

//获取生日最晚时间限制
function getMaxDate(type) {
  let day = new Date();
  if (type === "ad") {
    day.setFullYear(day.getFullYear() - 12);
    return day;
  } else {
    day.setFullYear(day.getFullYear() - 2);
    return day;
  }
}
</script>

<template>
  <div>
    <div class="margin-bottom-20">
      <h4>
        {{ $t("traveler") }} - {{ passengerIndex }}:
        {{ getPassengerType(passengerModel.passengerTypeCode) }}
      </h4>
    </div>
    <!-- 姓名-->
    <div class="row">
      <div class="col-xl-6 col-xxl-2 col-lg-6">
        <div class="form-group">
          <select
            v-if="getPassengerType(passengerModel.passengerTypeCode) == 'Adult'"
            type="text"
            class="form-control bg_input"
            :class="{
              'is-invalid': v$.nameTitle.$errors.length > 0,
            }"
            v-model="v$.nameTitle.$model"
          >
            <option
              v-for="(
                nameTitleOption, nameTitleOptionIndex
              ) in adultNameTitleOptions"
              :key="nameTitleOptionIndex"
              :value="nameTitleOption.value"
            >
              {{ nameTitleOption.label }}
            </option>
          </select>
          <select
            v-else
            type="text"
            class="form-control bg_input"
            :class="{
              'is-invalid': v$.nameTitle.$errors.length > 0,
            }"
            v-model="v$.nameTitle.$model"
          >
            <option
              v-for="(
                nameTitleOption, nameTitleOptionIndex
              ) in childNameTitleOptions"
              :key="nameTitleOptionIndex"
              :value="nameTitleOption.value"
            >
              {{ nameTitleOption.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-6 col-xxl-4 col-lg-6">
        <div class="form-group">
          <input
            type="text"
            :class="{
              'is-invalid': v$.surname.$errors.length > 0,
            }"
            class="form-control bg_input"
            placeholder="Last name*"
            v-model="v$.surname.$model"
          />
        </div>
      </div>
      <div class="col-xl-6 col-xxl-4 col-lg-6">
        <div class="form-group">
          <input
            :class="{
              'is-invalid': v$.givenName.$errors.length > 0,
            }"
            v-model="v$.givenName.$model"
            type="text"
            class="form-control bg_input"
            placeholder="First name*"
          />
        </div>
      </div>

      <!-- 生日（做出年龄限制）-->
      <div
        class="col-xl-6 col-xxl-2 col-lg-6"
        v-if="getPassengerType(passengerModel.passengerTypeCode) === 'Adult'"
      >
        <div class="form-group">
          <FlatPickr
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Birth Date"
            :class="{
              'is-invalid': v$.birthDate.$errors.length > 0,
            }"
            v-model="v$.birthDate.$model"
            :config="{
              maxDate: getMaxDate('ad'),
            }"
          />
        </div>
      </div>
      <div
        class="col-xl-6 col-xxl-2 col-lg-6"
        v-else-if="
          getPassengerType(passengerModel.passengerTypeCode) === 'Child'
        "
      >
        <div class="form-group">
          <FlatPickr
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Birth Date"
            :class="{
              'is-invalid': v$.birthDate.$errors.length > 0,
            }"
            v-model="v$.birthDate.$model"
            :config="{
              maxDate: getMaxDate('ch'),
              minDate: getMinDate('ch'),
            }"
          />
        </div>
      </div>
      <div class="col-xl-6 col-xxl-2 col-lg-6" v-else>
        <div class="form-group">
          <FlatPickr
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Birth Date"
            :class="{
              'is-invalid': v$.birthDate.$errors.length > 0,
            }"
            v-model="v$.birthDate.$model"
            :config="{
              maxDate: 'today',
              minDate: getMinDate('inf'),
            }"
          />
        </div>
      </div>
    </div>
    <!--   邮箱电话-->
    <div class="row">
      <div
        class="col-lg-6"
        v-if="
          passengerModel.passengerTypeCode === 'ADT' && passengerIndex === 1
        "
      >
        <div class="form-group">
          <input
            type="text"
            :class="{
              'is-invalid': v$.emailAddress.$errors.length > 0,
            }"
            class="form-control bg_input"
            placeholder="Email address"
            v-model="v$.emailAddress.$model"
          />
        </div>
      </div>
      <div
        class="col-lg-6"
        v-if="
          passengerModel.passengerTypeCode === 'ADT' && passengerIndex === 1
        "
      >
        <VueTelInput
          mode="international"
          :class="{
            'is-invalid': v$.phoneNumber.$errors.length > 0,
          }"
          :id="getPhoneElementId(props.passengerIndex)"
          autocomplete="off"
          :autoFormat="false"
          :inputOptions="{
            placeholder: 'Phone number*',
          }"
          @input="phoneNumberInput"
          @blur="phoneBlur"
          @country-changed="countryChange"
        />
      </div>
    </div>
    <!--   护照-->
    <div class="row">
      <div class="col-lg-6">
        <VueTelInput
          mode="international"
          :class="{
            'is-invalid': v$.docID.$errors.length > 0,
          }"
          autocomplete="off"
          :id="getPassportElementId(props.passengerIndex)"
          :autoFormat="false"
          :inputOptions="{
            placeholder: 'Passport no.*',
            autocomplete: false,
          }"
          :dropdownOptions="{
            showDialCodeInList: false,
            showDialCodeInSelection: false,
            showFlags: true,
          }"
          aria-required="false"
          @input="passportNumberInput"
          @blur="passportBlur"
        />
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <FlatPickr
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Expiry Date."
            :class="{
              'is-invalid': v$.docExpireDate.$errors.length > 0,
            }"
            v-model="v$.docExpireDate.$model"
            :config="{
              minDate: 'today',
            }"
            @change="chooseExpireDate(passengerModel.docExpireDate)"
          />
        </div>
      </div>
      <span
        id="warnExpireDate"
        style="margin-left: 48%; margin-top: -20px; color: #ffc107"
        v-if="warnExpireDate"
        ><i class="bi bi-exclamation-circle"></i
        >{{ $t("passport-expiry-date") }}</span
      >
    </div>
    <!--   联盟 -->
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <select
            type="text"
            class="form-control bg_input"
            :class="{
              'is-invalid': v$.flyerAirline.$errors.length > 0,
            }"
            v-model="v$.flyerAirline.$model"
          >
            <option
              v-for="(
                frequentFlyerProgram, frequentFlyerIndex
              ) in frequentFlyerPrograms"
              :key="frequentFlyerIndex"
              :value="frequentFlyerProgram.programID"
            >
              {{ frequentFlyerProgram.programName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input
            v-model="v$.ffpNumber.$model"
            :class="{
              'is-invalid': v$.ffpNumber.$errors.length > 0,
            }"
            type="text"
            class="form-control bg_input"
            placeholder="Frequent Flyer Airline Number(option)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
