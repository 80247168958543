<script setup>
import { required, email, integer } from "@vuelidate/validators";
import { onMounted, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import FlatPickr from "vue-flatpickr-component";
import * as regexUtils from "@/utils/regexUtils";
import dateUtils from "@/utils/dateUtils";
import dayjs from "dayjs";

let props = defineProps({
  passengerIndex: {
    type: Number,
  },
  passengerModel: {},
  frequentFlyerPrograms: {},
  roomIndex: {},
  isFlight: {},
});

// Use vuelidate
let pkgPassengerModel = reactive(props.passengerModel);

const nameRule = (value) => regexUtils.nameRegx.test(value);
const validateFFPRule = () => {
  if (
    (pkgPassengerModel.flyerAirline == null &&
      pkgPassengerModel.ffpNumber == null) ||
    (pkgPassengerModel.flyerAirline != null &&
      pkgPassengerModel.ffpNumber != null &&
      pkgPassengerModel.flyerAirline != "" &&
      pkgPassengerModel.ffpNumber != "")
  ) {
    return true;
  }

  return false;
};

let rules = reactive({
  nameTitle: { required },
  givenName: { required, nameRule },
  surname: { required, nameRule },
  docID: { required },
  docExpireDate: { required },
  docHolderNationality: { required },
  birthDate: { required },
  flyerAirline: { validateFFPRule },
  ffpNumber: { validateFFPRule },
});

if (pkgPassengerModel.primaryIndicator) {
  rules.emailAddress = { required, email };
  rules.phoneNumber = { required, integer };
}
const v$ = useVuelidate(rules, pkgPassengerModel);

let adultNameTitleOptions = ref([
  {
    value: "MR",
    label: "Mr.",
  },
  {
    value: "MS",
    label: "Ms.",
  },
  {
    value: "MRS",
    label: "Mrs.",
  },
  {
    value: "DR",
    label: "Dr.",
  },
  {
    value: "",
    label: "Prof.",
  },
]);

let childNameTitleOptions = ref([
  {
    value: "MSTR",
    label: "Master",
  },
  {
    value: "MISS",
    label: "Miss",
  },
]);

function countryChange(value) {
  pkgPassengerModel.countryAccessCode = value.dialCode;
}

//region 电话号码
function phoneNUmberInput(value, event) {
  if (value && typeof value !== "object") {
    pkgPassengerModel.phoneNumber = value;
    validatePhoneNumber();
  }
}

function phoneBlur() {
  validatePhoneNumber();
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}
const validatePhoneNumberExpose = () => {
  if (!v$.value.phoneNumber) return;
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById(
      getPhoneElementId(props.passengerIndex)
    );
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
};

function getPhoneElementId(passengerIndex) {
  return "phoneEle-" + props.roomIndex + passengerIndex;
}

function validateAllCustom() {
  validatePhoneNumberExpose();
  validatePassportExpose();
}

defineExpose({
  validatePhoneNumberExpose,
  validateAllCustom,
});

onMounted(() => {
  let phoneEle = document.getElementById(
    getPhoneElementId(props.passengerIndex)
  );
  if (phoneEle) {
    phoneEle.style.border = "none";
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("form-control");
        childListElement.classList.add("bg_input");
      }
    }
  }
});
//endregion 电话号码

//region 护照

function getPassportElementId(passengerIndex) {
  return "passportEle-" + props.roomIndex + passengerIndex;
}

onMounted(() => {
  let passportEle = document.getElementById(
    getPassportElementId(props.passengerIndex)
  );
  if (passportEle) {
    passportEle.style.border = "none";
    for (let i = 0; i < passportEle.children.length; i++) {
      let childListElement = passportEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("form-control");
        childListElement.classList.add("bg_input");
      }
    }
  }
});

function passportNumberInput(value, event) {
  if (typeof value !== "string") return;
  pkgPassengerModel.docID = value;
  if (event && event.country) {
    let countryNameFormat = event.country.name.substring(
      0,
      event.country.name.indexOf("(")
    );
    pkgPassengerModel.docHolderNationality = event.country.iso2;
    pkgPassengerModel.docHolderNationalityName = countryNameFormat;
  }
}

function passportBlur() {
  validatePassportExpose();
}
const validatePassportExpose = () => {
  if (!v$.value.docID) return;
  v$.value.docID.$touch();
  if (v$.value.docID.$errors.length > 0) {
    let passportEle = document.getElementById(
      getPassportElementId(props.passengerIndex)
    );
    for (let i = 0; i < passportEle.children.length; i++) {
      let childListElement = passportEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let passportEle = document.getElementById(
      getPassportElementId(props.passengerIndex)
    );
    for (let i = 0; i < passportEle.children.length; i++) {
      let childListElement = passportEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
};

function getTravelerTypeName(pkgPassengerModel) {
  if (pkgPassengerModel.ageQualifyingCode == "8" && pkgPassengerModel.age < 2) {
    return "Infant";
  } else if (pkgPassengerModel.ageQualifyingCode == "8") {
    return "Child";
  } else {
    return "Adult";
  }
}

function getMaxDate(passengerType) {
  if (passengerType == "CHILD") {
    return dateUtils.format(new Date());
  } else if (passengerType == "ADULT") {
    let s = dayjs(dateUtils.format(new Date()))
      .subtract(12, "year")
      .format("YYYY-MM-DD");
    return s;
  }
}
//endregion 护照
let warnExpireDate = ref(false);
function chooseExpireDate(date) {
  warnExpireDate.value = false;
  let pkgPassengerFormModel = JSON.parse(
    sessionStorage.getItem("packageSearchFormModel")
  );

  let dates = [];
  dates.push(pkgPassengerFormModel.departureDate);
  dates.push(pkgPassengerFormModel.returnDate);

  let res = dates.some(
    (item) =>
      new Date(
        dayjs(new Date(item)).add(6, "month").format("YYYY-MM-DD")
      ).getTime() >= new Date(date).getTime()
  );
  if (res) {
    warnExpireDate.value = true;
  }
  sessionStorage.setItem(
    "warnExpireDate",
    JSON.stringify(warnExpireDate.value)
  );
}
</script>
<template>
  <form action="!#" id="tour_bookking_form_item">
    <H4
      >{{ $t("traveler") }} -{{ passengerIndex }}:
      {{ getTravelerTypeName(pkgPassengerModel) }}</H4
    >
    <!-- 姓名-->
    <div class="row">
      <div class="col-xl-6 col-xxl-2 col-lg-6">
        <div class="form-group">
          <select
            v-if="getTravelerTypeName(pkgPassengerModel) == 'Adult'"
            type="text"
            class="form-control bg_input"
            :class="{
              'is-invalid': v$.nameTitle.$errors.length > 0,
            }"
            v-model="v$.nameTitle.$model"
          >
            <option
              v-for="(
                nameTitleOption, nameTitleOptionIndex
              ) in adultNameTitleOptions"
              :key="nameTitleOptionIndex"
              :value="nameTitleOption.value"
            >
              {{ nameTitleOption.label }}
            </option>
          </select>

          <select
            v-else
            type="text"
            class="form-control bg_input"
            :class="{
              'is-invalid': v$.nameTitle.$errors.length > 0,
            }"
            v-model="v$.nameTitle.$model"
          >
            <option
              v-for="(
                nameTitleOption, nameTitleOptionIndex
              ) in childNameTitleOptions"
              :key="nameTitleOptionIndex"
              :value="nameTitleOption.value"
            >
              {{ nameTitleOption.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-6 col-xxl-4 col-lg-6">
        <div class="form-group">
          <input
            type="text"
            :class="{
              'is-invalid': v$.surname.$errors.length > 0,
            }"
            class="form-control bg_input"
            placeholder="Last name*"
            v-model="v$.surname.$model"
          />
        </div>
      </div>
      <div class="col-xl-6 col-xxl-4 col-lg-6">
        <div class="form-group">
          <input
            :class="{
              'is-invalid': v$.givenName.$errors.length > 0,
            }"
            v-model="v$.givenName.$model"
            type="text"
            class="form-control bg_input"
            placeholder="First name*"
          />
        </div>
      </div>

      <!-- 生日-->
      <div class="col-xl-6 col-xxl-2 col-lg-6">
        <div class="form-group">
          <FlatPickr
            v-if="getTravelerTypeName(pkgPassengerModel) == 'Adult'"
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Birth Date*"
            :class="{
              'is-invalid': v$.birthDate.$errors.length > 0,
            }"
            v-model="v$.birthDate.$model"
            :config="{
              maxDate: getMaxDate('ADULT'),
            }"
          />
          <FlatPickr
            v-else
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Birth Date*"
            :class="{
              'is-invalid': v$.birthDate.$errors.length > 0,
            }"
            v-model="v$.birthDate.$model"
            :config="{
              maxDate: getMaxDate('CHILD'),
              minDate: getMaxDate('ADULT'),
            }"
          />
        </div>
      </div>
    </div>
    <!--   邮箱电话-->
    <div class="row" v-if="pkgPassengerModel.primaryIndicator">
      <div class="col-lg-6">
        <div class="form-group">
          <input
            type="text"
            :class="{
              'is-invalid': v$.emailAddress.$errors.length > 0,
            }"
            class="form-control bg_input"
            placeholder="Email address"
            v-model="v$.emailAddress.$model"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <VueTelInput
          mode="international"
          :class="{
            'is-invalid': v$.phoneNumber.$errors.length > 0,
          }"
          :id="getPhoneElementId(props.passengerIndex)"
          autocomplete="off"
          :autoFormat="false"
          :inputOptions="{
            placeholder: 'Phone number*',
          }"
          @input="phoneNUmberInput"
          @blur="phoneBlur"
          @country-changed="countryChange"
        />
      </div>
    </div>
    <!--   护照-->
    <div class="row">
      <div class="col-lg-6">
        <VueTelInput
          mode="international"
          :class="{
            'is-invalid': v$.docID.$errors.length > 0,
          }"
          autocomplete="off"
          :id="getPassportElementId(props.passengerIndex)"
          :autoFormat="false"
          :inputOptions="{
            placeholder: 'Passport no*',
            autocomplete: false,
          }"
          :dropdownOptions="{
            showDialCodeInList: false,
            showDialCodeInSelection: false,
            showFlags: true,
          }"
          @input="passportNumberInput"
          @blur="passportBlur"
          @country-changed="countryChange"
        />
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <FlatPickr
            id="example-flatpickr-range"
            class="form-control empty-border-left bg_input"
            placeholder="Expiry Date."
            :class="{
              'is-invalid': v$.docExpireDate.$errors.length > 0,
            }"
            v-model="v$.docExpireDate.$model"
            :config="{
              minDate: 'today',
            }"
            @change="chooseExpireDate(pkgPassengerModel.docExpireDate)"
          />
        </div>
      </div>
      <span
        style="margin-left: 48%; margin-top: -20px; color: #ffc107"
        id="warnExpireDate"
        v-if="warnExpireDate"
        ><i class="bi bi-exclamation-circle"></i
        >{{ $t("passport-expiry-date") }}</span
      >
    </div>
    <!--   联盟 -->
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <select
            type="text"
            class="form-control bg_input"
            :class="{
              'is-invalid': v$.flyerAirline.$errors.length > 0,
            }"
            v-model="v$.flyerAirline.$model"
          >
            <option
              v-for="(
                frequentFlyerProgram, frequentFlyerIndex
              ) in frequentFlyerPrograms"
              :key="frequentFlyerIndex"
              :value="frequentFlyerProgram.programID"
            >
              {{ frequentFlyerProgram.programName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input
            v-model="v$.ffpNumber.$model"
            :class="{
              'is-invalid': v$.ffpNumber.$errors.length > 0,
            }"
            type="text"
            class="form-control bg_input"
            placeholder="Frequent Flyer Airline Number(option)*"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped></style>
