<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n(); // 解构调用函数
const props = defineProps({
  tripAirItem: {},
  passengerFormModelItems: {},
  servicesSelected: {},
  flightPriceFormModel: {},
});

const emits = defineEmits();

const tripAirItemFareRef = ref(null);
const passengerFormModelItemsRef = ref(null);
const isPriceSummaryShow = ref(true);
const segmentsRef = ref([]);

const priceBreakdownContentRef = ref({
  passengerFares: {},
  taxesTotalAmount: 0,
});

const serviceTotalAmountRef = ref({
  amount: 0,
  currencyCode: null,
});

watch(
  () => props.tripAirItem,
  (newValue) => {
    if (newValue && newValue.tripAirItemFare) {
      tripAirItemFareRef.value = newValue.tripAirItemFare;

      priceBreakdownContentRef.value = {
        passengerFares: {},
        taxesTotalAmount: 0,
      };

      serviceTotalAmountRef.value = {
        amount: 0,
        currencyCode: null,
      };
      if (
        tripAirItemFareRef.value &&
        tripAirItemFareRef.value.passengerFares &&
        tripAirItemFareRef.value.passengerFares.length > 0
      ) {
        for (const passengerFare of tripAirItemFareRef.value.passengerFares) {
          const passengerTypeCode = passengerFare.passengerTypeCode;
          priceBreakdownContentRef.value.taxesTotalAmount +=
            passengerFare.taxesAmount;
          if (
            priceBreakdownContentRef.value.passengerFares[passengerTypeCode]
          ) {
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].baseAmount += passengerFare.baseAmount;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].totalAmount += passengerFare.totalAmount;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].quantity += 1;
          } else {
            priceBreakdownContentRef.value.passengerFares[passengerTypeCode] = {
              baseAmount: passengerFare.baseAmount,
              totalAmount: passengerFare.totalAmount,
              quantity: 1,
            };
          }
        }
      }
    }

    let ancillaryFares = [];

    if (tripAirItemFareRef.value && tripAirItemFareRef.value.ancillaryFares) {
      ancillaryFares = tripAirItemFareRef.value.ancillaryFares;
      if (ancillaryFares && ancillaryFares.length > 0) {
        ancillaryFares.forEach((item) => {
          serviceTotalAmountRef.value.amount += item.amount;
          serviceTotalAmountRef.value.currencyCode = item.currencyCode;
        });
      }
    }

    segmentsRef.value = [];
    if (newValue && newValue.flightItems && newValue.flightItems.length > 0) {
      newValue.flightItems.forEach((item, index) => {
        segmentsRef.value.push(...item.flightSegments);
      });
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => props.passengerFormModelItems,
  (newValue) => {
    passengerFormModelItemsRef.value = newValue;
  },
  { deep: true, immediate: true }
);

const flightPriceFormModelRef = ref(null);

watch(
  () => props.flightPriceFormModel,
  (newValue) => {
    flightPriceFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

function changePriceSummaryShow() {
  isPriceSummaryShow.value = !isPriceSummaryShow.value;
}

function getPassengerType(typeCode) {
  let typeName = t("adult");
  if (typeCode === "CNN") {
    typeName = t("child");
  } else if (typeCode === "INF") {
    typeName = t("infant");
  }
  return typeName;
}

//添加乘客序号 -》遍历
function addPassengerIndex(passengerFares) {
  const passengerFaresCopy = JSON.parse(JSON.stringify(passengerFares)); //不改变元数据结构

  if (passengerFaresCopy && passengerFaresCopy.length > 0) {
    let index = 1;
    for (let i = 0; i < passengerFaresCopy.length; i++) {
      const passengerFare = passengerFaresCopy[i];
      const travelerRefNumbers = passengerFare.travelerRefNumbers;
      let travelerNumbers = [];
      if (travelerRefNumbers && travelerRefNumbers.length > 0) {
        for (let j = 0; j < travelerRefNumbers.length; j++) {
          travelerNumbers.push({
            rph: travelerRefNumbers[j],
            passengerIndex: index++,
          });
        }
      }
      passengerFare.travelerNumbers = travelerNumbers;
    }
  }
  return passengerFaresCopy;
}

function getPassengerServices(servicesSelected, travelerRefNumberRPH) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) => item.travelerRPH === travelerRefNumberRPH
    );
  }
  return services;
}

function getPassengerServicesTotalAmount(
  servicesSelected,
  travelerRefNumberRPH
) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) => item.travelerRPH === travelerRefNumberRPH
    );
  }

  let totalAmount = 0;
  let currencyCode = null;
  if (services && services.length > 0) {
    services.forEach((item) => {
      totalAmount += item.amount;
      currencyCode = item.currencyCode;
    });
  }
  return {
    amount: totalAmount,
    currencyCode: currencyCode,
  };
}

function getSegmentFlight(flightSegmentRPH, segments) {
  if (segments && segments.length > 0) {
    let segmentItem = segments.find((item) => item.rph === flightSegmentRPH);
    if (segmentItem) {
      return segmentItem.marketingAirlineCode + segmentItem.flightNumber;
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-6">
      <h4>
        {{ isPriceSummaryShow ? $t("price-summary") : $t("price-breakdown") }}
      </h4>
    </div>
    <div class="col-6 text-end">
      <button
        class="btn btn_border"
        type="reset"
        @click="changePriceSummaryShow"
      >
        {{
          isPriceSummaryShow
            ? $t("view-price-breakdown")
            : $t("view-price-summary")
        }}
      </button>
    </div>
  </div>

  <div
    v-if="isPriceSummaryShow && priceBreakdownContentRef && tripAirItemFareRef"
  >
    <div class="row">
      <div class="col-12">
        <div class="row text-muted">
          <div class="col-8">{{ $t("description-uppercase") }}</div>
          <div v-if="tripAirItemFareRef" class="col-4 text-end">
            {{ $t("price-uppercase") }} ({{ tripAirItemFareRef.currencyCode }})
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-8 col-8">
            <figure>
              <div
                v-if="
                  priceBreakdownContentRef &&
                  priceBreakdownContentRef.passengerFares
                "
              >
                <div
                  v-for="(
                    passengerFare, passengerFareKey
                  ) in priceBreakdownContentRef.passengerFares"
                  :key="passengerFareKey"
                >
                  {{ passengerFareKey }} x
                  {{ passengerFare.quantity }}
                </div>
              </div>
              <div
                v-if="
                  priceBreakdownContentRef &&
                  priceBreakdownContentRef.taxesTotalAmount
                "
              >
                {{ $t("taxes-uppercase") }}
              </div>
              <div v-if="serviceTotalAmountRef.currencyCode">
                {{ $t("additional-service-charge") }}
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure class="text-end">
              <div
                v-if="
                  priceBreakdownContentRef &&
                  priceBreakdownContentRef.passengerFares
                "
              >
                <div
                  v-for="(
                    passengerFare, passengerFareKey
                  ) in priceBreakdownContentRef.passengerFares"
                  :key="passengerFareKey"
                >
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ passengerFare.baseAmount.toFixed(2) }}
                </div>
              </div>
              <div
                v-if="
                  priceBreakdownContentRef &&
                  priceBreakdownContentRef.taxesTotalAmount
                "
              >
                {{ tripAirItemFareRef.currencyCode }}
                {{ priceBreakdownContentRef.taxesTotalAmount.toFixed(2) }}
              </div>

              <div
                v-if="
                  serviceTotalAmountRef && serviceTotalAmountRef.currencyCode
                "
              >
                {{ serviceTotalAmountRef.currencyCode }}
                {{ serviceTotalAmountRef.amount.toFixed(2) }}
              </div>
            </figure>
          </div>
          <hr class="mg-up-10" />
        </div>

        <div v-if="tripAirItemFareRef" class="row font_weight">
          <div class="col-lg-8 col-8">
            <figure>
              <div>
                {{ $t("total-uppercase") }}
                ({{ tripAirItemFareRef.currencyCode }})
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure class="text-end text-danger">
              <div>
                {{ tripAirItemFareRef.currencyCode }}
                {{ tripAirItemFareRef.totalAmount.toFixed(2) }}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div
        v-if="
          tripAirItemFareRef &&
          tripAirItemFareRef.passengerFares &&
          tripAirItemFareRef.passengerFares.length > 0
        "
        class="col-12"
      >
        <div class="row text-muted">
          <div class="col-8">{{ $t("description-uppercase") }}</div>
          <div class="col-4 text-end">
            {{ $t("price-uppercase") }} ({{ tripAirItemFareRef.currencyCode }})
          </div>
        </div>
        <hr />
        <div
          v-for="(passengerFare, passengerFareIndex) in addPassengerIndex(
            tripAirItemFareRef.passengerFares
          )"
          :key="passengerFareIndex"
          class="row"
        >
          <div class="col-12">
            <div
              v-for="(
                travelerRef, travelerRefNumberIndex
              ) in passengerFare.travelerNumbers"
              :key="travelerRefNumberIndex"
              class="row"
            >
              <div class="col-lg-8 col-8">
                <figure>
                  <div>
                    <span class="font_weight"
                      >{{ $t("traveler") }}
                      {{ travelerRef.passengerIndex }}
                    </span>
                    {{ getPassengerType(passengerFare.passengerTypeCode) }}
                    ({{ travelerRef.rph }} )
                  </div>
                  <div class="mg-l-10">{{ $t("flight") }}</div>
                  <div class="mg-l-10">{{ $t("taxes-and-fees") }}</div>
                  <div
                    v-if="
                      tripAirItemFareRef.ancillaryFares &&
                      tripAirItemFareRef.ancillaryFares.length > 0 &&
                      getPassengerServices(
                        tripAirItemFareRef.ancillaryFares,
                        travelerRef.rph
                      ) &&
                      getPassengerServices(
                        tripAirItemFareRef.ancillaryFares,
                        travelerRef.rph
                      ).length > 0
                    "
                  >
                    <div class="font_weight">
                      {{ $t("services-summary") }}
                    </div>
                    <div class="p-1 font-14">
                      <div
                        v-for="(service, serviceIndex) in getPassengerServices(
                          tripAirItemFareRef.ancillaryFares,
                          travelerRef.rph
                        )"
                        :key="serviceIndex"
                        class="mg-l-10"
                      >
                        {{ service.name }}
                        <span class="text-muted"
                          >({{
                            getSegmentFlight(
                              service.flightSegmentRPH,
                              segmentsRef
                            )
                          }})</span
                        >
                      </div>
                      <div
                        v-if="
                          getPassengerServicesTotalAmount(
                            tripAirItemFareRef.ancillaryFares,
                            travelerRef.rph
                          )
                        "
                        class="font_weight mg-l-10"
                      >
                        {{ $t("services-total") }}
                      </div>
                    </div>
                  </div>
                </figure>
              </div>
              <div class="col-lg-4 col-4">
                <figure class="text-end">
                  <div class="font_weight">
                    {{ passengerFare.currencyCode }}
                    {{ passengerFare.totalAmount.toFixed(2) }}
                  </div>
                  <div class="text-muted font-14">
                    {{ passengerFare.currencyCode }}
                    {{ passengerFare.baseAmount.toFixed(2) }}
                  </div>
                  <div class="text-muted font-14">
                    {{ passengerFare.currencyCode }}
                    {{ passengerFare.taxesAmount.toFixed(2) }}
                  </div>
                  <div
                    v-if="
                      tripAirItemFareRef.ancillaryFares &&
                      tripAirItemFareRef.ancillaryFares.length > 0 &&
                      getPassengerServices(
                        tripAirItemFareRef.ancillaryFares,
                        travelerRef.rph
                      ) &&
                      getPassengerServices(
                        tripAirItemFareRef.ancillaryFares,
                        travelerRef.rph
                      ).length > 0
                    "
                  >
                    <div class="font_weight">
                      {{ $t("price") }}
                    </div>
                    <div class="p-1 font-14">
                      <div
                        v-for="(service, serviceIndex) in getPassengerServices(
                          tripAirItemFareRef.ancillaryFares,
                          travelerRef.rph
                        )"
                        :key="serviceIndex"
                      >
                        {{ service.currencyCode }}
                        {{ service.amount.toFixed(2) }}
                      </div>
                      <div
                        v-if="
                          getPassengerServicesTotalAmount(
                            tripAirItemFareRef.ancillaryFares,
                            travelerRef.rph
                          )
                        "
                        class="font_weight"
                      >
                        {{
                          getPassengerServicesTotalAmount(
                            tripAirItemFareRef.ancillaryFares,
                            travelerRef.rph
                          ).currencyCode
                        }}
                        {{
                          getPassengerServicesTotalAmount(
                            tripAirItemFareRef.ancillaryFares,
                            travelerRef.rph
                          ).amount.toFixed(2)
                        }}
                      </div>
                    </div>
                  </div>
                </figure>
              </div>

              <hr class="mg-up-10" />
            </div>
          </div>
        </div>
        <div v-if="tripAirItemFareRef" class="row font_weight">
          <div class="col-lg-8 col-8">
            <figure>
              <div>
                {{ $t("total-uppercase") }}
                ({{ tripAirItemFareRef.currencyCode }})
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure class="text-end text-danger">
              <div v-if="tripAirItemFareRef.totalAmount">
                {{ tripAirItemFareRef.currencyCode }}
                {{ tripAirItemFareRef.totalAmount.toFixed(2) }}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
