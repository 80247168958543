<script setup>
import { useI18n } from "vue-i18n";

import { ref, watch } from "vue";

import FlightPassengerFormItem from "@/views/v2/sales/flight/items/form/FlightPassengerFormItem.vue";
import contentApi from "@/apis/contentApi";

const { t, locale } = useI18n(); // 解构调用函数

const emits = defineEmits();
const props = defineProps({
  passengerFormModelItems: {
    type: Object,
  },
  tripAirItem: {},
});

const earlyDepartureDateRef = ref(null);
const latestDepartureDateRef = ref(null);
const isPassportMandatoryRef = ref(null);

const originDestinationsRef = ref([]);

watch(
  () => props.tripAirItem,
  (newValue) => {
    if (newValue && newValue.flightItems) {
      originDestinationsRef.value = newValue.flightItems;

      if (
        originDestinationsRef.value &&
        originDestinationsRef.value.length > 0
      ) {
        //需要校验护照过期时间（护照有效期为出发或返回后 6 个月内） 需要校验护照是否强制（根据OD countryCode）
        earlyDepartureDateRef.value =
          originDestinationsRef.value[0].departureDateTime.split("T")[0];
        latestDepartureDateRef.value =
          originDestinationsRef.value[
            originDestinationsRef.value.length - 1
          ].departureDateTime.split("T")[0];
        //只要里面有符合
        isPassportMandatoryRef.value = originDestinationsRef.value.some(
          (flightItem) => {
            const originLocationCode = flightItem.originLocationCode;
            const destinationLocationCode = flightItem.destinationLocationCode;
            let originCountryCode = null;
            let destinationCountryCode = null;

            contentApi.airportCountryCode(originLocationCode).then((res) => {
              originCountryCode = res.data.countryCode;
            });
            contentApi
              .airportCountryCode(destinationLocationCode)
              .then((res) => {
                destinationCountryCode = res.data.countryCode;
              });

            let isNeed = false;
            contentApi
              .passport(destinationLocationCode, originLocationCode)
              .then((res) => {
                if (res.comment && res.comment.stringValue === "MANDATORY") {
                  isNeed = true; //强制要求
                }
              });

            return isNeed;
          }
        );
      }
    }
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <div v-if="passengerFormModelItems && passengerFormModelItems.length > 0">
    <FlightPassengerFormItem
      v-for="(passenger, passengerIndex) in passengerFormModelItems"
      :key="passengerIndex"
      :early-departure-date="earlyDepartureDateRef"
      :latest-departure-date="latestDepartureDateRef"
      :is-passport-mandatory="isPassportMandatoryRef"
      :passenger-index="passengerIndex + 1"
      :passenger-model="passenger"
    />
  </div>
</template>

<style lang="scss" scoped></style>
