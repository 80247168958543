<script setup>
import { ref, watch } from "vue";
import BaseFlightItineraryItem from "@/views/v2/sales/flight/items/group/BaseFlightItineraryItem.vue";

const props = defineProps({
  tripAirItem: {},
  priceClasses: {
    default: null,
  },
  passengerFormModelItems: {},
});

const tripAirItemRef = ref(null);
watch(
  () => props.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
  },
  { deep: true, immediate: true }
);

//region 辅助函数
function findSpecificPriceClass(priceClassId) {
  if (!priceClassId || !props.priceClasses) return null;

  for (const priceClass of props.priceClasses) {
    if (priceClass.priceClassId === priceClassId) return priceClass;
  }
}

//endregion 辅助函数
</script>

<template>
  <div
    v-if="
      tripAirItemRef &&
      tripAirItemRef.flightItems &&
      tripAirItemRef.flightItems.length > 0
    "
    class="itinerary-item"
  >
    <BaseFlightItineraryItem
      v-for="(flightItem, flightIndex) in tripAirItemRef.flightItems"
      :key="flightIndex"
      :flight-index="flightIndex"
      :flight-item="flightItem"
      :flight-num="tripAirItemRef.flightItems.length"
      :price-class="findSpecificPriceClass(flightItem.priceClassId)"
    />
  </div>
</template>

<style scoped lang="scss"></style>
