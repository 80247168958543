
// Errors
const Error400 = () => import("@/views/errors/400View.vue");
const Error401 = () => import("@/views/errors/401View.vue");
const Error403 = () => import("@/views/errors/403View.vue");
const Error404 = () => import("@/views/errors/404View.vue");
const Error500 = () => import("@/views/errors/500View.vue");
const Error503 = () => import("@/views/errors/503View.vue");
export default [
    {
        path: "/errors",
        children: [
            {
                path: "400",
                name: "error-400",
                component: Error400,
            },
            {
                path: "401",
                name: "error-401",
                component: Error401,
            },
            {
                path: "403",
                name: "error-403",
                component: Error403,
            },
            {
                path: "404",
                name: "error-404",
                component: Error404,
            },
            {
                path: "500",
                name: "error-500",
                component: Error500,
            },
            {
                path: "503",
                name: "error-503",
                component: Error503,
            },
        ],
    }
]