import axios from "axios";
import { useTemplateStore } from "@/stores/template"; // 请求拦截

// 请求拦截
axios.interceptors.request.use(
  (req) => {
    //(req);
    return Promise.resolve(req);
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      let templateStore = useTemplateStore();
      templateStore.pageLoader({ mode: "off" });
      window.location.href = "/#/security/sign-in";
    }
    // else if (error.response && error.response.status === 500) {
    //   console.log(error.response);
    // }
    //登录页登录失败的情况
    else {
      return Promise.reject(error.response);
    }
    // return Promise.resolve();
  }
);

export default axios;
