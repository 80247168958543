<script setup>
import { email, integer, required } from "@vuelidate/validators";
import { reactive, ref, watch } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import * as regexUtils from "@/utils/regexUtils";
import useVuelidate from "@vuelidate/core";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n";

import {
  adultNameTitleOptions,
  childNameTitleOptions,
  genderOptions,
  passengerTypeOptions,
} from "@/formmodel/sales/flight/common/options/flightCommonOption";
import dayjs from "dayjs";
import dateUtils from "@/utils/dateUtils";
import { useRoute } from "vue-router";
import { useFlightPriceStore } from "@/stores/v2/flight/price/flightPriceStore";

const route = useRoute();
const props = defineProps({
  passengerIndex: {
    type: Number,
  },
  passengerModel: {},
  earlyDepartureDate: {},
  latestDepartureDate: {},
  isPassportMandatory: {},
});

const { t } = useI18n();
const flightPriceStore = useFlightPriceStore();
const isPassportMandatoryRef = ref(props.isPassportMandatory);
const earlyDepartureDateRef = ref(props.earlyDepartureDate);
const latestDepartureDateRef = ref(props.latestDepartureDate);

const genderOptionsRef = ref(genderOptions);
const adultNameTitleOptionsRef = ref(adultNameTitleOptions);
const childNameTitleOptionsRef = ref(childNameTitleOptions);
//for initiate
const tripAirItemRef = ref(null);
const passengerModelRef = ref(null);
const warnExpireDate = ref(false); //护照过期提示

const passengerTypeOptionsRef = ref(passengerTypeOptions);

//校验相关
const nameRule = (value) => regexUtils.nameRegx.test(value);
const codeRule = (value) => regexUtils.codeRegx.test(value) || !value;
let rules = reactive({
  nameTitle: { required },
  gender: { required },
  givenName: { required, nameRule },
  surname: { required, nameRule },
  birthDate: { required },
  ffpNumber: { codeRule },
  phoneNumber: { integer },
  emailAddress: { email },
});

if (isPassportMandatoryRef.value) {
  rules.docID = { required };
  rules.docExpireDate = { required };
  rules.docHolderNationality = { required };
}

watch(
  () => flightPriceStore.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => props.passengerModel,
  (newValue) => {
    passengerModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

// Use vuelidate
const v$ = useVuelidate(rules, passengerModelRef.value);

const flatPickrLimit = reactive({
  disable: [
    function (date) {
      // return true to disable
      return disableDates(date, props.passengerModel);
    },
  ],
});

function disableDates(time, passenger) {
  let maxAge = null;
  let minAge = null;
  if (passenger.passengerTypeCode === "INF") {
    maxAge = 1;
    minAge = 0;
  } else if (passenger.passengerTypeCode === "CNN") {
    minAge = 2;
    maxAge = 12;
  }
  let dateLatest = dateUtils.format(new Date(latestDepartureDateRef.value)); //最晚出发时间

  let maxDate = dayjs(dateLatest).subtract(minAge, "year");

  let minDate = dayjs(dateLatest)
    .subtract(maxAge + 1, "year")
    .add(1, "day");
  let compareDate = dayjs(new Date(time.getTime()));
  let isBefore = compareDate.isBefore(minDate);
  let isAfter = compareDate.isAfter(maxDate);
  let b = isBefore || isAfter;
  return b;
}

//region 电话号码
function phoneNumberInput(value, event) {
  if (
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    passengerModelRef.value.phoneNumber = event.nationalNumber;
  } else if (
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null
  ) {
    passengerModelRef.value.phoneNumber = event.formatted;
  }
}

//endregion 电话号码

//region 护照
function passportNumberInput(value, event) {
  if (typeof value !== "string") return;
  passengerModelRef.value.docID = value;
  if (event && event.country) {
    let countryNameFormat = event.country.name.substring(
      0,
      event.country.name.indexOf("(")
    );
    passengerModelRef.value.docHolderNationality = event.country.iso2;
    passengerModelRef.value.docHolderNationalityName = countryNameFormat;
  }
}

//endregion 护照

function getPassengerType(typeCode) {
  let passengerType = {};
  if (
    passengerTypeOptionsRef.value &&
    passengerTypeOptionsRef.value.length > 0
  ) {
    passengerType = passengerTypeOptionsRef.value.find(
      (item) => item.code === typeCode
    );
  }

  return passengerType.name;
}

function countryChange(value) {
  passengerModelRef.value.countryAccessCode = value.dialCode;
}

function chooseExpireDate(expireDate) {
  warnExpireDate.value = false;
  //判断是否过期时间在旅行期间六个月内，是则提示
  const isEarlyExpired =
    new Date(
      dayjs(new Date(earlyDepartureDateRef.value))
        .add(6, "month")
        .format("YYYY-MM-DD")
    ).getTime() >= new Date(expireDate).getTime();
  const isLatestExpired =
    new Date(
      dayjs(new Date(earlyDepartureDateRef.value))
        .add(6, "month")
        .format("YYYY-MM-DD")
    ).getTime() >= new Date(expireDate).getTime();
  if (isEarlyExpired || isLatestExpired) {
    warnExpireDate.value = true;
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <span
        v-if="passengerModelRef.travelerRefNumberRPH"
        class="form-label font_weight"
        >{{ $t("traveler") }} {{ passengerIndex }}:
      </span>
      <span class="text-muted">
        {{ getPassengerType(passengerModelRef.passengerTypeCode) }} ({{
          passengerModelRef.travelerRefNumberRPH
        }})</span
      >
    </div>
  </div>
  <div class="row mg-t-10 flight-passenger-info-form">
    <div class="col-lg-12">
      <!-- Form Inline - Default Style -->
      <form class="align-items-center" @sumbit.prevent>
        <div class="row">
          <div class="col-2">
            <label class="form-label" for="example-text-input"
              >{{ $t("gender") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <select
              id="passenger-form-nameTitle"
              v-model="v$.gender.$model"
              :class="{
                'is-invalid': v$.gender.$errors.length > 0,
              }"
              class="form-control"
              type="text"
            >
              <option
                v-for="(
                  nameTitleOption, nameTitleOptionIndex
                ) in genderOptionsRef"
                :key="nameTitleOptionIndex"
                :value="nameTitleOption.code"
              >
                {{ nameTitleOption.name }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <label class="form-label" for="example-text-input"
              >{{ $t("name-title") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <select
              v-if="
                passengerModelRef.passengerTypeCode === 'CNN' ||
                passengerModelRef.passengerTypeCode === 'INF'
              "
              id="passenger-form-nameTitle"
              v-model="v$.nameTitle.$model"
              :class="{
                'is-invalid': v$.nameTitle.$errors.length > 0,
              }"
              class="form-control"
              type="text"
            >
              <option
                v-for="(
                  nameTitleOption, nameTitleOptionIndex
                ) in childNameTitleOptionsRef"
                :key="nameTitleOptionIndex"
                :value="nameTitleOption.code"
              >
                {{ nameTitleOption.name }}
              </option>
            </select>
            <select
              v-else
              id="passenger-form-nameTitle"
              v-model="v$.nameTitle.$model"
              :class="{
                'is-invalid': v$.nameTitle.$errors.length > 0,
              }"
              class="form-control"
              type="text"
            >
              <option
                v-for="(
                  nameTitleOption, nameTitleOptionIndex
                ) in adultNameTitleOptionsRef"
                :key="nameTitleOptionIndex"
                :value="nameTitleOption.code"
              >
                {{ nameTitleOption.name }}
              </option>
            </select>
          </div>

          <div class="col-3">
            <label class="form-label" for="example-text-input"
              >{{ $t("last-name") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <input
              id="passenger-form-surname"
              v-model="v$.surname.$model"
              :class="{
                'is-invalid': v$.surname.$errors.length > 0,
              }"
              :placeholder="$t('last-name')"
              autocomplete="off"
              class="form-control"
              name="passenger-form-surname"
              type="text"
              @blur="v$.surname.$touch"
            />
          </div>
          <div class="col-3">
            <label class="form-label" for="example-text-input"
              >{{ $t("first-name") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <input
              id="passenger-form-givenName"
              v-model="v$.givenName.$model"
              :class="{
                'is-invalid': v$.givenName.$errors.length,
              }"
              :placeholder="$t('first-name')"
              autocomplete="off"
              class="form-control"
              name="passenger-form-givenName"
              type="text"
              @blur="v$.givenName.$touch"
            />
          </div>
          <div class="col-2">
            <label class="form-label" for="example-text-input"
              >{{ $t("birth-date") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <FlatPickr
              v-if="
                passengerModelRef.passengerTypeCode === 'CNN' ||
                passengerModelRef.passengerTypeCode === 'INF'
              "
              id="example-flatpickr-default"
              v-model="v$.birthDate.$model"
              :class="{
                'is-invalid': v$.birthDate.$errors.length > 0,
              }"
              :config="flatPickrLimit"
              :placeholder="$t('birth-date')"
              class="form-control"
            />
            <FlatPickr
              v-else
              id="example-flatpickr-default"
              v-model="v$.birthDate.$model"
              :class="{
                'is-invalid': v$.birthDate.$errors.length > 0,
              }"
              :config="{
                maxDate: 'today',
              }"
              :placeholder="$t('birth-date')"
              class="form-control"
            />
          </div>
        </div>
        <!-- 护照强制-->
        <div v-if="isPassportMandatoryRef" class="row mg-t-5 flight-passport">
          <div class="col-5">
            <label class="form-label" for="example-text-input"
              >{{ $t("passport-number") }}
            </label>
            <VueTelInput
              v-model="passengerModelRef.docID"
              :autoFormat="false"
              :class="{
                'is-invalid': v$.docID.$errors.length > 0,
              }"
              :dropdownOptions="{
                showDialCodeInList: false,
                showDialCodeInSelection: false,
                showFlags: true,
              }"
              :inputOptions="{
                placeholder: $t('enter-your-passport-optional'),
                autocomplete: false,
              }"
              autocomplete="off"
              class="form-control flex"
              mode="international"
              @blur="v$.docID.$touch"
              @input="passportNumberInput"
              @country-changed="countryChange"
            />
          </div>
          <div class="col-5">
            <label class="form-label" for="example-text-input"
              >{{ $t("expire-date") }}
            </label>
            <FlatPickr
              id="example-flatpickr-default"
              v-model="passengerModelRef.docExpireDate"
              :class="{
                'is-invalid': v$.docExpireDate.$errors.length > 0,
              }"
              :config="{
                minDate: 'today',
              }"
              :placeholder="$t('expire-date-optional')"
              class="form-control"
              @blur="v$.docExpireDate.$touch"
              @on-change="chooseExpireDate"
            />
          </div>
          <span v-if="warnExpireDate" class="passport-expired"
            ><i class="bi bi-exclamation-circle"></i
            >{{ $t("passport-expiry-date") }}</span
          >
        </div>
        <!-- 护照强制END-->
        <div class="row">
          <div class="col-12 text-end">
            <span
              :data-bs-target="'#collapseExample' + passengerIndex"
              aria-controls="collapseExample"
              aria-expanded="false"
              class="mg-l-20 cursor-point main_color"
              data-bs-toggle="collapse"
            >
              {{ $t("show-more-options") }}
              <i class="fa fa-fw fa-angle-down"></i
            ></span>
          </div>
        </div>
        <div :id="'collapseExample' + passengerIndex" class="collapse">
          <div class="row">
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("phone-number") }}
              </label>
              <VueTelInput
                v-model="passengerModelRef.phoneNumber"
                :autoFormat="false"
                :class="{
                  'is-invalid': v$.phoneNumber.$errors.length > 0,
                }"
                :inputOptions="{
                  placeholder: $t('enter-your-phone-number'),
                }"
                autocomplete="off"
                class="form-control flex"
                mode="international"
                @blur="v$.phoneNumber.$touch"
                @input="phoneNumberInput"
                @country-changed="countryChange"
              />
            </div>
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("email-address") }}
              </label>
              <input
                id="passenger-form-email"
                v-model="passengerModelRef.emailAddress"
                :class="{
                  'is-invalid': v$.emailAddress.$errors.length > 0,
                }"
                :placeholder="$t('enter-a-email-address')"
                autocomplete="off"
                class="form-control"
                name="passenger-form-email"
                type="email"
                @blur="v$.emailAddress.$touch"
              />
            </div>
          </div>
          <!-- 护照非强制-->
          <div
            v-if="!isPassportMandatoryRef"
            class="row mg-t-5 flight-passport"
          >
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("passport-number") }}
              </label>
              <VueTelInput
                v-model="passengerModelRef.docID"
                :autoFormat="false"
                :dropdownOptions="{
                  showDialCodeInList: false,
                  showDialCodeInSelection: false,
                  showFlags: true,
                }"
                :inputOptions="{
                  placeholder: $t('enter-your-passport-optional'),
                  autocomplete: false,
                }"
                autocomplete="off"
                class="form-control flex"
                mode="international"
                @input="passportNumberInput"
                @country-changed="countryChange"
              />
            </div>
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("expire-date") }}
              </label>
              <FlatPickr
                id="example-flatpickr-default"
                v-model="passengerModelRef.docExpireDate"
                :config="{
                  minDate: 'today',
                }"
                :placeholder="$t('expire-date-optional')"
                class="form-control"
                @on-change="chooseExpireDate"
              />
            </div>
            <span v-if="warnExpireDate" class="passport-expired"
              ><i class="bi bi-exclamation-circle"></i
              >{{ $t("passport-expiry-date") }}</span
            >
          </div>
          <!-- 护照非强制END-->
          <div class="row mg-t-5">
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("frequent-flyer-airline-number") }}
              </label>
              <input
                v-model="v$.ffpNumber.$model"
                :class="{
                  'is-invalid': v$.ffpNumber.$errors.length > 0,
                }"
                :placeholder="$t('frequent-flyer-airline-number') + '(SQ/1234)'"
                autocomplete="off"
                class="form-control"
                name="passenger-form-surname"
                type="text"
                @blur="v$.ffpNumber.$touch"
              />
              <div class="invalid-feedback">{{ $t("invalid-input") }}</div>
            </div>
            <div class="col-lg-5 col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("redress-number") }}
              </label>
              <input
                id="passenger-form-surname"
                v-model="passengerModelRef.redressNumber"
                :placeholder="$t('enter-redress-number-option')"
                autocomplete="off"
                class="form-control main_input_height"
                name="passenger-form-surname"
                type="text"
              />
            </div>
          </div>
          <div class="row mg-t-5">
            <div class="col-lg-5 col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("known-traveler-number") }}
              </label>
              <input
                id="passenger-form-surname"
                v-model="passengerModelRef.knownTravelerNumber"
                :placeholder="$t('enter-known-traveler-number-option')"
                autocomplete="off"
                class="form-control main_input_height"
                name="passenger-form-surname"
                type="text"
              />
            </div>
          </div>
          <hr />
        </div>
      </form>
      <!-- END Form Inline - Default Style -->
    </div>
  </div>
</template>

<style lang="scss">
.vue-tel-input {
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dfe3ea;
  text-align: left;
}
</style>
