<script setup>
import BaseFlightItineraryPanelItem from "@/views/sales/flight/items/panel/BaseFlightItineraryPanelItem.vue";
import BaseBookingPassengersPanel from "@/views/sales/booking/items/BaseBookingPassengersPanel.vue";

defineProps({
  flightItems: {},
  airportCityMap: {},
  equipmentType: {},
});

function getContactInfo(passengers) {
  const passenger = passengers.find((item) => item.phoneNumber);

  return {
    phoneNumber: passenger.phoneNumber,
    emailAddress: passenger.emailAddress,
  };
}
</script>

<template>
  <div
    v-for="(flightItem, flightItemIndex) in flightItems"
    :key="flightItemIndex"
  >
    <div class="tour_booking_form_box reservation-review">
      <BaseFlightItineraryPanelItem
        :flight-view-items="flightItem.flightViewItems"
        :itinerary-index="flightItemIndex"
        :airport-city-map="airportCityMap"
      />
    </div>

    <div class="tour_booking_form_box margin-top-20">
      <p class="h4 mb-2">{{ $t("contact-info") }}</p>
      <div
        v-if="
          getContactInfo(flightItem.passengers) &&
          getContactInfo(flightItem.passengers).phoneNumber
        "
        class="row"
      >
        <div class="col-3">
          <h6>{{ $t("phone-number") }}</h6>
          <div>
            {{ getContactInfo(flightItem.passengers).phoneNumber }}
          </div>
        </div>
        <div class="col-3 text-end">
          <h6>{{ $t("email-address") }}</h6>
          <div>
            {{ getContactInfo(flightItem.passengers).emailAddress }}
          </div>
        </div>
      </div>
    </div>

    <div class="tour_booking_form_box mg-t-20">
      <BaseBookingPassengersPanel
        :equipment-type="equipmentType"
        :flight-view-items="flightItem.flightViewItems"
        :passengers="flightItem.passengers"
      />
    </div>
  </div>
</template>

<style scoped></style>
