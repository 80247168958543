<script setup>
import { reactive, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { required } from "@vuelidate/validators";
import * as regexUtils from "@/utils/regexUtils";
import { useI18n } from "vue-i18n";
import { adultNameTitleOptions } from "@/formmodel/sales/flight/common/options/flightCommonOption";

const props = defineProps({
  resGuest: {},
  equipmentType: {},
});
const { t } = useI18n();

const resGuestModelRef = ref(null);

watch(
  () => props.resGuest,
  (newValue) => {
    resGuestModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

const nameRule = (value) => regexUtils.nameRegx.test(value);

const rules = reactive({
  nameTitle: { required },
  givenName: { required, nameRule },
  surname: { required, nameRule },
});

const v$ = useVuelidate(rules, resGuestModelRef);
</script>

<template>
  <form class="row row-cols-lg-auto g-3 align-items-center" @sumbit.prevent>
    <div class="col-2">
      <label for="passenger-form-title"
        >{{ $t("name-title")
        }}<small class="text-lowercase"><code>*</code></small></label
      >
      <select
        class="form-select form-control"
        id="passenger-form-title"
        name="passenger-form-title"
        :class="{
          'is-invalid': v$.nameTitle.$errors.length,
        }"
        v-model="v$.nameTitle.$model"
        @blur="v$.nameTitle.$touch"
      >
        <option
          v-for="(
            nameTitleOption, nameTitleOptionIndex
          ) in adultNameTitleOptions"
          :key="nameTitleOptionIndex"
          :value="nameTitleOption.code"
        >
          {{ nameTitleOption.name }}
        </option>
      </select>
    </div>
    <div
      class="col-lg-5 col-5"
      :class="{
        'col-lg-12': equipmentType === 'iphone',
        'col-12': equipmentType === 'iphone',
      }"
    >
      <label for="passenger-form-surname"
        >{{ $t("surname")
        }}<small class="text-lowercase"><code>*</code></small></label
      >
      <input
        type="text"
        class="form-control"
        id="passenger-form-surname"
        name="passenger-form-surname"
        autocomplete="off"
        placeholder="(e.g. Dole)"
        :class="{
          'is-invalid': v$.surname.$errors.length,
        }"
        v-model="v$.surname.$model"
        @blur="v$.surname.$touch"
      />
    </div>
    <div
      class="col-lg-5 col-5"
      :class="{
        'col-lg-12': equipmentType === 'iphone',
        'col-12': equipmentType === 'iphone',
      }"
    >
      <label for="passenger-form-givenName"
        >{{ $t("given-name")
        }}<small class="text-lowercase"><code>*</code></small></label
      >
      <input
        type="text"
        class="form-control"
        id="passenger-form-givenName"
        name="passenger-form-givenName"
        placeholder="(e.g. John)"
        autocomplete="off"
        :class="{
          'is-invalid': v$.givenName.$errors.length,
        }"
        v-model="v$.givenName.$model"
        @blur="v$.givenName.$touch"
      />
    </div>
  </form>
</template>

<style scoped></style>
