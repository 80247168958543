<script setup>
let props = defineProps({
  flightPassengerFareItems: {},
  hotelPassengerFareItems: {},
  totalAmountContent: {},
  equipmentType:{}
});

function getPassengerType(typeCode) {
  let typeName = "Adult";
  if (typeCode === "CNN") {
    typeName = "Child";
  }
  return typeName;
}
</script>

<template>
  <div class="tour_booking_form_box">
    <p class="h4 mb-2">{{$t("price-summary")}}</p>
    <div v-if="equipmentType == 'iphone'" class="tour_booking_amount_area">
      <div v-if="flightPassengerFareItems">
        <ul
          v-for="(
            fareBreakdownItem, fareBreakdownItemIndex
          ) in flightPassengerFareItems"
          :key="fareBreakdownItemIndex"
        >
          <li class="row">
           <span class="col-md-7 col-sm-7 col-7 col-lg-7"> {{$t("traveler")}} {{ fareBreakdownItemIndex + 1 }}:
            {{ getPassengerType(fareBreakdownItem.passengerTypeCode) }}</span>
            <span
                class="col-md-5 col-sm-5 col-5 col-lg-5">{{ fareBreakdownItem.currency }}
              {{ fareBreakdownItem.totalFare }}</span
            >
          </li>
          <li class="row">
            <p class="col-md-7 col-sm-7 col-7 col-lg-7">{{$t("flight")}}</p>
            <span
                class="col-md-5 col-sm-5 col-5 col-lg-5"><p>
                {{ fareBreakdownItem.currency }}
                {{ fareBreakdownItem.baseFare }}
              </p></span
            >
          </li>
          <li class="row">
            <p class="col-md-7 col-sm-7 col-7 col-lg-7">{{$t("taxes-and-fees")}}</p>
            <span
                class="col-md-5 col-sm-5 col-5 col-lg-5"><p>
                {{ fareBreakdownItem.currency }}
                {{ fareBreakdownItem.taxes }}
              </p></span
            >
          </li>
        </ul>
      </div>
      <div v-if="hotelPassengerFareItems">
        <ul
          v-for="(
            hotelPassengerFareItem, hotelPassengerFareItemIndex
          ) in hotelPassengerFareItems"
          :key="hotelPassengerFareItemIndex"
        >
          <li class="row">
            <span class="col-md-7 col-sm-7 col-7 col-lg-7">{{$t("room-name-index",{index:hotelPassengerFareItemIndex + 1,roomTypeName:hotelPassengerFareItem.roomTypeName})}} :</span>
            <span
                class="col-md-5 col-sm-5 col-5 col-lg-5">{{ hotelPassengerFareItem.currency }}
              {{ hotelPassengerFareItem.totalPrice }}</span
            >
          </li>
        </ul>
      </div>
      <div class="total_subtotal_booking">
        <h6 class="row">
          <span class="col-md-7 col-sm-7 col-7 col-lg-7"> {{$t("total-amount")}}</span>
          <span
              class="col-md-5 col-sm-5 col-5 col-lg-5">{{ totalAmountContent.currency }}
            {{ totalAmountContent.totalAmount.toFixed(2) }}</span
          >
        </h6>
      </div>
    </div>
    <div v-else class="tour_booking_amount_area">
      <div v-if="flightPassengerFareItems">
        <ul
          v-for="(
            fareBreakdownItem, fareBreakdownItemIndex
          ) in flightPassengerFareItems"
          :key="fareBreakdownItemIndex"
        >
          <li>
            {{$t("traveler")}} {{ fareBreakdownItemIndex + 1 }}:
            {{ getPassengerType(fareBreakdownItem.passengerTypeCode) }}
            <span
              >{{ fareBreakdownItem.currency }}
              {{ fareBreakdownItem.totalFare }}</span
            >
          </li>
          <li>
            <p>{{$t("flight")}}</p>
            <span
              ><p>
                {{ fareBreakdownItem.currency }}
                {{ fareBreakdownItem.baseFare }}
              </p></span
            >
          </li>
          <li>
            <p>{{$t("taxes-and-fees")}}</p>
            <span
              ><p>
                {{ fareBreakdownItem.currency }}
                {{ fareBreakdownItem.taxes }}
              </p></span
            >
          </li>
        </ul>
      </div>
      <div v-if="hotelPassengerFareItems">
        <ul
          v-for="(
            hotelPassengerFareItem, hotelPassengerFareItemIndex
          ) in hotelPassengerFareItems"
          :key="hotelPassengerFareItemIndex"
        >
          <li>
            {{$t("room-name-index",{index:hotelPassengerFareItemIndex + 1,roomTypeName:hotelPassengerFareItem.roomTypeName})}} :
            <span
              >{{ hotelPassengerFareItem.currency }}
              {{ hotelPassengerFareItem.totalPrice }}</span
            >
          </li>
        </ul>
      </div>
      <div class="total_subtotal_booking">
        <h6>
          {{$t("trip-total-price")}}
          <span
            >{{ totalAmountContent.currency }}
            {{ totalAmountContent.totalAmount.toFixed(2) }}</span
          >
        </h6>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
