<script setup>
import BaseAggregatorSearchForm from "@/views/sales/common/items/form/BaseAggregatorSearchForm.vue";
import Partners from "@/components/templates/home/Partners.vue";
import Imagination from "@/components/templates/home/Imagination.vue";
import ExploreDeals from "@/components/templates/home/ExploreDeals.vue";
import Banner from "@/components/templates/home/Banner.vue";
import { reactive, ref, watchEffect } from "vue";

import { useCmsContentStore } from "@/stores/cms";

//header
const cmsContentStore = useCmsContentStore();
cmsContentStore.getHeaderContent();
cmsContentStore.getContentManagements();
let headerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  cmsContentStore.headerContent,
    (headerContent = cmsContentStore.headerContent);
  imageUrl.value =
    cmsContentStore.headerContent &&
    cmsContentStore.headerContent.backgroundImage && cmsContentStore.headerContent.backgroundImage.url
      ? cmsContentStore.headerContent.backgroundImage.url
      : null;

  backgroundImageUrl.value =imageUrl.value ?  baseUrl + imageUrl.value : null;
});

//Promotion Manegement
cmsContentStore.getPromotionManagements();

// PromotionDeals
cmsContentStore.getPromotionDeals();

// Explore our hot deals
cmsContentStore.getHotelPromotionPrimaryDeals();
cmsContentStore.getFlightPromotionPrimaryDeals();

//partner
cmsContentStore.getOurPartner();

sessionStorage.removeItem("pricedFlightViewItemsGroup");
sessionStorage.removeItem("airportCityMap");
sessionStorage.removeItem("offerViewItemMap");
sessionStorage.removeItem("flightPassengersFormModel");
sessionStorage.removeItem("passengerTicketFareBreakdownItems");
sessionStorage.removeItem("frequentFlyerProgram");
sessionStorage.removeItem("pkgPassengerFormModel");
sessionStorage.removeItem("pkgItineraryReview");
sessionStorage.removeItem("packageHotelTtiCode");
sessionStorage.removeItem("warnExpireDate");
sessionStorage.removeItem("flightSearchFormModel");
sessionStorage.removeItem("preBookRoomInfo");
sessionStorage.removeItem("roomResGuestDetails");
sessionStorage.removeItem("pkgRoomResGuestFormModel");
sessionStorage.removeItem("packageSearchFormModel");
sessionStorage.removeItem("pkgMatchFlightViewItemsGroup");
sessionStorage.removeItem("pkgFlightViewItemsGroup");
sessionStorage.removeItem("pkgPricedItineraries");
sessionStorage.removeItem("pkgOrigDestGroupMap");
sessionStorage.removeItem("pkgAirportCityMap");
sessionStorage.removeItem("wrapPkgRoomViewItem");
sessionStorage.removeItem("bookRQ");
</script>

<template>
  <!-- Banner Area -->
  <Banner
    :background-image-url="backgroundImageUrl"
    :header-content="headerContent"
  />

  <!-- Form Area -->
  <BaseAggregatorSearchForm />

  <!-- imagination Area -->
  <Imagination
    v-if="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showFrontPagePrimaryPromotion
        : ''
    "
    :promotion-deals="cmsContentStore.promotionDeals"
  />

  <!-- Top destinations -->
  <!--  <TopDestinations />-->

  <!-- Explore our hot deals -->
  <ExploreDeals
    :promotion-flights="cmsContentStore.flightPromotionPrimaryDeals"
    :promotion-hotels="cmsContentStore.hotelPromotionPrimaryDeals"
    :promotion-flights-is-show="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showFrontPageFlightPromotion
        : ''
    "
    :promotion-hotels-is-show="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showHotelPageHotPromotion
        : ''
    "
    :show-flight-promotion-user-rating="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showFlightPromotionUserRating
        : ' '
    "
    :show-hotel-promotion-user-rating="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.ShowHotelPromotionUserRating
        : ' '
    "
  />

  <!-- Offer Area -->
  <!--  <Offer />-->

  <!--Promotional Tours Area -->
  <!--  <PromotionalTours />-->

  <!-- Destinations Area -->
  <!--  <Destinations />-->

  <!-- News Area -->
  <!--  <News />-->

  <!-- Our partners Area -->
  <div v-if="cmsContentStore.contentManagement">
    <Partners
      :our-partners="cmsContentStore.ourPartners"
      v-if="cmsContentStore.contentManagement.showOurPartnersContent"
    />
  </div>
</template>

<style scoped></style>
